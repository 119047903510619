import { createContext } from "react";
import { useMergedState } from "../hooks/useMergedState";

/**
 * Shared context to hold one instance of useAppFooterState.
 */
export const AppFooterContext = createContext<
  ReturnType<typeof useAppFooterState>
>(null);

type AppFooterState = {
  isVisible?: boolean;
  content?: any;
};

/**
 * Header state management hook. One instance of this is shared across all consumers of AppHeaderContext.
 */
export function useAppFooterState(initialValues: AppFooterState) {
  return useMergedState(initialValues);
}
