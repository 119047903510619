// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconWebLink({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.745 9.776c-0.447-0.446-1.064-0.722-1.745-0.722-1.363 0-2.469 1.104-2.471 2.467v1.447c0 0.985 0.577 1.835 1.411 2.231 0.015-0.497 0.015-1.004 0.015-1.507-0.003-0.039-0.004-0.085-0.004-0.131s0.001-0.092 0.004-0.137c-0.073-0.14-0.116-0.313-0.116-0.496v-1.458c0-0.64 0.519-1.158 1.158-1.158s1.158 0.519 1.158 1.158v1.456c0 0.001 0 0.002 0 0.002 0 0.179-0.041 0.348-0.114 0.499 0.004 0.001 0.004 0.010 0.004 0.019s-0 0.018-0.001 0.027l0 1.729c0.849-0.403 1.426-1.252 1.427-2.236v-1.446c-0.001-0.682-0.278-1.299-0.726-1.745z" />
      <path d="M16.463 13.005c0.122 0.113 0.198 0.274 0.198 0.453 0 0.004-0 0.007-0 0.011q0 2.379 0 4.758c0 0.1 0 0.201 0 0.3 0 0.002 0 0.005 0 0.008 0 0.361-0.292 0.653-0.653 0.653-0.128 0-0.248-0.037-0.349-0.101-0.191-0.11-0.319-0.316-0.319-0.551 0-0.008 0-0.016 0-0.024q-0-2.41-0-4.822c-0.004-0.037-0.007-0.079-0.007-0.122 0-0.141 0.027-0.276 0.078-0.4 0.104-0.214 0.326-0.363 0.583-0.363 0.184 0 0.351 0.077 0.469 0.2z" />
      <path d="M14.255 22.224c0.447 0.446 1.064 0.722 1.745 0.722 1.363 0 2.469-1.104 2.471-2.467v-1.447c-0-0.985-0.577-1.835-1.411-2.231-0.015 0.497-0.015 1.004-0.015 1.507 0.003 0.039 0.004 0.085 0.004 0.131s-0.001 0.092-0.004 0.137c0.073 0.14 0.116 0.313 0.116 0.496v1.456c0 0.64-0.519 1.158-1.158 1.158s-1.158-0.519-1.158-1.158v-1.455c0-0.001 0-0.002 0-0.002 0-0.179 0.041-0.348 0.114-0.499-0.004-0.001-0.004-0.010-0.004-0.019s0-0.018 0.001-0.027l-0-1.729c-0.849 0.403-1.426 1.252-1.427 2.236v1.446c0.001 0.682 0.278 1.299 0.726 1.745z" />
      <path d="M16 3.363c6.979 0 12.637 5.658 12.637 12.637s-5.658 12.637-12.637 12.637c-6.979 0-12.637-5.658-12.637-12.637 0.008-6.976 5.661-12.629 12.636-12.637zM16 2.1c-7.677 0-13.9 6.223-13.9 13.9s6.223 13.9 13.9 13.9c7.677 0 13.9-6.223 13.9-13.9s-6.223-13.9-13.9-13.9z" />
    </svg>
  );
}

IconWebLink.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
