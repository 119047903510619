// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconIdeaCenter({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.069 24.952c-0.714 0-1.426 0-2.139 0-0.451 0-0.72-0.254-0.718-0.688 0-1.718-0.534-3.262-1.458-4.693-0.407-0.588-0.836-1.283-1.231-2-1.127-2.208-1.324-4.362-0.322-6.504 0.97-2.080 2.68-3.267 4.931-3.627 3.68-0.587 6.789 2.080 7.309 5.291 0.066 0.37 0.103 0.795 0.103 1.23 0 1.393-0.386 2.696-1.056 3.808-0.465 0.791-1.007 1.591-1.487 2.416-0.687 1.14-1.093 2.515-1.093 3.986 0 0.001 0 0.002 0 0.003 0 0.537-0.243 0.773-0.786 0.774-0.683 0-1.366 0-2.050 0zM16.069 23.672c0.435 0 0.872 0 1.307 0 0.213 0 0.293-0.069 0.309-0.288 0.119-1.482 0.587-2.834 1.322-4.001 0.436-0.715 0.919-1.453 1.361-2.215 0.57-0.932 0.908-2.060 0.908-3.267 0-0.192-0.009-0.382-0.025-0.569-0.202-2.207-1.783-4.019-3.876-4.565-0.41-0.103-0.84-0.157-1.282-0.157-1.827 0-3.437 0.928-4.385 2.338-1.116 1.723-1.132 3.539-0.364 5.353 0.382 0.902 0.933 1.741 1.461 2.574 0.888 1.402 1.522 2.88 1.634 4.557 0.014 0.203 0.11 0.246 0.285 0.245 0.446 0.002 0.891 0.002 1.338 0.002z" />
      <path d="M24.928 5.715c-0.070 0.154-0.157 0.287-0.261 0.405-0.823 0.755-1.658 1.498-2.496 2.238-0.248 0.219-0.515 0.24-0.662 0.061-0.19-0.232-0.090-0.453 0.077-0.64q1.12-1.267 2.256-2.523c0.108-0.145 0.279-0.239 0.473-0.239 0.080 0 0.156 0.016 0.226 0.045 0.236 0.095 0.372 0.279 0.388 0.653z" />
      <path d="M10.536 8.122c0 0.368-0.21 0.539-0.48 0.448-0.124-0.048-0.23-0.115-0.321-0.199q-1.198-1.060-2.386-2.131c-0.123-0.109-0.221-0.243-0.287-0.394-0.024-0.061-0.036-0.124-0.036-0.19 0-0.176 0.088-0.332 0.222-0.426 0.098-0.093 0.23-0.15 0.374-0.15 0.107 0 0.207 0.031 0.291 0.085 0.109 0.069 0.205 0.152 0.286 0.247q1.064 1.183 2.117 2.371c0.079 0.099 0.152 0.21 0.215 0.328z" />
      <path d="M26.080 14.538c-0.573-0.034-1.146-0.064-1.718-0.101-0.007 0-0.016 0.001-0.025 0.001-0.105 0-0.203-0.028-0.288-0.077-0.103-0.085-0.229-0.221-0.229-0.336s0.133-0.242 0.23-0.341c0.059-0.042 0.132-0.067 0.211-0.067 0.004 0 0.008 0 0.012 0 1.145-0.066 2.292-0.133 3.438-0.187 0.020-0.002 0.043-0.004 0.066-0.004 0.33 0 0.597 0.267 0.597 0.597s-0.267 0.597-0.597 0.597c-0.013 0-0.025-0-0.038-0.001-0.552-0.011-1.105-0.050-1.659-0.077z" />
      <path d="M16.598 3.096q-0.070 1.23-0.141 2.461c0.001 0.022 0.002 0.048 0.002 0.074s-0.001 0.052-0.003 0.078c-0.043 0.235-0.131 0.46-0.414 0.456s-0.382-0.224-0.4-0.469c-0.043-0.573-0.077-1.146-0.109-1.718-0.029-0.523-0.056-1.048-0.077-1.571s0.181-0.808 0.595-0.8 0.605 0.27 0.589 0.814c0 0.227-0.022 0.454-0.034 0.682z" />
      <path d="M5.12 14.621c-0.277 0-0.554 0-0.83 0-0.406 0-0.654-0.235-0.651-0.606-0-0.007-0.001-0.016-0.001-0.024 0-0.312 0.253-0.565 0.565-0.565 0.027 0 0.053 0.002 0.079 0.005 1.154 0.052 2.311 0.118 3.466 0.187 0.005-0 0.010-0 0.016-0 0.236 0 0.43 0.182 0.448 0.413 0 0.216-0.211 0.386-0.51 0.403-0.859 0.051-1.72 0.098-2.579 0.147z" />
      <path d="M14.115 25.178h3.851c0.451 0 0.816 0.365 0.816 0.816s-0.365 0.816-0.816 0.816h-3.851c-0.451 0-0.816-0.365-0.816-0.816s0.365-0.816 0.816-0.816z" />
      <path d="M14.115 26.973h3.851c0.451 0 0.816 0.365 0.816 0.816s-0.365 0.816-0.816 0.816h-3.851c-0.451 0-0.816-0.365-0.816-0.816s0.365-0.816 0.816-0.816z" />
      <path d="M15.008 28.768h2.067c0.451 0 0.816 0.365 0.816 0.816s-0.365 0.816-0.816 0.816h-2.067c-0.451 0-0.816-0.365-0.816-0.816s0.365-0.816 0.816-0.816z" />
    </svg>
  );
}

IconIdeaCenter.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
