// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconDeleteBlack({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 1.5c-8.008 0-14.5 6.492-14.5 14.5s6.492 14.5 14.5 14.5c8.008 0 14.5-6.492 14.5-14.5s-6.492-14.5-14.5-14.5zM22.098 21.056c0.134 0.133 0.216 0.318 0.216 0.521s-0.083 0.388-0.216 0.521c-0.133 0.134-0.318 0.216-0.521 0.216s-0.388-0.083-0.521-0.216l-5.056-5.056-5.056 5.056c-0.133 0.134-0.318 0.216-0.521 0.216s-0.388-0.083-0.521-0.216c-0.134-0.133-0.216-0.318-0.216-0.521s0.083-0.388 0.216-0.521l5.056-5.056-5.056-5.056c-0.133-0.133-0.216-0.318-0.216-0.521 0-0.407 0.33-0.737 0.737-0.737 0.204 0 0.388 0.082 0.521 0.216l5.056 5.056 5.056-5.056c0.133-0.133 0.318-0.216 0.521-0.216 0.407 0 0.737 0.33 0.737 0.737 0 0.204-0.082 0.388-0.216 0.521l-5.056 5.056z" />
      <path d="M16 1.5c-8.008 0-14.5 6.492-14.5 14.5s6.492 14.5 14.5 14.5c8.008 0 14.5-6.492 14.5-14.5s-6.492-14.5-14.5-14.5zM16 29.607c-7.515 0-13.607-6.092-13.607-13.607s6.092-13.607 13.607-13.607c7.515 0 13.607 6.092 13.607 13.607-0.009 7.511-6.096 13.598-13.606 13.607z" />
    </svg>
  );
}

IconDeleteBlack.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
