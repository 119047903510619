const alignments = {
  "row:top": "flex-start",
  "row:bottom": "flex-end",
  "row:center": "center",
  "row-reverse:top": "flex-start",
  "row-reverse:bottom": "flex-end",
  "row-reverse:center": "center",
  "column:left": "flex-start",
  "column:right": "flex-end",
  "column:center": "center",
  "column-reverse:left": "flex-start",
  "column-reverse:right": "flex-end",
  "column-reverse:center": "center",
};

/**
 * Parses an alignment against the container's direction to generate an `align-items` CSS value.
 */
export function useParsedAlign(direction: string, align?: string) {
  let alignment: string;

  if (direction && align) {
    alignment = alignments[`${direction}:${align}`];
  }

  return alignment || "stretch";
}
