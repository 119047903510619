import styles from "./index.module.css";
import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { AppFooterContext } from "../../contexts/AppFooterContext";

/**
 * A footer bar for displaying controls in a modal-like way.
 * Usually used to hold cancel, save, submit buttons and the like.
 */
export function AppFooterView() {
  const { currentState } = useContext(AppFooterContext);
  const { isVisible, content } = currentState;

  const canShow = isVisible && content != null;

  // Cache last content component to keep it visible for the
  // exit animation even when set to null.
  const [cachedContent, setCachedContent] = useState(null);
  useEffect(() => {
    if (content != null) {
      setCachedContent(content);
    }
  }, [content]);

  return <footer className={classNames(styles.footer, {
    [styles.isVisible]: canShow
  })}>
    <div className={styles.footerContent}>
      {cachedContent}
    </div>
  </footer>
};