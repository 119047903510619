// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconSTP({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M29.833 24.131c0 2.244-1.814 4.073-4.073 4.073-2.244 0-4.073-1.829-4.073-4.073 0-1.030 0.384-1.967 1.014-2.69l-5.057-9.868c-0.507 0.231-1.061 0.354-1.66 0.354-0.415 0-0.83-0.061-1.199-0.184l-5.887 9.33c0.861 0.738 1.399 1.844 1.399 3.074 0 2.244-1.814 4.073-4.073 4.073-2.244 0-4.073-1.829-4.073-4.073s1.814-4.058 4.073-4.058c0.138 0 0.277 0 0.415 0.031l6.163-9.729c-0.538-0.692-0.861-1.552-0.861-2.505 0-2.244 1.814-4.073 4.058-4.073s4.058 1.814 4.058 4.073c0 0.738-0.2 1.429-0.538 2.029l5.272 10.298c0.307-0.077 0.63-0.123 0.968-0.123 2.259-0.015 4.073 1.798 4.073 4.042z" />
    </svg>
  );
}

IconSTP.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
