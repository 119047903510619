// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconUSFlagBlue({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 1.6v12.701h-14.301c0.13-1.102 0.382-2.165 0.747-3.176 0.406-1.131 0.95-2.195 1.611-3.174 0.811-1.2 1.797-2.272 2.923-3.176 2.467-1.987 5.603-3.174 9.019-3.174z" />
    </svg>
  );
}

IconUSFlagBlue.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
