import { Ok, IkonateContext } from "react-ikonate";
import React from "react";
import styles from "./index.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";
/**
 * A basic HTML checkbox with built in styles.
 */
export function Checkbox({ className, ...props }) {
  return (
    <div className={styles.container}>
      <input
        type="checkbox"
        checked={props.selected}
        className={classNames(styles.checkbox, className)}
        {...props}
      />
      <div className={styles.icon}>
        <IkonateContext.Provider value={{ color: "#fff", size: 15, border: 4 }}>
          <Ok />
        </IkonateContext.Provider>
      </div>
    </div>
  );
}
Checkbox.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};
