// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconAddInverted({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 2.1c-7.677 0-13.9 6.223-13.9 13.9s6.223 13.9 13.9 13.9c7.677 0 13.9-6.223 13.9-13.9v0c-0.009-7.673-6.227-13.891-13.899-13.9h-0.001zM22.95 16.632h-6.318v6.318h-1.263v-6.318h-6.318v-1.263h6.318v-6.318h1.263v6.318h6.318z" />
    </svg>
  );
}

IconAddInverted.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
