import React, { FunctionComponent, useContext, useEffect } from "react"
import PropTypes from "prop-types";
import { useUniqueId } from "../hooks/useUniqueId";
import { AppFooterContext } from "../contexts/AppFooterContext";

const propTypes = {
  isVisible: PropTypes.bool,
  content: PropTypes.node
}

type AppFooterProps = PropTypes.InferProps<typeof propTypes>;

export const AppFooter: FunctionComponent<AppFooterProps> = ({ isVisible, children }) => {
  const id = useUniqueId();
  const { applyState, removeState } = useContext(AppFooterContext);

  useEffect(() => {
    return () => {
      removeState(id);
    }
  }, [id]);

  useEffect(() => {
    applyState(id, { isVisible, content: children });
  }, [isVisible, children]);

  return null;
}

AppFooter.propTypes = propTypes;