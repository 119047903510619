// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconCart({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 2.393c7.515 0 13.607 6.092 13.607 13.607s-6.092 13.607-13.607 13.607c-7.515 0-13.607-6.092-13.607-13.607 0.009-7.511 6.096-13.598 13.607-13.607zM16 1.5c-8.008 0-14.5 6.492-14.5 14.5s6.492 14.5 14.5 14.5c8.008 0 14.5-6.492 14.5-14.5s-6.492-14.5-14.5-14.5z" />
      <path d="M14.141 22.594c0 0.791-0.641 1.432-1.432 1.432s-1.432-0.641-1.432-1.432c0-0.791 0.641-1.432 1.432-1.432s1.432 0.641 1.432 1.432z" />
      <path d="M14.279 16.598l10.169-0.424c0.185-0.008 0.333-0.16 0.334-0.346v-5.818c0-0.192-0.156-0.348-0.348-0.348h-12.957l-0.683-1.688h-3.098c-0.267 0-0.483 0.216-0.483 0.483s0.216 0.483 0.483 0.483h2.452l2.952 7.302-2.755 1.895v2.697h13.905c0.267 0 0.483-0.216 0.483-0.483s-0.216-0.483-0.483-0.483h-12.942v-1.237zM24.086 10.361v5.133l-10.079 0.421-2.249-5.553z" />
      <path d="M23.361 22.594c0 0.791-0.641 1.432-1.432 1.432s-1.432-0.641-1.432-1.432c0-0.791 0.641-1.432 1.432-1.432s1.432 0.641 1.432 1.432z" />
    </svg>
  );
}

IconCart.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
