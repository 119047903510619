// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconDiamond({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.845 21.040l-4.314-11.6c-0.211-0.485-0.345-1.047-0.371-1.638l-0-0.010c-0.002-0.024-0.003-0.051-0.003-0.079 0-0.34 0.14-0.648 0.366-0.868l0-0q0.363-0.33 1.53-0.418v-0.814h-8.453v0.814q1.32 0.285 1.936 0.848t1.254 2.165l6.47 16.947h0.814l1.514-3.315z" />
      <path d="M22.798 21.040l-4.314-11.6c-0.213-0.485-0.348-1.048-0.374-1.64l-0-0.010c-0.002-0.023-0.002-0.050-0.002-0.077 0-0.341 0.14-0.649 0.365-0.87l0-0q0.363-0.33 1.53-0.418v-0.813h-8.451v0.814q1.32 0.285 1.938 0.848t1.254 2.165l6.469 16.946h0.814l1.514-3.315z" />
      <path d="M25.861 5.976l-1.083 1.114-2.184-0.882 1.834-0.595 1.434 0.363z" />
      <path d="M28.21 6.925l-0.088 1.517 2.278 0.92-0.906-1.701-1.285-0.736z" />
      <path d="M24.912 7.378l-0.088 4.426 3.008-3.246-2.92-1.179z" />
      <path d="M24.698 7.29l-2.259-0.912 2.174 5.194 0.085-4.282z" />
      <path d="M28.046 8.64l-2.909 3.144 5.166-2.229z" />
      <path d="M27.995 6.838l-1.92-0.776-1.083 1.114 2.92 1.181 0.083-1.518z" />
      <path d="M29.109 10.918v0.101h-0.299v0.784h-0.117v-0.784h-0.299v-0.101zM30.069 11.803l-0.043-0.603c0-0.051 0-0.117-0.010-0.189v0c-0.022 0.053-0.048 0.144-0.072 0.211l-0.205 0.566h-0.139l-0.208-0.582c-0.016-0.054-0.050-0.139-0.066-0.195v0c0 0.059 0 0.12 0 0.189l-0.046 0.603h-0.117l0.070-0.885h0.173l0.208 0.566c0.026 0.066 0.043 0.126 0.062 0.186v0c0.019-0.062 0.042-0.134 0.054-0.179l0.208-0.573h0.176l0.078 0.885z" />
    </svg>
  );
}

IconDiamond.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
