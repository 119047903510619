import styles from "./Item.module.css";
import React from "react";
import PropTypes from "prop-types";

/**
 * An item within a group. Has a label, optional widgets and content.
 */
export function Item(props) {
  return (
    <div className={styles.item}>
      <div className={styles.titleSection}>
        <span className={styles.titleSpan}>{props.title}</span>
        <div className={styles.widgetsList}>{props.widgets}</div>
      </div>
      <div className={styles.contentSection}>{props.children}</div>
    </div>
  );
}

Item.propTypes = {
  /**
   * A string or component to be displayed as this item's label.
   */
  title: PropTypes.any,

  /**
   * An array of components to be displayed under the label.
   */
  widgets: PropTypes.array,
};
