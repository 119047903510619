import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { FlowContainer } from "../FlowContainer";

type FlowDownProps = PropTypes.InferProps<typeof FlowContainer.propTypes>;

/**
 * Layout container that flows children from top to bottom.
 */
export const FlowDown = forwardRef(function FlowDown(props: FlowDownProps, ref) {
  return <FlowContainer ref={ref} {...props} direction="column" />;
});

FlowDown.propTypes = FlowContainer.propTypes;
