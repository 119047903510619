// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconHomeFeedAdmin({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M29.339 31.282h0.007c0.001 0 0.001 0 0.002 0 0.389 0 0.758-0.081 1.093-0.227l-0.018 0.007c0.673-0.291 1.199-0.817 1.483-1.472l0.007-0.018c0.14-0.318 0.221-0.689 0.221-1.078 0-0.002 0-0.005 0-0.007v0-16.446c0-0.002 0-0.005 0-0.008 0-0.389-0.081-0.76-0.228-1.096l0.007 0.018c-0.292-0.673-0.817-1.199-1.472-1.484l-0.018-0.007c-0.317-0.139-0.687-0.22-1.075-0.22-0.001 0-0.002 0-0.002 0h-2.803v19.243c0 0.002 0 0.004 0 0.005 0 0.39 0.081 0.761 0.228 1.097l-0.007-0.018c0.292 0.673 0.818 1.198 1.472 1.483l0.018 0.007c0.317 0.139 0.687 0.22 1.076 0.22 0.001 0 0.002 0 0.002 0h0.007z" />
      <path d="M26.172 28.488v-9.738l-0.090-14.726c-0.020-2.088-1.717-3.773-3.808-3.773-0.004 0-0.008 0-0.012 0h-18.441c-0.004 0-0.008-0-0.013-0-2.091 0-3.788 1.685-3.808 3.771v23.734c0.019 2.088 1.717 3.774 3.808 3.774 0.004 0 0.009 0 0.013-0h24.656c-1.337-0.392-2.297-1.603-2.306-3.041v-0.001zM13.498 3.349l0.526 2.514c0.139 0.673 0.271 1.38 0.361 1.931h0.017c0.090-0.583 0.246-1.24 0.4-1.95l0.6-2.498h1.060l0.55 2.547c0.132 0.657 0.255 1.274 0.337 1.873h0.017c0.1-0.616 0.23-1.24 0.378-1.923l0.576-2.498h1.027l-1.495 5.538h-1.076l-0.576-2.613c-0.115-0.464-0.224-1.058-0.303-1.662l-0.009-0.088h-0.017c-0.106 0.699-0.232 1.293-0.388 1.875l0.027-0.117-0.641 2.605h-1.090l-1.356-5.538zM8.585 3.349h3.22v0.83h-2.21v1.43h2.087v0.822h-2.087v1.627h2.334v0.83h-3.344zM3.023 3.349h1.158l1.43 2.375c0.309 0.511 0.625 1.121 0.903 1.751l0.042 0.106 0.017-0.008c-0.059-0.605-0.092-1.309-0.092-2.020 0-0.096 0.001-0.191 0.002-0.286l-0 0.014v-1.931h0.936v5.538h-1.050l-1.446-2.441c-0.313-0.517-0.643-1.142-0.937-1.787l-0.049-0.119-0.033 0.008c0.041 0.715 0.057 1.446 0.057 2.358v1.98h-0.938zM23.265 29.163h-20.513v-2.15h20.513zM23.265 25.512h-20.513v-2.15h20.513zM23.265 21.857h-20.513v-2.15h20.513zM23.265 18.201h-20.513v-6.964h20.513zM21.246 8.97c-0.010 0-0.021 0-0.033 0-0.536 0-1.042-0.127-1.49-0.354l0.019 0.009 0.23-0.838c0.386 0.22 0.847 0.35 1.339 0.353h0.001c0.641 0 1.003-0.304 1.003-0.755 0-0.42-0.279-0.665-0.977-0.92-0.921-0.321-1.504-0.814-1.504-1.603 0-0.912 0.756-1.594 1.964-1.594 0.021-0.001 0.047-0.001 0.072-0.001 0.453 0 0.883 0.1 1.269 0.28l-0.018-0.008-0.238 0.822c-0.318-0.166-0.695-0.263-1.095-0.263-0.005 0-0.010 0-0.015 0h0.001c-0.641 0-0.912 0.337-0.912 0.665 0 0.428 0.32 0.625 1.059 0.904 0.962 0.37 1.422 0.854 1.422 1.643 0.001 0.896-0.674 1.659-2.096 1.659z" />
    </svg>
  );
}

IconHomeFeedAdmin.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
