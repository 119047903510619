import styles from "./Button.module.css";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * A general-purpose button component with style presets.
 */
export function Button({
  buttonStyle = "default",
  children,
  className,
  disabled = false,
  icon,
  label,
  onClick,
  style,
  type = "button",
}) {
  return (
    <button
      disabled={disabled}
      type={type}
      className={classNames([
        styles.button,
        className,
        styles[buttonStyle],
        disabled && styles.disabled,
        icon && styles.withIcon,
      ])}
      style={style}
      onClick={disabled ? null : onClick}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {label || children || "Button"}
    </button>
  );
}

Button.propTypes = {
  label: PropTypes.string,

  /**
   * React element or fragment to display as an icon.
   */
  icon: PropTypes.node,

  /**
   * HTML button type attribute (optional)
   */
  type: PropTypes.oneOf(["button", "submit", "reset"]),

  /**
   * React className string.
   */
  className: PropTypes.string,

  /**
   * React styles object.
   */
  style: PropTypes.object,

  disabled: PropTypes.bool,

  buttonStyle: PropTypes.oneOf([
    "accent",
    "accentDark",
    "approve",
    "borderless",
    "danger",
    "default",
    "hollow",
    "hollowAccent",
    "hollowBronze",
    "hollowInverse",
  ]),

  onClick: PropTypes.func,
};
