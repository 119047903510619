// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconFamilyReunion({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.557 13.045c0.001-0.031 0.002-0.067 0.002-0.103 0-0.712-0.285-1.358-0.748-1.829l0 0c-0.494-0.494-1.152-0.742-1.966-0.742-0.469 0.013-0.916 0.066-1.351 0.156l0.050-0.009c-0.021 0.032-0.041 0.070-0.057 0.109l-0.002 0.005c-0.067 0.21-0.106 0.451-0.109 0.701v4.001c0.118 0 0.221 0 0.307 0h0.216c1.21 0 2.070-0.229 2.736-0.686 0.346-0.237 0.922-0.792 0.922-1.603z" />
      <path d="M16 1.6c-7.953 0-14.4 6.447-14.4 14.4s6.447 14.4 14.4 14.4c7.953 0 14.4-6.447 14.4-14.4v0c0-7.953-6.447-14.4-14.4-14.4v0zM24.379 22.338h-3.382l-4.085-6.25c-0.32 0.013-0.784 0.026-0.984 0.026-0.082 0-0.16 0-0.262 0s-0.19 0-0.29-0.013v4c0 0.8 0.086 1.293 0.262 1.485 0.085 0.101 0.188 0.184 0.305 0.245l0.006 0.003c0.006 0.004 0.014 0.008 0.021 0.011l0.001 0c0.032 0.018 0.069 0.035 0.108 0.048l0.004 0.001c0.037 0.016 0.082 0.030 0.128 0.042l0.006 0.001c0.089 0.024 0.195 0.042 0.302 0.051l0.006 0c0.056 0 0.112 0 0.173 0h0.472v0.346h-5.403v-0.346h0.48c0.501 0 0.867-0.152 1.102-0.458 0.013-0.016 0.025-0.034 0.037-0.052l0.001-0.002c0.009-0.014 0.019-0.030 0.027-0.047l0.001-0.003v-0.010c0.004-0.009 0.008-0.019 0.011-0.029l0-0.001c0.006-0.009 0.010-0.020 0.013-0.031l0-0.001q0.12-0.32 0.12-1.262v-3.981h-2.304c-0.036-0.002-0.078-0.003-0.12-0.003-0.304 0-0.593 0.065-0.854 0.182l0.013-0.005c-0.217 0.119-0.389 0.295-0.501 0.508l-0.003 0.007c-0.122 0.232-0.184 0.592-0.19 1.085h-0.346v-4.285h0.342c0.080 0.693 0.245 1.154 0.491 1.387s0.64 0.352 1.163 0.352h2.312v-4.070c-0.035-0.432-0.115-0.717-0.237-0.85-0.021-0.024-0.043-0.045-0.066-0.065l-0.001-0.001h-2.491c-0.661 0-1.147 0.061-1.462 0.187-0.325 0.129-0.595 0.336-0.797 0.599l-0.003 0.004c-0.257 0.372-0.453 0.809-0.56 1.279l-0.005 0.025h-0.32l0.122-2.786h9.44v0.011c0.96 0.034 1.682 0.126 2.189 0.282 0.652 0.207 1.2 0.584 1.611 1.079l0.005 0.006c0.416 0.488 0.669 1.126 0.669 1.823 0 0.021-0 0.043-0.001 0.064l0-0.003c0 0.019 0.001 0.041 0.001 0.064 0 0.758-0.29 1.449-0.766 1.967l0.002-0.002c-0.669 0.741-1.693 1.080-2.362 1.216l2.437 3.37c0.597 0.835 1.296 1.658 1.726 1.931 0.482 0.289 1.056 0.477 1.669 0.522l0.013 0.001z" />
    </svg>
  );
}

IconFamilyReunion.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
