// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconMyLists({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.66 6.316c-0.046-0.803-0.71-1.498-1.714-1.9 0.015-0.108 0.031-0.232 0.031-0.34 0-1.097-0.88-1.977-1.977-1.977s-1.977 0.88-1.977 1.977c0 0.124 0.015 0.232 0.031 0.34-0.988 0.402-1.668 1.097-1.714 1.9h-6.101v23.584h19.522v-23.584h-6.101zM16 3.289c0.432 0 0.788 0.355 0.788 0.788 0 0.015 0 0.031 0 0.031-0.015 0.417-0.371 0.757-0.788 0.757s-0.772-0.324-0.788-0.757c0-0.015 0-0.031 0-0.031 0-0.432 0.355-0.788 0.788-0.788zM24.433 28.294h-16.865v-20.371h3.413c-0.17 0.371-0.263 0.772-0.263 1.189h10.564c0-0.417-0.093-0.819-0.263-1.189h3.413v20.371z" />
      <path d="M9.251 11.567h13.498v1.174h-13.498v-1.174z" />
      <path d="M9.251 14.44h13.498v1.189h-13.498v-1.189z" />
      <path d="M9.251 17.313h13.498v1.174h-13.498v-1.174z" />
      <path d="M9.251 20.201h13.498v1.174h-13.498v-1.174z" />
    </svg>
  );
}

IconMyLists.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
