// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconManageBSM({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.784 22.614c-3.384 0-5.772 1.715-5.772 1.715v-17.669c0 0 2.389-1.776 5.772-1.776s5.772 2.006 5.772 2.006v17.455c0-0.015-2.389-1.73-5.772-1.73v0zM22.216 22.614c-3.384 0-5.772 1.715-5.772 1.715v-17.669c0 0 2.389-1.776 5.772-1.776s5.772 2.006 5.772 2.006v17.455c0.015-0.015-2.373-1.73-5.772-1.73v0zM19.108 26.228l0.061 0.888h-6.278v-0.888h-10.672v-17.792h0.888v16.904h10.672v0.888h4.44v-0.888h10.672v-16.904h0.888v17.776h-10.672v0.015z" />
    </svg>
  );
}

IconManageBSM.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
