// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconShare({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.402 15.311c-0.237 0-0.479 0-0.719 0.018 0.11 0.492 0.173 1.057 0.173 1.637 0 0.003 0 0.006 0 0.009v-0c0 0.002 0 0.005 0 0.007 0 4.377-3.548 7.925-7.925 7.925s-7.925-3.548-7.925-7.925c0-4.377 3.548-7.925 7.925-7.925 1.643 0 3.17 0.5 4.435 1.356l-0.028-0.018c0.24-0.117 0.491-0.231 0.753-0.338-1.42-1.067-3.212-1.709-5.154-1.709-4.765 0-8.628 3.863-8.628 8.628s3.863 8.628 8.628 8.628c4.765 0 8.628-3.863 8.628-8.628 0-0.001 0-0.002 0-0.002v0c-0.001-0.589-0.060-1.164-0.172-1.72l0.009 0.056z" />
      <path d="M24.282 18.49v-3.25l-0.269-0.067c-1.372-0.361-2.949-0.572-4.574-0.581h-0.005c-0.068 0-0.138 0-0.205 0-0.245 0-0.49 0.009-0.73 0.024s-0.456 0.027-0.675 0.056c-1.833 0.171-3.513 0.676-5.029 1.455l0.077-0.036c-0.894 0.455-1.664 0.96-2.371 1.539l0.023-0.018c0.244-1.014 0.594-1.906 1.049-2.733l-0.028 0.056c0.887-1.597 2.185-2.871 3.752-3.706l0.050-0.024c0.208-0.114 0.427-0.219 0.654-0.319s0.482-0.208 0.741-0.304c1.825-0.665 4.172-1.014 7.168-0.833l0.376 0.024v-3.376l5.404 6.045z" />
    </svg>
  );
}

IconShare.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
