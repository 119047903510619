import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { FlowContainer } from "../FlowContainer";

type FlowLeftProps = PropTypes.InferProps<typeof FlowContainer.propTypes>;

/**
 * Layout container that flows children from right to left.
 */
export const FlowLeft = forwardRef(function FlowLeft(props: FlowLeftProps, ref) {
  return <FlowContainer ref={ref} {...props} direction="row-reverse" />;
});

FlowLeft.propTypes = FlowContainer.propTypes;
