import React, { FunctionComponent, useContext, useEffect } from "react"
import PropTypes from "prop-types";
import { useUniqueId } from "../hooks/useUniqueId";
import { AppHeaderContext } from "../contexts/AppHeaderContext";

const propTypes = {
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.node,
  onBack: PropTypes.func
}

type AppHeaderProps = PropTypes.InferType<typeof propTypes>;

/**
 * Control the app header by passing props to this component.
 * Props from the most recently rendered AppHeader will override previous ones.
 */
export const AppHeader: FunctionComponent<AppHeaderProps> = ({ isVisible, title, icon, onBack, children }) => {
  const id = useUniqueId();
  const { applyState, removeState } = useContext(AppHeaderContext);

  useEffect(() => {
    return () => {
      removeState(id);
    }
  }, [id]);

  useEffect(() => {
    applyState(id, { isVisible, title, icon, onBack, controls: children });
  }, [isVisible, title, icon, onBack, children]);

  return null;
}

AppHeader.propTypes = propTypes;