import styles from "./TabBar.module.css";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Tab } from "./Tab/Tab";

const styleClasses = {
  default: styles.styleDefault,
  gradient: styles.styleGradient,
  ios13: styles.styleiOS13,
  underline: styles.styleUnderline,
};

export function TabBar(props) {
  const { tabStyle, selected, tabs } = props;

  const tabIndex = tabs.findIndex(
    (tab, i) => (selected == null && i === 0) || tab.value === selected
  );

  const tabWidth = 100 / tabs.length;

  return (
    <div
      className={classNames([styles.bar, styleClasses[tabStyle || "default"]])}
    >
      <div className={styles.tabs}>
        {tabs.map((opts, i) => (
          <Tab
            {...opts}
            tabStyle={tabStyle}
            key={opts.value}
            isSelected={tabIndex === i}
          />
        ))}
        <div
          className={styles.selection}
          style={{
            width: tabWidth + "%",
            transform: `translateX(${100 * tabIndex}%)`,
          }}
        />
      </div>
    </div>
  );
}

TabBar.propTypes = {
  tabStyle: PropTypes.string,
  selected: PropTypes.any,
  tabs: PropTypes.arrayOf(PropTypes.object),
};
