import styles from "./DateTimePicker.module.css";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import { DatePicker } from "./DatePicker/DatePicker";
import { TimePicker } from "./TimePicker/TimePicker";
import { ZonePicker } from "./ZonePicker/ZonePicker";

/**
 * A combined picker for date and time with support for time zones.
 */
export function DateTimePicker(props) {
  const {
    timeZone,
    zones,
    onChange,
    useNativeOnMobile,
    withZonePicker,
  } = props;

  const [value, setValue] = useState(props.value || new Date());
  const [components, setComponents] = useState(
    dateIntoComponents(value, timeZone)
  );

  const [year, month, day, hour, minute] = components;

  // Refresh values
  useEffect(() => {
    setComponents(dateIntoComponents(value, timeZone));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  // Re-emit onChange when the time zone changes.
  useEffect(() => {
    const [year, month, day, hour, minute] = components;
    onUpdate([year, month, day], [hour, minute]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZone]);

  const onUpdate = (date, time) => {
    const reconstructed = dateFromComponents([...date, ...time], timeZone);

    setValue(reconstructed);

    if (reconstructed && typeof onChange === "function") {
      onChange(reconstructed);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.picker}>
        {withZonePicker && (
          <ZonePicker
            value={timeZone}
            zones={zones}
            onChange={(zone) => {
              console.log("changed to zone");
            }}
          />
        )}
      </div>
      <div className={styles.picker}>
        <DatePicker
          value={[year, month, day]}
          isRequired={true}
          useNativeOnMobile={useNativeOnMobile}
          onChange={(values) => {
            onUpdate(values, [hour, minute]);
          }}
        />
      </div>
      <div className={styles.picker}>
        <TimePicker
          value={[hour, minute]}
          isRequired={true}
          useNativeOnMobile={useNativeOnMobile}
          onChange={(values) => {
            onUpdate([year, month, day], values);
          }}
        />
      </div>
    </div>
  );
}

DateTimePicker.propTypes = {
  /**
   * The current date being picked.
   */
  value: PropTypes.instanceOf(Date).isRequired,

  /**
   * An IANA/Olson time zone designation, e.g. America/New_York
   */
  timeZone: PropTypes.string,

  /**
   * If true, show a time zone picker to allow the user to choose a time zone.
   */
  withZonePicker: PropTypes.bool,

  /**
   * An array of { value: string, label: string }
   */
  zones: PropTypes.arrayOf(PropTypes.string),

  /**
   * A callback to fire when the user changes the value. Passes the picked date as the first param
   * and the selected time zone as the second.
   */
  onChange: PropTypes.func,

  /**
   * Use native time picker instead of web-based one on touch devices.
   */
  useNativeOnMobile: PropTypes.bool,
};

/**
 * Transforms a JS Date object into an array of numeric pieces. Dealing with date components this
 * way simplifies things greatly.
 *
 * @param {Date} date - A JS Date object.
 */
const dateIntoComponents = (date, timeZone) => {
  const m = moment(date).tz(timeZone || moment.tz.guess());

  return [m.year(), m.month() + 1, m.date(), m.hours(), m.minutes()];
};

/**
 * Transforms an array of date components back into a JS Date.
 *
 * @param {number[]} components - An array of date components, e.g. [year, month, date, hours, minutes]
 */
const dateFromComponents = (components, timeZone) => {
  if (components[3] === 24) {
    components[3] = 0;
  }
  const [y, mo, d, h, m] = components.map((c) => c.toString().padStart(2, "0"));

  const str = `${y}-${mo}-${d} ${h}:${m}`;
  const date = moment.tz(str, timeZone || moment.tz.guess()).toDate();

  return date;
};
