// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconUndo({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 2.909c7.219 0 13.091 5.873 13.091 13.091s-5.872 13.091-13.091 13.091c-7.219 0-13.091-5.872-13.091-13.091s5.872-13.091 13.091-13.091zM16 1.6c-7.953 0-14.4 6.448-14.4 14.4s6.447 14.4 14.4 14.4 14.4-6.447 14.4-14.4c0-7.952-6.447-14.4-14.4-14.4z" />
      <path d="M23.246 16c0 1.924-0.746 3.731-2.108 5.092s-3.168 2.108-5.092 2.108c-1.925 0-3.731-0.746-5.092-2.108-0.249-0.262-0.249-0.668 0-0.93 0.262-0.249 0.668-0.249 0.929 0 1.113 1.113 2.592 1.728 4.163 1.728s3.050-0.615 4.163-1.728c1.112-1.112 1.728-2.592 1.728-4.163s-0.616-3.050-1.728-4.163c-1.113-1.113-2.592-1.728-4.163-1.728-1.401 0-2.736 0.497-3.796 1.401l1.728 1.728c0.222 0.222 0.079 0.602-0.236 0.628l-4.608 0.249c-0.209 0.013-0.393-0.17-0.38-0.393l0.327-4.529c0.013-0.314 0.393-0.458 0.615-0.236l1.623 1.623c1.309-1.165 2.971-1.78 4.726-1.78 1.924 0 3.731 0.746 5.092 2.108s2.108 3.168 2.108 5.092z" />
    </svg>
  );
}

IconUndo.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
