// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconConferenceCall({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.624 14.33c0.010 0 0.021 0 0.032 0 0.984 0 1.782-0.798 1.782-1.782s-0.798-1.782-1.782-1.782c-0.984 0-1.781 0.797-1.782 1.78v0c-0 0.005-0 0.010-0 0.016 0 0.97 0.782 1.758 1.75 1.768h0.001z" />
      <path d="M24.35 15.067h-3.33c0.267 0.352 0.429 0.797 0.432 1.279v4.961c-0.001 0.696-0.333 1.315-0.848 1.707l-0.005 0.004v3.435c-0 0.007-0 0.015-0 0.023 0 0.565 0.458 1.022 1.022 1.022 0.492 0 0.904-0.348 1.001-0.812l0.001-0.007c0.098 0.47 0.509 0.819 1.002 0.819 0.565 0 1.022-0.458 1.022-1.022 0-0.008-0-0.016-0-0.025l0 0.001v-4.837c0.593-0.15 1.025-0.678 1.027-1.307v-3.899c0-0.001 0-0.003 0-0.005 0-0.734-0.592-1.33-1.324-1.338h-0.001z" />
      <path d="M10.486 21.312v-4.96c0.002-0.484 0.164-0.929 0.437-1.285l-0.004 0.005h-3.27c-0.733 0.007-1.325 0.603-1.325 1.338 0 0.002 0 0.003 0 0.005v-0 3.899c0.004 0.63 0.44 1.157 1.026 1.3l0.009 0.002v4.842c-0 0.007-0 0.015-0 0.023 0 0.565 0.458 1.022 1.022 1.022 0.492 0 0.904-0.348 1.001-0.812l0.001-0.007c0.098 0.47 0.509 0.819 1.002 0.819 0.565 0 1.022-0.458 1.022-1.022 0-0.008-0-0.016-0-0.025l0 0.001v-3.382c-0.56-0.393-0.921-1.036-0.922-1.763v-0z" />
      <path d="M9.376 14.33c0.007 0 0.015 0 0.022 0 0.984 0 1.782-0.798 1.782-1.782s-0.798-1.782-1.782-1.782c-0.984 0-1.781 0.797-1.782 1.781v0c-0 0.005-0 0.010-0 0.016 0 0.974 0.787 1.763 1.76 1.768h0z" />
      <path d="M18.949 15.006h-1.669l-1.317 1.704-1.315-1.704h-1.658c-0.734 0.009-1.325 0.606-1.325 1.341 0 0.002 0 0.003 0 0.005v-0 4.96c0 0.001 0 0.002 0 0.003 0 0.735 0.591 1.331 1.324 1.339h0.425v6.4c-0 0.011-0.001 0.025-0.001 0.038 0 0.728 0.59 1.318 1.318 1.318 0.57 0 1.055-0.361 1.239-0.867l0.003-0.009c0.189 0.506 0.665 0.86 1.225 0.866h0.001c0.731-0.005 1.322-0.599 1.322-1.331 0-0.004-0-0.008-0-0.012v0.001-6.4h0.427c0.734-0.007 1.326-0.604 1.326-1.339 0-0.001 0-0.002 0-0.003v0-4.96c0-0.002 0-0.005 0-0.008 0-0.735-0.592-1.333-1.326-1.341h-0.001z" />
      <path d="M15.97 14.098c0.011 0 0.025 0 0.038 0 1.214 0 2.198-0.984 2.198-2.198s-0.984-2.198-2.198-2.198c-1.214 0-2.198 0.984-2.198 2.198 0 0 0 0.001 0 0.001v-0c0.001 1.2 0.963 2.175 2.158 2.197l0.002 0z" />
      <path d="M26.808 5.738c-2.853-2.567-6.647-4.137-10.808-4.137s-7.955 1.57-10.823 4.15l0.015-0.013c-0.167 0.147-0.271 0.362-0.271 0.601 0 0.216 0.086 0.412 0.225 0.556l-0-0 2.264 2.371c0.132 0.139 0.318 0.225 0.524 0.225 0.183 0 0.351-0.069 0.478-0.181l-0.001 0.001c0.959-0.843 2.075-1.538 3.293-2.031l0.078-0.028c0.535-0.218 0.906-0.734 0.906-1.337 0-0.001 0-0.003 0-0.004v0-1.187c0.994-0.262 2.136-0.413 3.312-0.413s2.318 0.151 3.406 0.434l-0.094-0.021v1.187c0 0.001 0 0.002 0 0.004 0 0.603 0.37 1.119 0.896 1.334l0.010 0.003c1.297 0.521 2.414 1.216 3.386 2.070l-0.013-0.011c0.126 0.112 0.294 0.181 0.477 0.181 0.206 0 0.392-0.086 0.523-0.225l0-0c1.12-1.181 0.902-0.947 2.264-2.371 0.139-0.144 0.225-0.34 0.225-0.556 0-0.239-0.105-0.453-0.271-0.6l-0.001-0.001z" />
    </svg>
  );
}

IconConferenceCall.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
