import styles from "./Selector.module.css";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export function Selector(props) {
  return (
    <div className={styles.selector}>
      <select {...props} className={classNames(styles.input, props.className)}>
        {props.children}
      </select>
      <span className={styles.indicator} />
    </div>
  );
}

Selector.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};
