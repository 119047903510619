// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconCore({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.187 5.952c0 1.664-1.356 3.020-3.020 3.020-1.68 0.015-3.036-1.341-3.036-3.020 0-1.664 1.356-3.020 3.020-3.020 1.68 0 3.036 1.356 3.036 3.020z" />
      <path d="M8.187 15.908c0 1.664-1.356 3.020-3.020 3.020s-3.020-1.356-3.020-3.020c0-1.664 1.356-3.020 3.020-3.020s3.020 1.356 3.020 3.020z" />
      <path d="M27.835 8.141h-15.965c-0.894 0-1.695-0.586-1.895-1.464-0.308-1.264 0.647-2.389 1.865-2.389h15.965c0.894 0 1.695 0.586 1.895 1.464 0.308 1.264-0.647 2.389-1.865 2.389z" />
      <path d="M27.835 18.157h-15.965c-0.894 0-1.695-0.586-1.895-1.464-0.308-1.264 0.647-2.389 1.865-2.389h15.965c0.894 0 1.695 0.586 1.895 1.464 0.308 1.264-0.647 2.389-1.865 2.389z" />
      <path d="M8.187 26.048c0 1.664-1.356 3.020-3.020 3.020s-3.020-1.356-3.020-3.020c0-1.664 1.356-3.020 3.020-3.020 1.664-0.015 3.020 1.341 3.020 3.020z" />
      <path d="M27.835 28.174h-15.965c-0.894 0-1.695-0.586-1.895-1.464-0.308-1.264 0.647-2.389 1.865-2.389h15.965c0.894 0 1.695 0.586 1.895 1.464 0.308 1.264-0.647 2.389-1.865 2.389z" />
    </svg>
  );
}

IconCore.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
