import styles from "./Tag.module.css";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * A general-purpose button component with style presets.
 */
export function Tag({ ...props }) {
  const tagLabel = props.label || props.children || props.status || "tag";

  return (
    <div className={classNames(styles.container, styles[props.status])}>
      {tagLabel === "inReview" ? "In Review" : `${tagLabel}`}
    </div>
  );
}

Tag.propTypes = {
  /**
   * Label can be passed in or text can be as a child
   */
  label: PropTypes.string,
  /**
   * Status is how you can select a color for the tag, If no label is passed then the status will also be the label shown.
   */
  status: PropTypes.oneOf([
    "Started",
    "Planned",
    "Completed",
    "inReview",
    "Closed",
    "Tag",
  ]),
};
