// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconView({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.013 30.346c-7.909 0-14.343-6.434-14.343-14.343s6.434-14.343 14.343-14.343 14.343 6.434 14.343 14.343c0 7.909-6.434 14.343-14.343 14.343zM16.013 2.516c-7.437 0-13.487 6.050-13.487 13.487s6.050 13.487 13.487 13.487c7.437 0 13.487-6.050 13.487-13.487s-6.050-13.487-13.487-13.487z" />
      <path d="M16.156 21.601c-6.065 0-10.897-4.020-10.897-5.599 0-1.785 4.117-5.843 10.897-5.843 3.609 0 6.216 1.306 7.768 2.402 1.621 1.145 2.843 2.624 2.843 3.441 0 1.353-4.403 5.599-10.611 5.599zM16.156 11.015c-6.369 0-10.041 3.785-10.041 4.987 0 0.788 4.158 4.742 10.041 4.742 6.027 0 9.732-4.025 9.755-4.742-0.001-0.283-0.742-1.514-2.483-2.743-1.45-1.024-3.889-2.244-7.271-2.244z" />
      <path d="M15.997 12.253c-2.064 0-3.751 1.678-3.751 3.751s1.687 3.751 3.751 3.751c2.081 0 3.759-1.678 3.759-3.751s-1.678-3.751-3.759-3.751zM16.005 17.305c-0.728 0-1.31-0.582-1.31-1.302s0.582-1.31 1.31-1.31c0.719 0 1.302 0.591 1.302 1.31s-0.582 1.302-1.302 1.302z" />
    </svg>
  );
}

IconView.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
