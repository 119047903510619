import styles from "./Group.module.css";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * A section within a sectioned list.
 */
export function Group(props) {
  let typeStyle;

  switch (props.groupStyle) {
    case "header":
      typeStyle = styles.headerGroup;
      break;
    case "alert":
      typeStyle = styles.alertGroup;
      break;
    default:
      break;
  }

  return (
    <div className={classNames(styles.group, typeStyle)}>{props.children}</div>
  );
}

Group.propTypes = {
  /**
   * Applies a specialized style to the group.
   */
  groupStyle: PropTypes.oneOf(["header", "alert"]),
};
