// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconScanners({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.345 12.203c-2.465-1.261-4.573-2.586-6.552-4.071l0.115 0.083c-0.177 1.377-0.623 5.911-0.652 8.555-0.033 3.023 0.26 7.884 0.365 8.639 0.227 0.116 0.628 0.316 1.113 0.54l-0.148-4.106s-0.282-7.394 1.311-7.943c1.237-0.426 2.433 0.232 2.906 0.545 0.307-0.903 0.84-1.662 1.534-2.234l0.008-0.007z" />
      <path d="M12.794 26.504v0.009c-0.086 0.413-0.393 0.737-0.789 0.847l-0.008 0.002c-0.112 0.033-0.241 0.053-0.374 0.053-0.009 0-0.017-0-0.026-0l0.001 0c-0.023 0-0.044 0.004-0.067 0.004h-0.020c-0.018 0-0.038-0.002-0.056-0.003-0.98-0.113-1.873-0.368-2.699-0.747l0.055 0.023-0.026-0.010c-0.052-0.021-0.104-0.042-0.156-0.064-0.518-0.211-0.881-0.381-0.881-0.381v-0.002c-0.606-0.278-1.101-0.527-1.339-0.649 0.073 0.57 0.171 1.068 0.297 1.554l-0.019-0.085c1.256 4.746 5.537 5.258 6.421 5.165 0.588-0.041 1.086-0.385 1.345-0.875l0.004-0.009c0.686-1.006-0.513-3.859-1.659-5.502 0.019 0.098 0.030 0.21 0.030 0.324 0 0.123-0.013 0.243-0.037 0.36l0.002-0.011z" />
      <path d="M13.575 12.278c-0.333 0.221-0.598 0.518-0.774 0.868l-0.006 0.013c-0.847 1.359-1.552 3.532-1.227 4.406 0.145 0.389 0.541 0.586 1.177 0.586 0.762-0.002 1.471-0.227 2.067-0.612l-0.015 0.009c-0.162-1.664 0.034-3.781 0.563-4.738-0.694-0.151-1.291-0.334-1.866-0.561l0.081 0.028z" />
      <path d="M25.762 4.436c-0.232-1.070-1.954-1.861-1.954-1.861s-5.116-2.38-8.050-2.56-9.896 1.156-9.725 5.165c0.546 2 0.701 1.21 0.698 2.559v0.002c1.977 1.535 4.205 2.952 6.561 4.154l0.238 0.11c0.617 0.25 1.348 0.465 2.104 0.611l0.076 0.012 0.003-0.003 0.008 0.005h0.011l-0.003 0.003c0.078 0.046 0.608 0.336 1.892 0.037 2.215-0.561 4.149-1.542 5.8-2.862l-0.030 0.023c2.093-1.673 2.605-4.326 2.372-5.396zM23.497 8.726c-1.092 1.371-5.843 2.945-6.778 2.945-0.002 0-0.005 0-0.008 0-0.028 0-0.055-0.002-0.081-0.006l0.003 0c-0.675-0.121-0.672-2.312-0.289-3.734 0.621-1.401 4.767-3.317 7.022-3.318v0c0.037-0.003 0.080-0.004 0.123-0.004 0.358 0 0.694 0.098 0.981 0.268l-0.009-0.005c0.313 0.458-0.192 3.18-0.963 3.854z" />
      <path d="M21.671 7.76c0.701-0.742 1.067-2.254 1.188-2.861-2.226 0.243-5.728 1.939-6.276 3.117-0.153 0.581-0.241 1.247-0.241 1.934 0 0.086 0.001 0.173 0.004 0.258l-0-0.012c0.986-0.343 4.561-1.627 5.325-2.437z" />
      <path d="M23.366 4.614v0z" />
    </svg>
  );
}

IconScanners.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
