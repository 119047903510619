// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconTrendEven({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M29.484 16c-0.001-0.543-0.237-1.031-0.613-1.366l-0.002-0.002-6.128-5.524c-0.327-0.291-0.76-0.47-1.234-0.47h-0c-1.016 0.001-1.839 0.825-1.839 1.841 0 0.542 0.234 1.030 0.607 1.366l0.002 0.001 2.57 2.315h-13.695l2.565-2.309c0.377-0.339 0.613-0.828 0.613-1.372 0-1.018-0.825-1.842-1.842-1.842-0.473 0-0.905 0.178-1.231 0.472l-6.129 5.523c-0.376 0.338-0.611 0.825-0.611 1.368s0.235 1.030 0.609 1.366l0.002 0.002 6.131 5.524c0.326 0.291 0.759 0.47 1.233 0.47h0c0.001 0 0.002 0 0.004 0 1.015 0 1.838-0.823 1.838-1.838 0-0.544-0.236-1.033-0.612-1.369l-0.002-0.002-2.568-2.315h13.696l-2.564 2.309c-0.377 0.339-0.613 0.828-0.613 1.372 0 1.018 0.825 1.842 1.842 1.842 0.473 0 0.905-0.178 1.231-0.472l6.123-5.517c0.379-0.338 0.617-0.828 0.617-1.374 0-0 0-0 0-0v0z" />
    </svg>
  );
}

IconTrendEven.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
