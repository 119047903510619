export const FILE_TYPE = {
  Image: "image",
  PDF: "pdf",
};

const extTypes = {
  jpg: FILE_TYPE.Image,
  jpeg: FILE_TYPE.Image,
  png: FILE_TYPE.Image,
  pdf: FILE_TYPE.PDF,
};

export function getFileType(href: string) {
  const matched = href.trim().match(/.*\.(.+)$/);

  if (matched == null) {
    return null;
  }

  if (extTypes[matched[1].toLowerCase()] == null) {
    return null;
  }

  return extTypes[matched[1].toLowerCase()];
}
