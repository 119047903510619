import styles from "./index.module.css";
import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "./NavLink";

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * String to display as the nav button title.
       */
      title: PropTypes.string.isRequired,

      /**
       * Something to render as the nav button icon.
       */
      icon: PropTypes.node.isRequired,

      /**
       * Path to navigate to when the nav item is clicked.
       */
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
}

type MobileNavProps = PropTypes.InferProps<typeof propTypes>;

export const MobileNav: FunctionComponent<MobileNavProps> = ({ items }) => {
  return (
    <nav className={styles.nav}>
      <ul>
        {items.map((item, i) => (
          <li key={i}>
            <NavLink {...item} />
          </li>
        ))}
      </ul>
    </nav>
  );
}

MobileNav.propTypes = propTypes;
