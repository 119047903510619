import { useCallback, useRef, useState } from "react";

/**
 * Manage a series of state objects where the currentState is calculated
 * by merging all state objects in the order they were inserted.
 *
 * States are added with `applyState(id, state)` and removed with `removeState(id)`.
 */
export function useMergedState<T>(initialValues: T) {
  const [currentState, setCurrentState] = useState(initialValues);
  const states = useRef(new Map<string, T>());

  const applyState = useCallback((id: string, state: T) => {
    states.current.set(id, state);
    setCurrentState(mergeStates(initialValues, states.current));
  }, []);

  const removeState = useCallback((id: string) => {
    states.current.delete(id);
    setCurrentState(mergeStates(initialValues, states.current));
  }, []);

  return {
    currentState,
    applyState,
    removeState,
  };
}

/**
 * Merge an array of cascading states into a single state object.
 */
function mergeStates<T>(initialValues: T, states: Map<string, T>): T {
  const merged = {
    ...initialValues,
  };

  for (const [id, state] of states) {
    for (const key in state) {
      if (state[key] != null) {
        merged[key] = state[key];
      }
    }
  }

  return merged;
}
