// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconMyMeetings({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24.984 15.040h-5.328v-0.011c0.16-1.262 0.96-2.947 2.802-2.947 2.050-0.003 2.547 1.792 2.526 2.958z" />
      <path d="M16 1.6c-7.953 0-14.4 6.447-14.4 14.4s6.447 14.4 14.4 14.4c7.953 0 14.4-6.447 14.4-14.4v0c0-7.953-6.447-14.4-14.4-14.4v0zM19.634 16.344c0.043 2.469 1.618 3.477 3.445 3.477 1.307 0 2.093-0.221 2.781-0.509l0.309 1.307c-0.64 0.288-1.739 0.621-3.344 0.621-3.024 0-4.853-1.962-4.918-4.896h-0.022v-0.787c0-1.92-0.698-3.090-2.171-3.090-1.042 0-1.85 0.763-2.16 1.661-0.088 0.272-0.141 0.585-0.144 0.91v6.205h-1.784v-6.019c0-1.6-0.698-2.757-2.093-2.757-1.141 0-1.971 0.907-2.259 1.827-0.091 0.251-0.144 0.541-0.144 0.844 0 0.015 0 0.030 0 0.045l-0-0.002v6.059h-1.784v-7.32c0-1.029-0.022-1.882-0.077-2.72h1.6l0.078 1.618h0.066c0.555-0.952 1.496-1.85 3.147-1.85 1.373 0 2.4 0.83 2.846 2.016h0.034c0.288-0.518 0.664-0.953 1.113-1.297l0.010-0.007c0.571-0.444 1.299-0.711 2.088-0.711 0.044 0 0.088 0.001 0.132 0.002l-0.006-0c0.001 0 0.003 0 0.004 0 1.127 0 2.111 0.613 2.636 1.524l0.008 0.015c0.8-1.061 2.059-1.74 3.476-1.74 0.036 0 0.072 0 0.107 0.001l-0.005-0c3.278 0 4.142 2.88 4.142 4.72 0 0.376-0.043 0.664-0.054 0.853z" />
    </svg>
  );
}

IconMyMeetings.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
