// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconFavoriteOff({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.955 2.596c7.452 0 13.514 6.063 13.514 13.515s-6.062 13.513-13.514 13.513-13.514-6.062-13.514-13.513c0-7.452 6.062-13.515 13.514-13.515zM15.955 1.71c-7.953 0-14.4 6.447-14.4 14.401 0 7.953 6.447 14.399 14.4 14.399s14.4-6.446 14.4-14.399c0-7.954-6.446-14.401-14.4-14.401v0z" />
      <path d="M22.504 25.275l-6.54-4.049-6.557 4.047 1.98-7.275-6.066-5.127h7.814l2.831-7.035 2.811 7.035h7.814l-6.065 5.127 1.979 7.277zM7.741 13.757l4.648 3.929-1.539 5.654 5.114-3.156 5.095 3.154-1.537-5.652 4.648-3.929h-5.993l-2.214-5.54-2.229 5.54h-5.992z" />
      <path d="M15.238 13.745h1.393v1.931h1.982v1.45h-1.982v1.936h-1.393v-1.936h-1.942v-1.45h1.942z" />
    </svg>
  );
}

IconFavoriteOff.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
