// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconMyAccount({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M30.4 1.6v28.8h-2.080c0.142-0.242 0.142-0.64 0.050-1.214-0.32-2.147-1.506-3.67-3.486-4.534-1.232-0.533-2.514-0.96-3.744-1.49-1.547-0.666-1.88-1.723-1.63-3.312 0.192-1.173 1.214-1.963 1.28-3.040 0.051-0.938 0.081-2.037 0.081-3.142 0-1.315-0.042-2.62-0.123-3.915-0.082-1.229-1.031-2.311-2.089-3.161-0.733-0.608-1.573 0.099-2.371 0.141-0.341 0.026-0.683 0.075-1.032 0.099-2.837 0.224-4.493 2.24-4.136 5.034 0.081 0.479 0.127 1.031 0.127 1.594 0 0.58-0.049 1.149-0.143 1.702-0.152 0.798-0.152 1.821 0.474 2.413 1.006 0.931 0.624 2.322 1.349 3.304 0.16 0.208-0.016 0.499-0.2 0.699-0.64 0.723-1.24 1.44-2.205 1.773-1.107 0.382-2.197 0.8-3.27 1.266-2.018 0.891-3.224 2.424-3.582 4.619-0.091 0.566-0.141 0.931-0.083 1.165h-1.984v-28.8z" />
    </svg>
  );
}

IconMyAccount.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
