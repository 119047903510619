// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconPurchaseTicket({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M29.449 9.619v-0.732h-26.897v0.732c0.254 0 0.463 0.209 0.463 0.463s-0.209 0.463-0.463 0.463v0.493c0.254 0 0.463 0.209 0.463 0.463s-0.209 0.463-0.463 0.463v0.493c0.254 0 0.463 0.209 0.463 0.463s-0.209 0.463-0.463 0.463v0.508c1.136 0 2.062 0.926 2.062 2.062s-0.926 2.062-2.062 2.062v0.583c0.254 0 0.463 0.209 0.463 0.463s-0.209 0.463-0.463 0.463v0.493c0.254 0 0.463 0.209 0.463 0.463s-0.209 0.463-0.463 0.463v0.493c0.254 0 0.463 0.209 0.463 0.463s-0.209 0.463-0.463 0.463v0.657h26.897v-0.657c-0.254 0-0.463-0.209-0.463-0.463s0.209-0.463 0.463-0.463v-0.493c-0.254 0-0.463-0.209-0.463-0.463s0.209-0.463 0.463-0.463v-0.433c-0.254 0-0.463-0.209-0.463-0.463s0.209-0.463 0.463-0.463v-0.583c-1.136 0-2.062-0.926-2.062-2.062s0.926-2.062 2.062-2.062v-0.508c-0.254 0-0.463-0.209-0.463-0.463s0.209-0.463 0.463-0.463v-0.493c-0.254 0-0.463-0.209-0.463-0.463s0.209-0.463 0.463-0.463v-0.493c-0.254 0-0.463-0.209-0.463-0.463 0-0.314 0.209-0.523 0.463-0.523zM25.384 21.155h-18.768v-10.311h18.783v10.311z" />
      <path d="M14.834 14.595c-0.179 0-0.269 0.015-0.329 0.030v0.882h0.299c0.403 0 0.628-0.194 0.628-0.463 0-0.314-0.224-0.448-0.598-0.448zM14.79 15.925h-0.284v1.046c0.075 0.015 0.179 0.015 0.314 0.015 0.374 0 0.717-0.134 0.717-0.538-0.015-0.374-0.344-0.523-0.747-0.523zM14.79 15.925h-0.284v1.046c0.075 0.015 0.179 0.015 0.314 0.015 0.374 0 0.717-0.134 0.717-0.538-0.015-0.374-0.344-0.523-0.747-0.523zM15.432 15.044c0-0.299-0.224-0.448-0.598-0.448-0.179 0-0.269 0.015-0.329 0.030v0.882h0.299c0.403 0 0.628-0.194 0.628-0.463zM7.303 11.547v8.906h17.379v-8.906h-17.379zM13.236 17.405h-0.583v-3.228h0.583v3.228zM15.836 17.136c-0.224 0.194-0.613 0.299-1.195 0.299-0.329 0-0.568-0.030-0.717-0.045v-3.153c0.179-0.045 0.523-0.075 0.867-0.075 0.433 0 0.702 0.060 0.912 0.209 0.194 0.12 0.329 0.329 0.329 0.613 0 0.299-0.179 0.568-0.538 0.687v0.015c0.344 0.090 0.643 0.359 0.643 0.792 0 0.284-0.12 0.508-0.299 0.657zM17.987 17.449v0c-0.912 0-1.494-0.702-1.494-1.644 0-0.986 0.628-1.689 1.539-1.689 0.941 0 1.494 0.717 1.494 1.629 0 1.091-0.657 1.704-1.539 1.704zM18.017 14.61c-0.583 0-0.897 0.553-0.897 1.195s0.329 1.18 0.882 1.18v0c0.568 0 0.882-0.523 0.882-1.195 0.015-0.613-0.284-1.181-0.867-1.181zM14.79 15.925h-0.284v1.046c0.075 0.015 0.179 0.015 0.314 0.015 0.374 0 0.717-0.134 0.717-0.538-0.015-0.374-0.344-0.523-0.747-0.523zM15.432 15.044c0-0.299-0.224-0.448-0.598-0.448-0.179 0-0.269 0.015-0.329 0.030v0.882h0.299c0.403 0 0.628-0.194 0.628-0.463z" />
    </svg>
  );
}

IconPurchaseTicket.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
