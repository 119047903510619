// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconPremierPlus({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.662 0c10.129 0.007 18.337 8.219 18.338 18.349v0c0 0.824-1.816 0.824-1.816 0 0-9.44-7.077-16.53-16.522-16.531-0.829 0-0.829-1.818 0-1.818z" />
      <path d="M13.662 3.232c8.343 0.006 15.105 6.771 15.106 15.115v0c0 0.826-1.816 0.826-1.816 0 0-7.662-5.63-13.294-13.29-13.298-0.829 0-0.829-1.818 0-1.818z" />
      <path d="M13.662 6.504c6.539 0 11.84 5.301 11.84 11.84v0c0 0.826-1.818 0.826-1.818 0 0-5.854-4.16-10.024-10.019-10.026-0.832 0.002-0.832-1.814-0.003-1.814z" />
    </svg>
  );
}

IconPremierPlus.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
