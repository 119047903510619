// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconDigitalDelivery({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.544 1.584c15.392 0.016 27.856 12.496 27.856 27.888 0 1.248-2.752 1.248-2.752 0 0-14.352-10.752-25.12-25.104-25.12-1.264 0-1.264-2.768 0-2.768z" />
      <path d="M2.544 6.496c12.672 0.016 22.944 10.288 22.944 22.96 0 1.248-2.752 1.248-2.752 0 0-11.648-8.56-20.208-20.192-20.208-1.264 0.016-1.264-2.752 0-2.752z" />
      <path d="M2.544 11.472c9.936 0 17.984 8.064 17.984 18 0 1.248-2.768 1.248-2.768 0 0-8.896-6.32-15.232-15.216-15.232-1.264 0-1.264-2.768 0-2.768z" />
      <path d="M8.908 19.493l-3.056 1.248-3.504-3.568 4.080 0.384 2.48 1.936z" />
      <path d="M12.684 23.333l-1.456 2.88 3.664 3.728-0.32-4.080-1.888-2.528z" />
      <path d="M5.868 21.413l-3.92 8.544 8.608-3.776-4.688-4.768z" />
      <path d="M5.532 21.061l-3.632-3.696-0.176 11.968 3.808-8.272z" />
      <path d="M10.908 26.549l-8.336 3.648 11.952 0.048-3.616-3.696z" />
      <path d="M12.332 22.981l-3.088-3.136-3.056 1.248 4.688 4.784 1.456-2.896z" />
    </svg>
  );
}

IconDigitalDelivery.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
