import styles from "./Checkbubble.module.css";
import React from "react";
import PropTypes from "prop-types";

/**
 * A checkbox component to indicate boolean selection.
 */
export function Checkbubble(props) {
  const { isChecked, isDisabled, name, onChange } = props;

  return (
    <input
      className={styles.checkbox}
      id={props.id || `input-checkbox-${name}`}
      type="checkbox"
      checked={isChecked}
      name={name}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.checked, e);
        }
      }}
      disabled={isDisabled}
    />
  );
}

Checkbubble.propTypes = {
  /**
   * Whether the checkbox displays as checked.
   */
  isChecked: PropTypes.bool,
  /**
   * Prevents selection.
   */
  isDisabled: PropTypes.bool,
  /**
   * HTML name property for use in a form.
   */
  name: PropTypes.string,
  /**
   * Function to call when the checkbox state changes.
   */
  onChange: PropTypes.func,
};
