// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconBSMAdmin({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M27.182 0.302h-22.404l-4.287 4.528h31.222z" />
      <path d="M8.204 18.177l0.49 13.102 0.733-3.43-0.245-9.673z" />
      <path d="M22.408 18.177l-0.245 9.673 0.857 3.551 0.49-13.223z" />
      <path d="M9.918 27.728h11.632l0.245-9.55h-12.122z" />
      <path d="M0 5.565l1.837 25.835h6.367l-0.612-13.713h16.531l-0.735 13.713h6.612l1.837-25.835zM17.282 7.492l0.63 1.83-4.010 0.009 0.743-1.833zM12.485 7.757l1.866-0.259-0.743 1.833-3 0.007zM10.507 9.614l3.101-0.007 1.954 5.089zM13.903 9.607l4.010-0.009-1.989 5.27zM16.283 14.696l1.924-5.099 3.101-0.007c-0.859 0.87-3.87 3.931-5.025 5.105zM18.2 9.322l-0.622-1.832 1.867 0.253 1.883 1.57z" />
    </svg>
  );
}

IconBSMAdmin.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
