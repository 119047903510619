// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconMe({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 29 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.557 30.001h-15.885c-0.11 0.009-0.237 0.015-0.366 0.015-2.478 0-4.487-2.009-4.487-4.487 0-0.084 0.002-0.168 0.007-0.25l-0.001 0.012c0-4.173 0.943-10.367 6.327-10.367 0.539 0 2.961 2.558 6.462 2.558s5.924-2.558 6.462-2.558c5.385 0 6.327 6.327 6.327 10.367 0.004 0.071 0.006 0.155 0.006 0.239 0 2.478-2.009 4.487-4.487 4.487-0.128 0-0.255-0.005-0.381-0.016l0.016 0.001zM14.614 16c-3.866 0-7.001-3.134-7.001-7.001s3.134-7.001 7.001-7.001c3.866 0 7.001 3.134 7.001 7.001v0c-0.070 3.837-3.162 6.93-6.993 7l-0.007 0z" />
    </svg>
  );
}

IconMe.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
