// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconBSMStore({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.111 7.091l1.579 22.489h5.511l-0.503-11.954 14.437 0.022-0.585 11.932h5.761l1.579-22.489zM19.525 9.387l0.235 1.173-1.363-1.301 0.309-0.298zM17.821 10.599l0.494-1.259 1.327 1.259zM19.613 10.736l-2.398 2.708 0.517-2.708zM18.596 8.901l-0.281 0.281-0.98-0.475zM18.205 9.272l-0.525 1.327-1.526-1.454 1.018-0.372zM17.46 10.599h-2.901l1.452-1.38zM16.978 8.704l-0.968 0.352-0.969-0.353zM15.87 9.144l-1.503 1.455-0.528-1.326 1.032-0.5zM14.276 10.736l0.512 2.724-2.41-2.724zM12.392 10.599l1.322-1.259 0.498 1.259zM14.707 8.707l-0.981 0.475-0.284-0.281zM13.309 8.962l0.298 0.293-1.363 1.301 0.242-1.218zM12.383 9.403l-0.25 1.196h-0.971zM11.216 10.736h1.011l1.697 2.597zM14.198 13.59l-1.414-2.194 2.031 2.29 0.926 1.338zM15.915 15.042l-0.995-1.474-0.502-2.696 1.497 2.798zM14.514 10.736h2.992l-1.495 2.795zM16.086 13.685l1.512-2.827-0.518 2.716-0.998 1.468zM16.276 15.034l0.901-1.349 2.031-2.293-1.415 2.201zM18.117 13.324l1.698-2.58h1.012zM19.867 10.599l-0.235-1.154 1.205 1.154z" />
      <path d="M2.608 6.471l3.605-4.052h19.575l3.586 4.032-26.767 0.020z" />
      <path d="M10.508 26.54l-0.248-8.435h-1.145l0.497 11.477 0.897-3.042z" />
      <path d="M21.272 26.54l0.248-8.435h1.145l-0.497 11.477-0.897-3.042z" />
      <path d="M20.716 26.435h-9.764l-0.247-8.33h10.256l-0.245 8.33z" />
    </svg>
  );
}

IconBSMStore.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
