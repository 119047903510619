// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconGetStarted({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.1 2.1v27.8h27.8v-27.8h-27.8zM15.12 19.753c0 0.448-0.201 0.896-0.618 1.313l-0.062 0.062-3.583 3.768c-0.757 0.803-1.405 1.421-1.946 1.853-0.525 0.432-0.911 0.664-1.127 0.664-0.232 0-0.525-0.108-0.865-0.324s-0.571-0.432-0.664-0.68c-0.077-0.185-0.17-0.602-0.232-1.22-0.077-0.633-0.108-1.297-0.108-2.039 0-0.402 0.17-0.757 0.494-1.097s0.695-0.51 1.097-0.51c0.386 0 0.602 0.355 0.68 1.050 0.015 0.077 0.015 0.139 0.015 0.185 0.062 0.541 0.139 0.911 0.216 1.127s0.201 0.309 0.324 0.309c0.062 0 0.17-0.062 0.324-0.185s0.355-0.293 0.571-0.51l3.676-3.645c0.309-0.309 0.587-0.541 0.834-0.71 0.247-0.154 0.448-0.232 0.618-0.232 0.124 0 0.216 0.046 0.278 0.139 0.046 0.108 0.077 0.263 0.077 0.494v0.185zM15.12 6.502c0 0.448-0.201 0.896-0.618 1.313l-0.062 0.062-3.583 3.784c-0.757 0.803-1.405 1.421-1.946 1.853-0.525 0.432-0.911 0.649-1.127 0.649-0.232 0-0.525-0.108-0.865-0.324s-0.571-0.448-0.664-0.68c-0.077-0.201-0.17-0.602-0.232-1.236-0.077-0.633-0.108-1.297-0.108-2.023 0-0.402 0.17-0.772 0.494-1.112s0.695-0.51 1.097-0.51c0.386 0 0.602 0.355 0.68 1.050 0.015 0.077 0.015 0.139 0.015 0.17 0.062 0.541 0.139 0.911 0.216 1.127s0.201 0.324 0.324 0.324c0.062 0 0.17-0.062 0.324-0.185s0.355-0.293 0.571-0.51l3.676-3.645c0.309-0.309 0.587-0.541 0.834-0.71 0.247-0.154 0.448-0.232 0.618-0.232 0.124 0 0.216 0.046 0.278 0.154 0.046 0.093 0.077 0.247 0.077 0.479v0.201zM25.174 23.985h-7.691v-1.189h7.691v1.189zM25.174 10.378h-7.691v-1.174h7.691v1.174z" />
    </svg>
  );
}

IconGetStarted.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
