// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconSettings({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.493 17.242c-1.485 0-2.689-1.201-2.689-2.683 0-1.479 1.204-2.678 2.689-2.678s2.691 1.199 2.691 2.678c0 1.482-1.205 2.683-2.691 2.683z" />
      <path d="M21.389 16.284v-2.874h-1.774c-0.171-1.209-0.603-2.331-1.243-3.313l1.27-1.267-2.036-2.032-1.209 1.208c-0.954-0.711-2.062-1.227-3.273-1.47v-1.745h-2.88v1.688c-1.226 0.187-2.363 0.634-3.346 1.296l-1.248-1.244-2.035 2.031 1.22 1.219c-0.688 0.953-1.176 2.058-1.404 3.251h-1.832v2.873h1.803c0.195 1.182 0.639 2.287 1.286 3.24l-1.342 1.342 2.036 2.030 1.321-1.312c0.927 0.666 1.997 1.15 3.16 1.387v1.933l2.88 0.003v-1.88c1.177-0.179 2.272-0.601 3.228-1.223l1.366 1.362 2.037-2.031-1.304-1.302c0.691-0.926 1.196-1.996 1.448-3.168h1.87zM11.493 18.632c-2.254 0-4.082-1.824-4.082-4.073 0-2.244 1.828-4.068 4.082-4.068s4.081 1.824 4.081 4.068c0 2.25-1.827 4.073-4.081 4.073z" />
      <path d="M29.122 18.392l1.278-0.543-0.875-2.048-1.217 0.518c-0.481-0.787-1.128-1.478-1.927-2.001l0.505-1.254-2.070-0.831-0.49 1.212c-0.932-0.219-1.88-0.226-2.779-0.033l-0.535-1.254-2.055 0.872 0.525 1.228c-0.769 0.486-1.44 1.137-1.952 1.932l-1.317-0.526-0.831 2.062 1.296 0.521c-0.202 0.907-0.202 1.828-0.017 2.698l-1.355 0.576 0.875 2.050 1.329-0.564c0.475 0.747 1.103 1.403 1.87 1.909l-0.561 1.392 2.071 0.835 0.545-1.354c0.895 0.211 1.804 0.221 2.673 0.051l0.587 1.372 2.055-0.871-0.561-1.315c0.767-0.462 1.44-1.085 1.96-1.855l1.343 0.539 0.836-2.067-1.277-0.511c0.228-0.918 0.245-1.851 0.070-2.741zM25.813 20.792c-0.649 1.619-2.492 2.401-4.113 1.749-1.621-0.651-2.404-2.488-1.752-4.104 0.652-1.614 2.492-2.396 4.113-1.745 1.621 0.647 2.405 2.486 1.752 4.1z" />
      <path d="M24.813 20.392c-0.427 1.066-1.641 1.579-2.709 1.15-1.068-0.426-1.584-1.637-1.154-2.7 0.428-1.065 1.643-1.579 2.709-1.155 1.068 0.427 1.586 1.639 1.154 2.705z" />
    </svg>
  );
}

IconSettings.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
