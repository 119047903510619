import styles from "./Range.module.css";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

let activeTimeout;

const resetInactiveTimeout = (callback) => {
  if (activeTimeout) {
    clearTimeout(activeTimeout);
  }

  activeTimeout = setTimeout(() => {
    callback();
    activeTimeout = null;
  }, 50);
};

export function Range(props) {
  const [isActive, setIsActive] = useState(false);
  const [width, setWidth] = useState(100);
  const [value, setValue] = useState(props.initialValue || 0);
  const ref = useRef(null);

  const rangeWidth = ref.current ? ref.current.clientWidth : null;

  const min = props.min == null ? 0 : props.min;
  const max = props.max == null ? 100 : props.max;
  const step = props.step == null ? 1 : props.step;

  const amountFilled = value / max;
  const showLabel = props.showLabel == null ? isActive : props.showLabel;

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.clientWidth);
    }
  }, [rangeWidth]);

  useEffect(() => {
    setValue(props.initialValue == null ? max / 2 : props.initialValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <input
        className={styles.range}
        type="range"
        ref={ref}
        min={min}
        max={max}
        step={step}
        value={value}
        onInput={(e) => {
          const newValue = parseInt(e.target.value);

          setIsActive(true);
          resetInactiveTimeout(() => {
            setIsActive(false);

            // Calling onChange after the inactive timeout to make sure it only gets called at the end.
            // Browsers are remarkably inconsistent about when events should fire.
            if (props.onChange) {
              props.onChange(e, newValue);
            }
          });
          setValue(newValue);

          if (props.onInput) {
            props.onInput(e, newValue);
          }
        }}
        onChange={() => {}}
      />
      <div
        className={`${styles.hoverLabel} ${showLabel ? styles.visible : ""}`}
        style={{
          transform: `translateX(${amountFilled * (width - 20)}px)`,
        }}
      >
        {props.children || (
          <span className={styles.hoverLabelText}>{value}</span>
        )}
      </div>
    </div>
  );
}

Range.propTypes = {
  initialValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  showLabel: PropTypes.bool,
};
