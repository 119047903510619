// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconOneOnOne({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.754 7.931c0.004 0 0.008 0 0.013 0 1.749 0 3.166-1.418 3.166-3.166s-1.418-3.166-3.166-3.166c-1.749 0-3.166 1.418-3.166 3.166 0 0.001 0 0.001 0 0.002v-0c0 0.002 0 0.004 0 0.006 0 1.743 1.411 3.156 3.153 3.158h0z" />
      <path d="M11.965 19.942h-4.765v-8.102c0-0.019 0.001-0.041 0.001-0.063 0-1.351-1.095-2.446-2.446-2.446s-2.446 1.095-2.446 2.446c0 0.022 0 0.044 0.001 0.066l-0-0.003v9.506c0 0.001 0 0.002 0 0.003 0 1.352 1.094 2.448 2.444 2.453h5.28v4.669c0 1.060 0.86 1.92 1.92 1.92s1.92-0.86 1.92-1.92v0-6.6c0-0.002 0-0.005 0-0.008 0-1.056-0.853-1.914-1.908-1.92h-0.001z" />
      <path d="M27.245 7.931c0.004 0 0.008 0 0.013 0 1.749 0 3.166-1.418 3.166-3.166s-1.418-3.166-3.166-3.166c-1.749 0-3.166 1.418-3.166 3.166 0 0.001 0 0.001 0 0.002v-0c0 0.001 0 0.003 0 0.005 0 1.743 1.411 3.156 3.153 3.16h0z" />
      <path d="M27.245 9.386c-1.351 0.005-2.445 1.102-2.445 2.454 0 0 0 0 0 0v0 8.102h-4.765c-1.060 0-1.92 0.86-1.92 1.92v0 6.6c0 1.060 0.86 1.92 1.92 1.92s1.92-0.86 1.92-1.92v0-4.661h5.28c1.352-0.005 2.446-1.102 2.446-2.454 0-0.001 0-0.001 0-0.002v0-9.506c0-0 0-0 0-0 0-1.349-1.089-2.444-2.436-2.454h-0.001z" />
      <path d="M10.122 16.030h11.758v1.934h-11.758v-1.934z" />
    </svg>
  );
}

IconOneOnOne.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
