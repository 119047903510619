// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconCartLarge({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.534 19.503l13.409-1.342c0.401-0.046 0.725-0.355 0.772-0.756l1.157-9.042c0.031-0.247-0.046-0.494-0.201-0.679s-0.386-0.293-0.633-0.309l-19.164-0.694-1.389-3.24c-0.123-0.293-0.401-0.494-0.725-0.525l-4.706-0.463c-0.478-0.046-0.91 0.309-0.957 0.787s0.309 0.91 0.787 0.957l4.182 0.401 1.404 3.256c0 0 0 0 0 0.015l4.598 10.662-1.42 1.744c-0.015 0.015-0.015 0.031-0.031 0.046-0.185 0.262-1.096 1.651-0.54 2.747 0.216 0.432 0.741 0.941 1.929 0.941 2.083 0 15.183-0.093 15.307-0.093 0.478 0 0.864-0.401 0.864-0.88s-0.386-0.864-0.88-0.864v0c-0.139 0-13.224 0.093-15.307 0.093-0.185 0-0.293-0.015-0.355-0.031 0.015-0.201 0.185-0.586 0.386-0.88l1.512-1.852zM10.646 8.455l17.39 0.633-0.957 7.407-12.437 1.234-3.996-9.274z" />
      <path d="M15.614 27.696c0 1.014-0.822 1.836-1.836 1.836s-1.836-0.822-1.836-1.836c0-1.014 0.822-1.836 1.836-1.836s1.836 0.822 1.836 1.836z" />
      <path d="M28.005 27.696c0 1.014-0.822 1.836-1.836 1.836s-1.836-0.822-1.836-1.836c0-1.014 0.822-1.836 1.836-1.836s1.836 0.822 1.836 1.836z" />
    </svg>
  );
}

IconCartLarge.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
