// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconQualifications({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.564 20.278v-8.263c4.911 2.517 7.738 5.591 7.738 5.591s-3.907 1.066-7.738 2.672v0zM17.39 19.305c-0.015 0-0.031 0-0.046 0.015-3.305 1.529-6.981 2.826-6.981 2.826v-15.475c0 0 3.691 1.297 6.981 2.826 0.015 0.015 0.015 0.031 0.031 0.046l0.015 9.761zM7.459 29.9c-0.973 0-1.761-0.772-1.761-1.714v-22.333h3.506v22.333c0 0.942-0.788 1.714-1.745 1.714v0zM5.699 3.814c0-1.127 1.112-1.992 2.317-1.637 0.726 0.232 1.189 0.958 1.189 1.714v0.788h-3.506v-0.865z" />
    </svg>
  );
}

IconQualifications.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
