import styles from "./index.module.css";
import React, { FunctionComponent, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";

const propTypes = {
  /**
   * String to display as the nav item title.
   */
  title: PropTypes.string.isRequired,

  /**
   * Something to render as the nav item icon.
   */
  icon: PropTypes.node.isRequired,

  /**
   * Path to navigate to when the nav item is clicked.
   */
  path: PropTypes.string.isRequired,
}

type NavLinkPropTypes = PropTypes.InferProps<typeof propTypes>;

export const NavLink: FunctionComponent<NavLinkPropTypes> = ({ title, path }) => {
  const { pathname } = useLocation();

  const isActive = useMemo(() => {
    if (path === pathname) {
      return true;
    }

    if (path === "/") {
      if (pathname === "/" || pathname === "") {
        return true;
      }
    } else if (pathname.startsWith(path)) {
      return true;
    }

    return false;
  }, [path, pathname]);

  return (
    <Link
      className={classNames(styles.navLink, isActive && styles.active)}
      to={path}
    >
      {title}
    </Link>
  );
}

NavLink.propTypes = propTypes;
