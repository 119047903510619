import styles from "./FlowContainer.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useParsedSize } from "./hooks/useParsedSize";
import { useFlowLayout } from "./hooks/useFlowLayout";
import React, { forwardRef } from "react";
import { useParsedAlign } from "./hooks/useParsedAlign";

const propTypes = {
  /**
   * Optional name for this container. Displayed when `debug` option is turned on.
   */
  name: PropTypes.string,

  /**
   * A number of pixels or a CSS string.
   *
   * Pass "auto" to have the container take up as much of its parent container as possible.
   */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Flex direction for the container.
   */
  direction: PropTypes.oneOf([
    "row",
    "row-reverse",
    "column",
    "column-reverse",
  ]),

  /**
   * Align items along this edge. Defaults to "stretch" which forces items to take up the full width.
   */
  align: PropTypes.oneOf([
    "top", "right", "bottom", "left", "stretch"
  ]),

  /**
   * Amount of space between items in the container. Number of pixels or a CSS string.
   */
  gutter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Amount of padding around the container's items. Number of pixels or a CSS `padding` string.
   */
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * If false, prevent scrolling in this container.
   */
  scroll: PropTypes.bool,

  /**
   * Extra classname to append to the container div.
   */
  className: PropTypes.string,

  /**
   * Extra classname to append to the content div.
   */
  contentClassName: PropTypes.string,

  /**
   * Inline styles to apply to the container div.
   */
  style: PropTypes.object,

  /**
   * Items to render into the container.
   */
  children: PropTypes.node,
};

type FlowContainerProps = PropTypes.InferProps<typeof propTypes>;

/**
 * Layout container that flows children in one direction.
 */
export const FlowContainer = forwardRef(function FlowContainer(
  props: FlowContainerProps,
  ref: any
) {
  const { name, size, align, direction, children, scroll = true, ...others } = props;
  const { debug } = useFlowLayout();
  const flex = useParsedSize(size);
  const alignItems = useParsedAlign(direction, align);

  if (process.env.NODE_ENV !== "production") {
    if (debug && name == null) {
      console.warn(
        `FlowContainer is missing a name in debug mode. You should pass a \`name\` prop if you want this container to be labeled.`
      );
    }
  }

  return (
    <div
      ref={ref}
      {...others}
      className={classNames(styles.flowContainer, props.className, {
        [styles.noScroll]: !scroll,
        [styles.debug]: debug,
      })}
      style={
        {
          ...(props.style || {}),
          flex,
        } as unknown
      }
    >
      <div
        className={classNames(styles.flowContent, props.contentClassName, {
          [styles.up]: direction === "column-reverse",
          [styles.right]: direction === "row",
          [styles.down]: direction === "column",
          [styles.left]: direction === "row-reverse",
        })}
        style={
          {
            "--flowPadding": props.padding || 0,
            "--flowGutter": props.gutter || 0,
            flexDirection: direction,
            alignItems,
          } as unknown
        }
      >
        {children}
      </div>

      {debug && name && (
        <span className={styles.debugName} title={name}>
          {name}
        </span>
      )}
    </div>
  );
});

FlowContainer.propTypes = propTypes;
