import { useEffect } from "react";
import React from "react";
import styles from "./index.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Button } from "../Button/Button";

export function Modal({ animation = "None", visible, onClick, children }) {
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27 && visible && typeof onClick === "function") {
        onClick();
      }
    };

    window.addEventListener("keydown", close);

    return () => {
      window.removeEventListener("keydown", close);
    };
  }, [onClick, visible]);

  return (
    <div
      className={classNames(styles.modalOverlay, {
        [styles.show]: visible,
      })}
      onClick={(e) => {
        if (typeof onClick === "function") {
          onClick();
        }
      }}
    >
      <div
        className={classNames(styles.innerModalBox, {
          [styles.animateDown]: animation === "Down",
          [styles.animateUp]: animation === "Up",
          [styles.show]: visible,
        })}
        onClick={(e) => {
          // Prevent clicks in the modal from bubbling up to the overlay's onClick handler and closing it.
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  /**
   * Controls the modal animation. Defaults to None.
   */
  animation: PropTypes.oneOf(["Up", "Down", "None"]),

  /**
   * Shows the modal if true
   */
  visible: PropTypes.bool,

  /**
   * Called when the user clicks the overlay
   */
  onClick: PropTypes.func,

  /**
   * The modal content. Defines the look of the modal panel.
   */
  children: PropTypes.node,
};

export function ExampleModal({ toggleFunction, children }) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <strong>Example</strong>
        <Button onClick={toggleFunction}>Close</Button>
      </div>
      <div className={styles.description}>
        {children ||
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos possimus dolorem similique nisi vitae? Veritatis ipsa vel ducimus eum incidunt placeat dolorum, soluta culpa quas cumque hic error suscipit a. Natus, ab culpa. Odio doloremque fuga vero id! Molestias reprehenderit architecto fuga dicta soluta eius animi ab! Hic perferendis explicabo non quidem, dolores nihil iusto corrupti blanditiis? Optio, incidunt minima? Praesentium natus dolor dicta maiores velit magni nam. Dignissimos magni consectetur pariatur tempora cupiditate quod nemo et praesentium corrupti porro dolorem vitae sunt totam quibusdam quo vel quaerat, odio obcaecati!"}
      </div>
    </div>
  );
}
