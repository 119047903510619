// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconSpringLeadership({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.81 11.931c-0.286-0.483-0.689-0.869-1.17-1.128l-0.016-0.008c-0.461-0.262-1.012-0.417-1.6-0.418h-0c-0.023-0.001-0.050-0.001-0.077-0.001-0.563 0-1.075 0.216-1.459 0.569l0.002-0.001c-0.367 0.313-0.601 0.774-0.608 1.29v0.001c0.001 0.388 0.145 0.743 0.384 1.013l-0.001-0.002c0.379 0.448 1.256 1.042 2.646 1.794q1.272 0.683 1.942 1.12v-4.078c0.001-0.011 0.001-0.024 0.001-0.038s-0-0.026-0.001-0.039l0 0.002c-0.014-0.026-0.029-0.050-0.043-0.075z" />
      <path d="M16 1.6c-7.953 0-14.4 6.447-14.4 14.4s6.447 14.4 14.4 14.4c7.953 0 14.4-6.447 14.4-14.4v0c0-7.953-6.447-14.4-14.4-14.4v0zM22.498 22.061h-8.187c-0.459 0.177-0.989 0.279-1.544 0.279-0.020 0-0.039-0-0.059-0l0.003 0c-0.008 0-0.017 0-0.026 0-0.341 0-0.675-0.029-1-0.085l0.035 0.005c-0.461-0.1-0.846-0.21-1.221-0.342l0.070 0.022c-0.318-0.125-0.691-0.223-1.077-0.278l-0.027-0.003c-0.007-0-0.015-0.001-0.024-0.001-0.128 0-0.244 0.051-0.328 0.134l0-0c-0.111 0.155-0.181 0.346-0.192 0.553l-0 0.003h-0.336v-4.16h0.331c0.16 0.87 0.363 1.523 0.624 1.955 0.298 0.461 0.706 0.827 1.187 1.067l0.017 0.008c0.503 0.271 1.101 0.431 1.736 0.431 0.013 0 0.026-0 0.039-0l-0.002 0c0.746 0 1.333-0.197 1.76-0.59 0.255-0.222 0.45-0.507 0.561-0.831l0.004-0.014c0.053-0.16 0.083-0.345 0.083-0.537 0-0.004 0-0.008-0-0.012v0.001c-0-0.183-0.029-0.359-0.082-0.524l0.003 0.012c-0.046-0.151-0.1-0.281-0.166-0.404l0.006 0.012c-0.197-0.341-0.454-0.626-0.759-0.849l-0.008-0.005c-0.235-0.179-0.869-0.558-1.907-1.139-0.851-0.457-1.574-0.919-2.261-1.427l0.049 0.035c-0.407-0.309-0.742-0.689-0.992-1.124l-0.010-0.018c-0.214-0.389-0.339-0.852-0.339-1.344 0-0.012 0-0.024 0-0.037l-0 0.002c0-0.003-0-0.006-0-0.010 0-0.886 0.386-1.681 0.999-2.228l0.003-0.003q1.002-0.941 2.547-0.942c0.556 0.007 1.086 0.108 1.578 0.289l-0.033-0.011c0.16 0.056 0.333 0.122 0.502 0.194 0.204 0.11 0.442 0.19 0.695 0.224l0.011 0.001c0.006 0 0.013 0 0.020 0 0.139 0 0.266-0.052 0.361-0.138l-0.001 0c0.071-0.078 0.126-0.172 0.159-0.276l0.001-0.005h3.286v0.33c-0.64 0-1.096 0.054-1.354 0.179-0.229 0.098-0.413 0.265-0.53 0.474l-0.003 0.006c-0.094 0.19-0.144 0.64-0.144 1.366v7.618c0 0.496 0.050 0.837 0.144 1.022 0.075 0.127 0.189 0.224 0.325 0.275l0.004 0.001c0.15 0.059 0.613 0.086 1.395 0.086h0.885c0.93 0 1.582-0.066 1.96-0.205 0.409-0.16 0.754-0.408 1.023-0.723l0.003-0.003c0.31-0.35 0.621-0.899 0.93-1.651l0.296 0.064z" />
    </svg>
  );
}

IconSpringLeadership.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
