// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconQuestion({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 2.956c7.204 0 13.044 5.84 13.044 13.044s-5.84 13.044-13.044 13.044c-7.204 0-13.044-5.84-13.044-13.044v0c0.009-7.201 5.844-13.036 13.044-13.044h0.001zM16 2.1c-7.677 0-13.9 6.223-13.9 13.9s6.223 13.9 13.9 13.9c7.677 0 13.9-6.223 13.9-13.9v0c0-7.677-6.223-13.9-13.9-13.9v0z" />
      <path d="M14.168 19.067l-0.046-0.605c-0.093-1.236 0.309-2.521 1.399-3.827 0.887-1.049 1.493-1.889 1.493-2.799 0-0.981-0.63-1.632-1.937-1.657-0.792 0-1.725 0.258-2.332 0.701l-0.676-2.054c0.815-0.536 2.162-0.956 3.639-0.956 2.893 0 4.223 1.68 4.223 3.552 0 1.699-1.002 2.846-2.008 3.989-0.927 1.073-1.283 2.029-1.236 3.149v0.513zM15.334 24.13c-1.026 0-1.748-0.794-1.748-1.819 0-1.081 0.746-1.819 1.773-1.819s1.773 0.746 1.773 1.819c0 1.026-0.701 1.819-1.773 1.819z" />
    </svg>
  );
}

IconQuestion.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
