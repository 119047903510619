// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconArchive({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M28.266 12.271h-24.532c-0.878 0-1.603 0.724-1.603 1.603v13.576c0 0.878 0.724 1.603 1.603 1.603h24.532c0.878 0 1.603-0.724 1.603-1.603v-13.576c0-0.878-0.724-1.603-1.603-1.603zM12.487 16.616c0-0.37 0.308-0.678 0.678-0.678h5.655c0.37 0 0.678 0.308 0.678 0.678v3.282c0 0.37-0.308 0.678-0.678 0.678h-5.655c-0.37 0-0.678-0.308-0.678-0.678v-3.282zM22.349 26.417h-12.698v-2.666h2.065v1.032h8.537v-1.032h2.111v2.666z" />
      <path d="M5.044 9.389v-5.347c0 0 0.154-1.094 1.094-1.094s19.555 0 19.555 0 1.248 0 1.248 1.248 0 5.178 0 5.178h-1.649l-2.327-3.221h-13.653l-2.327 3.236h-1.942z" />
      <path d="M9.466 7.401h13.052v1.294h-13.052v-1.294z" />
      <path d="M7.771 9.898h16.458v1.371h-16.458v-1.371z" />
    </svg>
  );
}

IconArchive.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
