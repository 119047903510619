// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconLOSTree({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M30.717 23.117c-0 2.392-1.939 4.331-4.332 4.331s-4.332-1.939-4.332-4.332c0-2.259 1.729-4.114 3.935-4.314l0.017-0.001v-2.412h-9.468v2.423c2.165 0.27 3.823 2.099 3.823 4.315 0 2.4-1.946 4.346-4.346 4.346s-4.346-1.946-4.346-4.346c0-2.185 1.613-3.994 3.713-4.3l0.023-0.003v-2.435h-9.229v2.435c2.133 0.29 3.76 2.1 3.76 4.289 0 2.39-1.937 4.327-4.327 4.327s-4.327-1.937-4.327-4.327c0-2.19 1.627-4 3.738-4.287l0.022-0.003v-3c0.001-0.313 0.254-0.566 0.567-0.567h9.795v-2.14c-2.107-0.309-3.707-2.105-3.707-4.274 0-2.384 1.933-4.317 4.317-4.317s4.317 1.933 4.317 4.317c0 2.2-1.645 4.015-3.772 4.283l-0.021 0.002v2.126h10.033c0.312 0.002 0.565 0.255 0.566 0.567v3.027c2.047 0.371 3.579 2.14 3.579 4.267 0 0.001 0 0.002 0 0.002v-0z" />
    </svg>
  );
}

IconLOSTree.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
