import styles from "./CalendarPicker.module.css";
import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { ChevronLeft, ChevronRight } from "react-ikonate";

import startOfMonth from "date-fns/startOfMonth";
import addDays from "date-fns/addDays";
import isSameMonth from "date-fns/isSameMonth";
import isSameDay from "date-fns/isSameDay";

const monthNames = [
  null,
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function CalendarPicker({ value, onChange }) {
  const [visibleMonth, setVisibleMonth] = useState(value.slice(0, 2));

  const [yearLabel, monthLabel] = useMemo(() => {
    const year = visibleMonth[0];
    const month = monthNames[visibleMonth[1]];

    return [year, month];
  }, [visibleMonth]);

  const weeks = useMemo(() => {
    const date = new Date(visibleMonth[0], visibleMonth[1] - 1);
    const leadingDays = startOfMonth(date).getDay();

    let cursor = addDays(date, -leadingDays);
    let weeks = [];

    for (let w = 0; w < 6; w++) {
      let week = [];

      for (let d = 0; d < 7; d++) {
        week.push({
          label: cursor.getDate().toString(),
          date: cursor,
          value: getDateValue(cursor),
          inMonth: isSameMonth(date, cursor),
        });

        cursor = addDays(cursor, 1);
      }

      weeks.push(week);
    }

    return weeks;
  }, [visibleMonth]);

  const selectedDate = new Date(value);

  return (
    <div className={styles.container}>
      <header>
        <button
          className={styles.navButton}
          onClick={(e) => {
            e.preventDefault();

            let [year, month] = visibleMonth;
            if (month === 1) {
              month = 12;
              year -= 1;
            } else {
              month -= 1;
            }

            setVisibleMonth([year, month]);
          }}
        >
          <ChevronLeft fontSize={18} strokeWidth={1.5} />
        </button>
        <div>
          <span className={styles.dateLabel}>
            {monthLabel} {yearLabel}
          </span>
        </div>
        <button
          className={styles.navButton}
          onClick={(e) => {
            e.preventDefault();

            let [year, month] = visibleMonth;
            if (month === 12) {
              month = 1;
              year += 1;
            } else {
              month += 1;
            }

            setVisibleMonth([year, month]);
          }}
        >
          <ChevronRight fontSize={18} strokeWidth={1.5} />
        </button>
      </header>
      <div className={styles.month}>
        <div className={styles.dayLabels}>
          <span>SUN</span>
          <span>MON</span>
          <span>TUE</span>
          <span>WED</span>
          <span>THU</span>
          <span>FRI</span>
          <span>SAT</span>
        </div>

        {weeks.map((week, i) => (
          <div key={i} className={styles.week}>
            {week.map((day) => (
              <div
                key={day.label}
                className={classNames(styles.day, {
                  [styles.outsideOfMonth]: !day.inMonth,
                  [styles.selectedDay]: isSameDay(selectedDate, day.date),
                })}
                onClick={(e) => {
                  e.preventDefault();
                  onChange(day.value);
                }}
              >
                <span>{day.label}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

function getDateValue(date) {
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();

  return [y, m, d];
}
