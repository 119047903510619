// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconFreeEnterprise({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 1.6c-7.953 0-14.4 6.447-14.4 14.4s6.447 14.4 14.4 14.4c7.953 0 14.4-6.447 14.4-14.4v0c0-7.953-6.447-14.4-14.4-14.4v0zM24.102 21.938h-9.77v-0.338h0.448c0.001 0 0.002 0 0.004 0 0.311 0 0.603-0.080 0.857-0.221l-0.009 0.005c0.189-0.090 0.334-0.249 0.405-0.443l0.002-0.005q0.107-0.299 0.107-1.226v-7.666c-0.113-0.495-0.309-0.931-0.573-1.315l0.008 0.013c-0.205-0.267-0.476-0.474-0.787-0.599l-0.013-0.004q-0.48-0.187-1.464-0.187h-2.789v4.982h1.222q0.8 0 1.165-0.35t0.491-1.387h0.354v4.291h-0.354q-0.010-0.739-0.192-1.085c-0.114-0.222-0.288-0.4-0.499-0.517l-0.006-0.003c-0.248-0.111-0.538-0.176-0.843-0.176-0.041 0-0.082 0.001-0.122 0.004l0.006-0h-1.221v6.227h-3.608v-0.338h0.448c0.525 0 0.904-0.16 1.141-0.459 0.15-0.198 0.224-0.678 0.224-1.44v-8.197q0-0.96-0.122-1.27c-0.080-0.175-0.213-0.315-0.378-0.401l-0.005-0.002c-0.247-0.144-0.544-0.229-0.86-0.23h-0.448v-0.339h17.182l0.139 2.776h-0.363q-0.198-0.997-0.435-1.374c-0.171-0.256-0.412-0.453-0.695-0.566l-0.010-0.004q-0.374-0.141-1.318-0.141h-3.478v5.011h2.786q1.085 0 1.45-0.32 0.48-0.429 0.542-1.514h0.346v4.413h-0.344c-0.088-0.618-0.174-1.013-0.262-1.187-0.124-0.228-0.314-0.407-0.545-0.512l-0.007-0.003c-0.256-0.125-0.64-0.186-1.179-0.186h-2.786v4.17q0 0.842 0.075 1.024c0.053 0.125 0.145 0.225 0.259 0.288l0.003 0.002c0.173 0.071 0.374 0.112 0.584 0.112 0.044 0 0.088-0.002 0.132-0.005l-0.006 0h2.15c0.069 0.003 0.149 0.005 0.23 0.005 0.472 0 0.931-0.056 1.37-0.161l-0.040 0.008c0.368-0.125 0.682-0.327 0.935-0.588l0.001-0.001c0.463-0.504 0.86-1.079 1.168-1.706l0.019-0.043h0.373z" />
    </svg>
  );
}

IconFreeEnterprise.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
