// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconPDF({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M30.874 20.117c-0.069-0.299-0.222-0.555-0.432-0.75-0.589-0.584-1.892-0.894-3.875-0.92-1.644 0.001-3.259 0.126-4.835 0.367-0.584-0.465-1.368-0.947-1.983-1.526-1.656-1.563-3.041-3.725-3.9-6.105 0.056-0.222 0.103-0.418 0.149-0.617 0 0 0.933-5.342 0.686-7.147-0.021-0.189-0.063-0.362-0.126-0.526l-0.077-0.196c-0.255-0.591-0.753-1.217-1.532-1.182l-0.47-0.015c-0.874 0-1.578 0.449-1.764 1.119-0.565 2.103 0.018 5.247 1.076 9.321l-0.271 0.666c-0.758 1.859-1.712 3.733-2.545 5.39l-0.109 0.216c-0.882 1.738-1.681 3.214-2.406 4.463l-0.748 0.398c-0.054 0.029-1.338 0.712-1.633 0.897-2.554 1.538-4.245 3.276-4.526 4.66-0.090 0.441-0.023 1.006 0.431 1.267l0.724 0.367c0.285 0.149 0.622 0.237 0.979 0.238 1.818 0 3.92-2.286 6.834-7.395 2.947-1.006 6.493-1.884 10.137-2.47 2.94 1.397 6.077 2.398 8.063 2.398 0.019 0 0.042 0.001 0.065 0.001 0.297 0 0.586-0.037 0.861-0.105 0.348-0.088 0.659-0.308 0.867-0.606 0.385-0.583 0.464-1.39 0.359-2.206zM3.298 29.064c0.327-0.913 1.643-2.719 3.583-4.321 0.121-0.1 0.423-0.384 0.697-0.653-2.028 3.268-3.387 4.566-4.281 4.975zM14.786 2.396c0.585 0 0.916 1.485 0.944 2.876s-0.296 2.368-0.696 3.087c-0.327-1.070-0.49-2.757-0.49-3.859-0.002 0.005-0.026-2.103 0.242-2.103zM11.356 21.401c0.407-0.735 0.83-1.509 1.262-2.331 0.719-1.314 1.465-2.92 2.115-4.575 1.072 1.481 2.295 2.995 3.745 4.254q0.291 0.249 0.591 0.479c-2.961 0.571-5.546 1.322-8.028 2.28zM29.873 21.238c-0.178 0.113-0.691 0.178-1.021 0.178-1.063 0-2.38-0.49-4.225-1.287 0.709-0.052 1.359-0.080 1.942-0.080 1.066 0 1.383 0 2.427 0.265s1.060 0.807 0.88 0.921z" />
    </svg>
  );
}

IconPDF.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
