// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconPulseMe({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23.908 29.746h-15.815c-2.879 0-4.834-1.754-4.834-4.686 0-4.093 0.958-10.374 6.261-10.374 0.559 0 2.934 2.517 6.479 2.517s5.918-2.517 6.487-2.517c5.302 0 6.263 6.281 6.263 10.374-0.006 2.931-1.961 4.686-4.84 4.686zM16 15.846c-3.838 0-6.95-3.112-6.95-6.95s3.112-6.95 6.95-6.95c3.838 0 6.95 3.112 6.95 6.95v0c0 3.838-3.112 6.95-6.95 6.95v0z" />
    </svg>
  );
}

IconPulseMe.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
