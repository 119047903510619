// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconNutsAndBolts({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.48 25.181c-0.125-0.117-0.294-0.19-0.48-0.19s-0.355 0.072-0.48 0.19l0-0-4.080 4.066c-0.123 0.123-0.199 0.293-0.199 0.48 0 0.375 0.304 0.679 0.679 0.679 0.187 0 0.357-0.076 0.48-0.199v0l3.6-3.592 3.598 3.587c0.123 0.122 0.293 0.198 0.48 0.198s0.357-0.076 0.48-0.198l-0 0c0.125-0.122 0.202-0.292 0.202-0.48s-0.077-0.358-0.202-0.48l-0-0z" />
      <path d="M26.133 3.394h-8.203v-0.779c-0.003-0.56-0.457-1.014-1.017-1.014h-1.824c-0.561 0.001-1.015 0.454-1.018 1.014v0.779h-8.205c-0 0-0.001 0-0.002 0-0.519 0-0.94 0.42-0.941 0.939v18.939c0.001 0.519 0.422 0.939 0.941 0.939 0.001 0 0.001 0 0.002 0h20.267c0.001 0 0.002 0 0.003 0 0.519 0 0.94-0.42 0.941-0.939v-18.939c0 0 0 0 0 0 0-0.519-0.421-0.939-0.939-0.939-0.002 0-0.003 0-0.005 0h0zM25.19 22.331h-18.381v-17.051h18.381z" />
      <path d="M14.475 12.886l1.078-1.077-1.406-1.402 1.406-1.403-1.078-1.075-1.408 1.403-1.41-1.403-1.077 1.075 1.408 1.403-1.408 1.402 1.077 1.077 1.41-1.403 1.408 1.403z" />
      <path d="M18.851 14.080c-1.41 0.005-2.552 1.149-2.552 2.56 0 1.414 1.146 2.56 2.56 2.56s2.56-1.146 2.56-2.56v0c0 0 0 0 0 0 0-1.414-1.146-2.56-2.56-2.56-0.003 0-0.006 0-0.008 0h0zM18.851 17.677c-0.572-0.002-1.035-0.466-1.035-1.038 0-0.573 0.465-1.038 1.038-1.038s1.038 0.465 1.038 1.038c0 0.001 0 0.001 0 0.002v-0c0 0.575-0.466 1.042-1.042 1.042v0z" />
    </svg>
  );
}

IconNutsAndBolts.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
