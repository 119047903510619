// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconStandingOrder({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.1 21.872c1.019 0.608 2.753 1.278 4.427 1.278 2.373 0 3.514-1.171 3.514-2.723 0-1.582-0.943-2.419-3.362-3.331-3.331-1.202-4.868-3.027-4.868-5.172 0-2.951 2.419-5.37 6.313-5.37 1.856 0 3.468 0.487 4.457 1.141l-0.791 2.373c-0.715-0.411-2.038-1.050-3.742-1.050-1.962 0-3.027 1.126-3.027 2.495 0 1.506 1.065 2.191 3.438 3.134 3.134 1.171 4.792 2.753 4.792 5.476 0 3.255-2.525 5.522-6.8 5.522-2.008 0-3.849-0.532-5.142-1.278l0.791-2.495z" />
      <path d="M20.883 6.34c-2.738 0-5.187 1.080-6.845 3.042l-0.152 0.472c-0.046-0.030-0.091-0.061-0.152-0.076-1.019 1.369-1.704 3.118-1.871 5.218 2.282 1.11 3.484 2.601 3.484 4.913 0 1.171-0.335 2.206-0.943 3.058 1.567 1.597 3.742 2.495 6.176 2.495h0.030c4.351 0 9.066-2.951 9.066-9.751 0.015-5.552-3.529-9.371-8.793-9.371zM20.746 22.967h-0.030c-3.21 0-5.476-3.027-5.476-7.028 0-3.468 1.704-7.104 5.583-7.104 3.925 0 5.476 3.894 5.476 6.982 0 4.168-2.388 7.15-5.552 7.15z" />
      <path d="M13.886 9.854l0.152-0.472c-0.106 0.122-0.213 0.259-0.304 0.38 0.046 0.046 0.106 0.076 0.152 0.091z" />
    </svg>
  );
}

IconStandingOrder.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
