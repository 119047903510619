// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconTrendUp({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23.104 9.050l-5.69-6.317h-0.009c-0.342-0.392-0.842-0.638-1.399-0.638s-1.058 0.246-1.397 0.636l-0.002 0.002h-0.009l-5.702 6.317c-0.302 0.334-0.488 0.778-0.488 1.266 0 1.043 0.846 1.889 1.889 1.889 0.004 0 0.007 0 0.011-0h-0.001c0.001 0 0.003 0 0.004 0 0.554 0 1.053-0.238 1.4-0.616l0.001-0.001 2.395-2.653v19.071c0 1.045 0.847 1.892 1.892 1.892s1.892-0.847 1.892-1.892v0-19.071l2.383 2.655c0.351 0.38 0.853 0.618 1.409 0.618 0 0 0 0 0.001 0h-0c0.006 0 0.013 0 0.019 0 1.043 0 1.889-0.846 1.889-1.889 0-0.489-0.186-0.934-0.49-1.27l0.001 0.002z" />
    </svg>
  );
}

IconTrendUp.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
