// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconLock({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.49 1.6c0.262 0.030 0.525 0.053 0.786 0.093 3.812 0.575 6.794 3.527 7.408 7.276l0.007 0.051c0.063 0.393 0.101 0.848 0.104 1.311v0.003c0.014 1.12 0 2.24 0 3.373v0.16h0.461c0.011-0 0.024-0.001 0.037-0.001 0.701 0 1.269 0.568 1.269 1.269 0 0.011-0 0.023-0 0.034l0-0.002v13.92c0.001 0.014 0.001 0.031 0.001 0.047 0 0.699-0.567 1.266-1.266 1.266-0.015 0-0.030-0-0.045-0.001l0.002 0h-18.512c-0.010 0-0.022 0-0.034 0-0.7 0-1.267-0.567-1.267-1.267 0-0.016 0-0.033 0.001-0.049l-0 0.002v-13.896c-0.001-0.017-0.001-0.037-0.001-0.057 0-0.701 0.568-1.269 1.269-1.269 0.018 0 0.037 0 0.055 0.001l-0.003-0h0.44v-0.16c0-1.21-0.032-2.421 0.010-3.629 0.151-4.082 3.063-7.442 6.917-8.276l0.058-0.010c0.389-0.082 0.789-0.112 1.184-0.16l0.152-0.029zM21.28 13.856c0-0.040 0-0.067 0-0.096q0-1.71 0-3.421c-0.001-0.481-0.070-0.946-0.199-1.385l0.009 0.035c-0.635-2.254-2.673-3.879-5.091-3.879-0.368 0-0.726 0.038-1.073 0.109l0.034-0.006c-2.349 0.445-4.176 2.563-4.24 4.949-0.027 1.198 0 2.403 0 3.598 0.001 0.033 0.005 0.063 0.010 0.094l-0.001-0.004zM14.72 23.75c0 0.659 0 1.32 0 1.979 0.078 0.639 0.617 1.129 1.271 1.129 0.66 0 1.203-0.499 1.272-1.14l0-0.006q0-2 0-4c-0-0.005-0.001-0.010-0.001-0.015 0-0.065 0.030-0.124 0.077-0.162l0-0c0.467-0.38 0.762-0.955 0.762-1.599 0-0.108-0.008-0.215-0.024-0.319l0.001 0.012c-0.156-1.019-1.026-1.79-2.076-1.79-0.048 0-0.096 0.002-0.143 0.005l0.006-0c-0.923 0.090-1.57 0.57-1.856 1.44s-0.035 1.643 0.653 2.262c0.039 0.042 0.064 0.098 0.064 0.16v0c0 0.683-0.008 1.363-0.008 2.043z" />
    </svg>
  );
}

IconLock.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
