// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconDelete({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 2.393c7.515 0 13.607 6.092 13.607 13.607s-6.092 13.607-13.607 13.607c-7.515 0-13.607-6.092-13.607-13.607 0.009-7.511 6.096-13.598 13.606-13.607zM16 1.5c-8.008 0-14.5 6.492-14.5 14.5s6.492 14.5 14.5 14.5c8.008 0 14.5-6.492 14.5-14.5s-6.492-14.5-14.5-14.5v0z" />
      <path d="M21.578 22.314c-0 0-0.001 0-0.002 0-0.203 0-0.387-0.083-0.52-0.216l-11.153-11.153c-0.119-0.131-0.192-0.305-0.192-0.497 0-0.408 0.33-0.738 0.738-0.738 0.192 0 0.366 0.073 0.497 0.193l11.153 11.153c0.133 0.133 0.215 0.317 0.215 0.52 0 0.407-0.329 0.736-0.735 0.738z" />
      <path d="M10.422 22.314c-0 0-0 0-0 0-0.408 0-0.738-0.33-0.738-0.738 0-0.204 0.083-0.388 0.216-0.522l11.153-11.153c0.135-0.148 0.33-0.241 0.546-0.241 0.407 0 0.738 0.33 0.738 0.738 0 0.216-0.093 0.41-0.241 0.545l-11.152 11.156c-0.133 0.133-0.317 0.216-0.521 0.216-0 0-0.001 0-0.001 0z" />
    </svg>
  );
}

IconDelete.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
