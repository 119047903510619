// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconReports({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.861 7.969v-5.869h-13.437v27.8h19.151v-21.931h-5.714zM7.66 4.571h10.811v1.39h-10.811v-1.39zM14.919 27.892h-7.413v-0.772h7.413v0.772zM14.919 26.039h-7.413v-0.772h7.413v0.772zM14.919 24.031h-7.413v-0.772h7.413v0.772zM14.548 18.626c0 1.931-1.56 3.506-3.49 3.506s-3.506-1.575-3.506-3.506 1.575-3.49 3.506-3.49v3.49h3.49zM11.521 18.162v-3.367c1.56 0.139 3.305 1.452 3.305 3.367h-3.305zM14.919 13.683h-7.413v-0.772h7.413v0.772zM14.919 11.676h-7.413v-0.772h7.413v0.772zM14.919 9.668h-7.413v-0.772h7.413v0.772zM24.34 28.047h-7.259v-7.413h0.772v6.487h6.487v0.927zM20.942 22.796v-0.463h1.699v1.699h-0.463v-0.958l-3.599 3.599-0.278-0.278 3.599-3.599h-0.958zM24.494 15.537h-7.413v-0.772h7.413v0.772zM24.494 13.683h-7.413v-0.772h7.413v0.772zM24.494 11.676h-7.413v-0.772h7.413v0.772zM24.494 9.668h-7.413v-0.772h7.413v0.772z" />
      <path d="M20.479 2.254v5.097h5.097z" />
    </svg>
  );
}

IconReports.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
