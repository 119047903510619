// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconNotifications({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.167 6.9c-0.362 0-0.655-0.293-0.655-0.655v-4.645c0-0.362 0.293-0.655 0.655-0.655s0.655 0.293 0.655 0.655v4.644c0 0.362-0.293 0.655-0.655 0.655z" />
      <path d="M20.177 7.805c-0.168 0-0.335-0.064-0.463-0.192-0.256-0.256-0.256-0.671-0-0.927l3.526-3.527c0.256-0.256 0.671-0.256 0.927 0s0.256 0.671 0 0.927l-3.526 3.527c-0.128 0.128-0.296 0.192-0.463 0.192z" />
      <path d="M12.156 7.805c-0.168 0-0.335-0.064-0.463-0.192l-3.526-3.527c-0.256-0.256-0.256-0.671 0-0.927s0.671-0.256 0.927 0l3.526 3.527c0.256 0.256 0.256 0.671 0 0.927-0.128 0.128-0.296 0.192-0.463 0.192z" />
      <path d="M11.657 11.040c-0.079 0-0.159-0.014-0.237-0.045l-7.105-2.761c-0.337-0.131-0.504-0.511-0.373-0.848s0.511-0.504 0.848-0.373l7.105 2.761c0.337 0.131 0.504 0.511 0.373 0.848-0.101 0.259-0.348 0.418-0.611 0.418z" />
      <path d="M20.544 11.040c-0.263 0-0.51-0.159-0.611-0.418-0.131-0.337 0.036-0.717 0.373-0.848l7.105-2.761c0.338-0.131 0.717 0.036 0.848 0.373s-0.036 0.717-0.373 0.848l-7.105 2.761c-0.078 0.031-0.158 0.045-0.237 0.045z" />
      <path d="M19.321 10.692c0 1.776-1.444 3.219-3.22 3.219-1.782 0-3.22-1.443-3.22-3.219s1.438-3.22 3.22-3.22c1.776-0 3.22 1.443 3.22 3.22z" />
      <path d="M13.969 15.303l1.251 0.256-5.568 14.841h-3.267z" />
      <path d="M18.232 15.303l-1.249 0.256 5.564 14.841h3.271z" />
      <path d="M13.683 16.273c0 0 2.743 0.598 5.040 0l0.16 0.903c-2.686 0.693-5.371 0-5.371 0l0.17-0.903z" />
      <path d="M12.229 20.886c0 0 4.336 0.939 7.966 0l0.243 1.424c-4.239 1.094-8.483 0-8.483 0l0.274-1.424z" />
      <path d="M10.481 26.165c0 0 6.242 1.36 11.464 0l0.359 2.057c-6.107 1.572-12.215 0-12.215 0l0.392-2.057z" />
    </svg>
  );
}

IconNotifications.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
