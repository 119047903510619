import styles from "./Tab.module.css";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export function Tab(props) {
  const { label, tabStyle, isSelected, onClick } = props;

  return (
    <div
      className={classNames(styles.tab, {
        [styles.styleDefault]: tabStyle == null || tabStyle === "default",
        [styles.styleGradient]: tabStyle === "gradient",
        [styles.styleiOS13]: tabStyle === "ios13",
        [styles.styleUnderline]: tabStyle === "underline",
        [styles.selected]: isSelected,
      })}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          onClick();
        }
      }}
    >
      <span>{label}</span>
    </div>
  );
}

Tab.propTypes = {
  label: PropTypes.string,
  tabStyle: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};
