import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { FlowContainer } from "../FlowContainer";

type FlowRightProps = PropTypes.InferProps<typeof FlowContainer.propTypes>;

/**
 * Layout container that flows children from left to right.
 */
export const FlowRight = forwardRef(function FlowRight(props: FlowRightProps, ref) {
  return <FlowContainer ref={ref} {...props} direction="row" />;
});

FlowRight.propTypes = FlowContainer.propTypes;
