// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconMyBusiness({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.102 15.061h4.601v11.482h-4.601v-11.482z" />
      <path d="M12.894 13.326h4.601v13.218h-4.601v-13.218z" />
      <path d="M19.23 16.173h4.601v10.371h-4.601v-10.371z" />
      <path d="M25.271 9.303h4.601v17.24h-4.601v-17.24z" />
      <path d="M2.129 9.303h0.53v19.813h-0.53v-19.813z" />
      <path d="M29.871 28.586v0.53h-27.742v-0.53h27.742z" />
      <path d="M6.034 11.39l18.634-7.627 0.254 0.62-18.634 7.627-0.254-0.62z" />
      <path d="M24.193 6.33l0.026-2.022-1.438-1.424 4.79 0.051-3.378 3.395z" />
    </svg>
  );
}

IconMyBusiness.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
