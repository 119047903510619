// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconShowThePlan({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M29.483 3.386h-7.987v-0.774c0-0.002 0-0.004 0-0.006 0-0.551-0.444-0.998-0.993-1.005h-1.802c-0.549 0.006-0.992 0.453-0.992 1.003 0 0.002 0 0.005 0 0.007v-0 0.774h-4.48c-0.516 0-0.934 0.418-0.934 0.934s0.418 0.934 0.934 0.934v0h15.336v16.971h-15.338c-0.499 0.021-0.896 0.431-0.896 0.934s0.397 0.912 0.894 0.934l0.002 0h16.256c0.507-0.004 0.917-0.416 0.917-0.923 0-0.004-0-0.008-0-0.012v0.001-18.837c0-0.003 0-0.006 0-0.010 0-0.508-0.41-0.92-0.916-0.925h-0z" />
      <path d="M7.446 7.714c1.658-0.039 2.988-1.393 2.988-3.057s-1.329-3.018-2.984-3.057l-0.004-0c-1.658 0.039-2.988 1.393-2.988 3.057s1.329 3.018 2.984 3.057l0.004 0z" />
      <path d="M20.069 25.058c-0.121-0.117-0.285-0.19-0.467-0.19s-0.347 0.072-0.467 0.19l0-0-3.973 4.042c-0.121 0.124-0.195 0.293-0.195 0.48s0.074 0.356 0.195 0.48l-0-0c0.119 0.121 0.285 0.196 0.468 0.196s0.349-0.075 0.468-0.196l0-0 3.504-3.571 3.504 3.571c0.119 0.121 0.284 0.195 0.467 0.195s0.348-0.075 0.467-0.195l0-0c0.121-0.123 0.196-0.293 0.196-0.48s-0.075-0.357-0.196-0.48l0 0z" />
      <path d="M9.28 8.982l-1.834 2.368-1.834-2.368h-2.176c-1.017 0.014-1.837 0.841-1.837 1.861 0 0.003 0 0.006 0 0.009v-0 6.909c-0 0.003-0 0.007-0 0.011 0 1.019 0.82 1.846 1.836 1.857h0.463v8.909c0 0.002-0 0.005-0 0.008 0 1.018 0.82 1.844 1.836 1.854h0.001c0.781-0.005 1.445-0.496 1.706-1.186l0.004-0.013c0.265 0.702 0.929 1.193 1.708 1.198h0.001c1.017-0.010 1.837-0.836 1.837-1.854 0-0.006-0-0.011-0-0.017v0.001-16.275h7.088c0.882-0.028 1.586-0.75 1.586-1.636s-0.704-1.608-1.583-1.636l-0.003-0z" />
    </svg>
  );
}

IconShowThePlan.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
