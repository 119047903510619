// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconWebTraining({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M29.65 11.45c0 0.006 0 0.012 0 0.019 0 0.164-0.109 0.303-0.259 0.347l-0.003 0.001-13.271 4.171c-0.033 0.008-0.071 0.012-0.11 0.012-0.003 0-0.007-0-0.010-0h0.001c-0.002 0-0.005 0-0.008 0-0.039 0-0.076-0.004-0.113-0.013l0.003 0.001-7.726-2.44q-0.511 0.403-0.842 1.321c-0.223 0.625-0.367 1.348-0.401 2.099l-0.001 0.016c0.448 0.238 0.747 0.701 0.747 1.235 0 0.020-0 0.039-0.001 0.059l0-0.003c0 0.013 0.001 0.029 0.001 0.045 0 0.516-0.273 0.968-0.682 1.22l-0.006 0.004 0.687 5.131c0.001 0.012 0.002 0.027 0.002 0.041 0 0.211-0.17 0.383-0.381 0.385h-2.275c-0.004 0-0.008 0-0.013 0-0.11 0-0.207-0.050-0.272-0.129l-0-0.001c-0.061-0.063-0.099-0.148-0.099-0.243 0-0.021 0.002-0.042 0.006-0.062l-0 0.002 0.686-5.131c-0.411-0.276-0.678-0.738-0.678-1.263 0-0.559 0.303-1.048 0.754-1.311l0.007-0.004q0.132-2.454 1.162-3.91l-3.943-1.232c-0.153-0.055-0.261-0.199-0.261-0.368s0.108-0.313 0.258-0.367l13.274-4.172c0.031-0.007 0.066-0.011 0.103-0.011 0.005 0 0.010 0 0.014 0l-0.001-0c0.003-0 0.006-0 0.010-0 0.039 0 0.077 0.004 0.113 0.013l-0.003-0.001 13.271 4.171c0.151 0.046 0.26 0.184 0.26 0.348 0 0.007-0 0.014-0.001 0.020l0-0.001zM23.371 15.289l0.212 3.745q0.047 0.817-0.971 1.517t-2.786 1.107c-1.112 0.261-2.389 0.41-3.702 0.41-0.044 0-0.088-0-0.132-0l0.007 0c-0.037 0-0.080 0-0.124 0-1.312 0-2.589-0.149-3.815-0.432l0.114 0.022q-1.77-0.408-2.788-1.107t-0.971-1.517l0.212-3.745 6.801 2.145c0.17 0.053 0.366 0.083 0.57 0.083s0.399-0.030 0.584-0.087l-0.014 0.004z" />
    </svg>
  );
}

IconWebTraining.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
