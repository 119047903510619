// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconPulse({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.9 11.411c0.459-0.088 1.059-0.153 1.822-0.153 0.937 0 1.622 0.219 2.059 0.609 0.403 0.35 0.653 0.884 0.653 1.525 0 0.666-0.197 1.188-0.578 1.569-0.5 0.534-1.319 0.806-2.244 0.806-0.284 0-0.547-0.009-0.763-0.066v2.953h-0.95v-7.244zM5.85 14.93c0.206 0.053 0.469 0.075 0.784 0.075 1.144 0 1.841-0.556 1.841-1.556 0-0.969-0.688-1.437-1.731-1.437-0.416 0-0.731 0.031-0.894 0.075v2.844z" />
      <path d="M14.26 17.205c0 0.556 0.009 1.034 0.044 1.45h-0.85l-0.056-0.859h-0.022c-0.237 0.425-0.806 0.978-1.741 0.978-0.828 0-1.822-0.469-1.822-2.309v-3.081h0.959v2.909c0 1 0.316 1.688 1.178 1.688 0.641 0 1.088-0.447 1.263-0.884 0.056-0.128 0.088-0.303 0.088-0.488v-3.225h0.959v3.822z" />
      <path d="M15.194 10.92h0.959v7.734h-0.959v-7.734z" />
      <path d="M16.981 17.673c0.294 0.175 0.794 0.372 1.275 0.372 0.684 0 1.012-0.337 1.012-0.784 0-0.459-0.272-0.697-0.969-0.959-0.959-0.35-1.406-0.872-1.406-1.494 0-0.85 0.697-1.547 1.819-1.547 0.534 0 1.003 0.144 1.288 0.328l-0.231 0.688c-0.206-0.122-0.588-0.306-1.078-0.306-0.566 0-0.872 0.328-0.872 0.719 0 0.437 0.306 0.631 0.994 0.903 0.903 0.341 1.381 0.797 1.381 1.581 0 0.937-0.728 1.591-1.959 1.591-0.578 0-1.112-0.153-1.482-0.372l0.229-0.719z" />
      <path d="M21.753 16.192c0.019 1.288 0.837 1.819 1.797 1.819 0.684 0 1.109-0.119 1.459-0.272l0.172 0.688c-0.334 0.15-0.925 0.337-1.763 0.337-1.612 0-2.594-1.078-2.594-2.659 0-1.591 0.947-2.844 2.484-2.844 1.722 0 2.169 1.516 2.169 2.484 0 0.197-0.012 0.337-0.034 0.447h-3.69zM24.55 15.505c0.012-0.609-0.25-1.556-1.316-1.556-0.981 0-1.397 0.894-1.472 1.556h2.788z" />
      <path d="M17.697 5.914c0 1.341 1.063 2.425 2.378 2.425 1.312 0 2.375-1.084 2.375-2.425 0-1.337-1.063-2.422-2.375-2.422-1.316 0-2.378 1.084-2.378 2.422z" />
      <path d="M16.45 7.848c0 0.622 0.496 1.125 1.106 1.125 0.612 0 1.106-0.503 1.106-1.125 0-0.625-0.494-1.128-1.106-1.128-0.609 0-1.106 0.503-1.106 1.128z" />
      <path d="M21.631 7.595c0 0.763 0.606 1.378 1.353 1.378 0.744 0 1.35-0.616 1.35-1.378 0-0.759-0.606-1.375-1.35-1.375-0.747 0-1.353 0.616-1.353 1.375z" />
      <path d="M23.859 9.036c0 0.763 0.606 1.378 1.353 1.378s1.35-0.616 1.35-1.378c0-0.759-0.603-1.375-1.35-1.375s-1.353 0.616-1.353 1.375z" />
      <path d="M25.031 10.877c0 0.759 0.603 1.378 1.35 1.378s1.353-0.619 1.353-1.378c0-0.763-0.606-1.378-1.353-1.378s-1.35 0.616-1.35 1.378z" />
      <path d="M25.275 13.327c0 0.763 0.603 1.378 1.353 1.378 0.744 0 1.35-0.616 1.35-1.378 0-0.759-0.606-1.378-1.35-1.378-0.75 0-1.353 0.619-1.353 1.378z" />
      <path d="M27.693 14.414c0 0.759 0.606 1.378 1.353 1.378s1.353-0.619 1.353-1.378-0.606-1.378-1.353-1.378-1.353 0.619-1.353 1.378z" />
      <path d="M24.072 12.808c0 0.759 0.603 1.378 1.35 1.378s1.353-0.619 1.353-1.378c0-0.763-0.606-1.378-1.353-1.378s-1.35 0.616-1.35 1.378z" />
      <path d="M25.35 15.555c0 0.763 0.606 1.378 1.353 1.378s1.353-0.616 1.353-1.378c0-0.759-0.606-1.378-1.353-1.378s-1.353 0.619-1.353 1.378z" />
      <path d="M24.572 17.392c0 0.763 0.603 1.381 1.353 1.381 0.744 0 1.35-0.619 1.35-1.381 0-0.759-0.606-1.375-1.35-1.375-0.75 0-1.353 0.616-1.353 1.375z" />
      <path d="M23.54 19.317c0 0.759 0.606 1.378 1.353 1.378 0.744 0 1.35-0.619 1.35-1.378 0-0.763-0.606-1.378-1.35-1.378-0.747 0-1.353 0.616-1.353 1.378z" />
      <path d="M23.818 20.542c0 0.759 0.603 1.378 1.35 1.378s1.353-0.619 1.353-1.378c0-0.763-0.606-1.378-1.353-1.378s-1.35 0.616-1.35 1.378z" />
      <path d="M22.465 20.736c0 0.763 0.606 1.378 1.353 1.378s1.35-0.616 1.35-1.378c0-0.759-0.603-1.378-1.35-1.378s-1.353 0.619-1.353 1.378z" />
      <path d="M21.018 22.492c0 0.759 0.603 1.378 1.35 1.378s1.353-0.619 1.353-1.378c0-0.763-0.606-1.378-1.353-1.378s-1.35 0.616-1.35 1.378z" />
      <path d="M17.097 25.452c0 0.612 0.488 1.112 1.088 1.112 0.603 0 1.091-0.5 1.091-1.112s-0.488-1.109-1.091-1.109c-0.6 0-1.088 0.497-1.088 1.109z" />
      <path d="M19.747 23.87c0 0.759 0.606 1.378 1.353 1.378s1.35-0.619 1.35-1.378c0-0.763-0.603-1.378-1.35-1.378s-1.353 0.616-1.353 1.378z" />
      <path d="M18.772 24.592c0 0.522 0.416 0.947 0.928 0.947s0.928-0.425 0.928-0.947-0.416-0.947-0.928-0.947-0.928 0.425-0.928 0.947z" />
      <path d="M16.45 26.564c0 0.403 0.322 0.731 0.721 0.731 0.397 0 0.719-0.328 0.719-0.731 0-0.406-0.322-0.734-0.719-0.734-0.399 0-0.721 0.328-0.721 0.734z" />
      <path d="M15.663 27.102c0 0.297 0.237 0.537 0.528 0.537s0.528-0.241 0.528-0.537-0.237-0.537-0.528-0.537-0.528 0.24-0.528 0.537z" />
      <path d="M23.203 23.539c0 0.763 0.606 1.378 1.353 1.378s1.35-0.616 1.35-1.378c0-0.759-0.603-1.378-1.35-1.378s-1.353 0.619-1.353 1.378z" />
      <path d="M24 6.836c0 0.763 0.603 1.378 1.353 1.378 0.744 0 1.35-0.616 1.35-1.378 0-0.759-0.606-1.378-1.35-1.378-0.75-0-1.353 0.619-1.353 1.378z" />
      <path d="M15.819 9.077c0 0.409 0.325 0.737 0.725 0.737s0.724-0.328 0.724-0.737-0.325-0.737-0.724-0.737c-0.4-0-0.725 0.328-0.725 0.737z" />
      <path d="M15.578 10.133c0 0.244 0.194 0.444 0.437 0.444 0.241 0 0.434-0.2 0.434-0.444 0-0.247-0.194-0.447-0.434-0.447-0.244 0-0.437 0.2-0.437 0.447z" />
      <path d="M15.228 10.911c0 0.247 0.194 0.447 0.434 0.447 0.244 0 0.437-0.2 0.437-0.447 0-0.244-0.194-0.444-0.437-0.444-0.241 0-0.434 0.2-0.434 0.444z" />
      <path d="M16.1 10.911c0 0.247-0.194 0.447-0.437 0.447-0.241 0-0.434-0.2-0.434-0.447 0-0.244 0.194-0.444 0.434-0.444 0.244 0 0.437 0.2 0.437 0.444z" />
      <path d="M13.638 5.914c0 1.341-1.063 2.425-2.375 2.425s-2.378-1.084-2.378-2.425c0-1.337 1.066-2.422 2.378-2.422s2.375 1.084 2.375 2.422z" />
      <path d="M14.885 7.848c0 0.622-0.494 1.125-1.106 1.125-0.609 0-1.106-0.503-1.106-1.125 0-0.625 0.497-1.128 1.106-1.128 0.612 0 1.106 0.503 1.106 1.128z" />
      <path d="M9.703 7.595c0 0.763-0.606 1.378-1.353 1.378-0.744 0-1.35-0.616-1.35-1.378 0-0.759 0.606-1.375 1.35-1.375 0.747 0 1.353 0.616 1.353 1.375z" />
      <path d="M7.475 9.036c0 0.763-0.606 1.378-1.353 1.378s-1.35-0.616-1.35-1.378c0-0.759 0.603-1.375 1.35-1.375s1.353 0.616 1.353 1.375z" />
      <path d="M6.303 10.877c0 0.759-0.603 1.378-1.35 1.378s-1.35-0.619-1.35-1.378c0-0.763 0.603-1.378 1.35-1.378s1.35 0.616 1.35 1.378z" />
      <path d="M6.060 13.327c0 0.763-0.603 1.378-1.353 1.378-0.744 0-1.35-0.616-1.35-1.378 0-0.759 0.606-1.378 1.35-1.378 0.75 0 1.353 0.619 1.353 1.378z" />
      <path d="M4.303 11.43c0 0.759-0.606 1.378-1.353 1.378s-1.35-0.619-1.35-1.378c0-0.763 0.603-1.378 1.35-1.378s1.353 0.616 1.353 1.378z" />
      <path d="M5.985 15.555c0 0.763-0.606 1.378-1.353 1.378s-1.35-0.616-1.35-1.378c0-0.759 0.603-1.378 1.35-1.378s1.353 0.619 1.353 1.378z" />
      <path d="M6.763 17.392c0 0.763-0.603 1.381-1.35 1.381s-1.353-0.619-1.353-1.381c0-0.759 0.606-1.375 1.353-1.375s1.35 0.616 1.35 1.375z" />
      <path d="M7.794 19.317c0 0.759-0.606 1.378-1.35 1.378-0.747 0-1.353-0.619-1.353-1.378 0-0.763 0.606-1.378 1.353-1.378 0.744 0 1.35 0.616 1.35 1.378z" />
      <path d="M9.175 18.883c0 0.763-0.603 1.378-1.35 1.378s-1.353-0.616-1.353-1.378c0-0.759 0.606-1.378 1.353-1.378s1.35 0.619 1.35 1.378z" />
      <path d="M8.869 20.736c0 0.763-0.606 1.378-1.35 1.378-0.747 0-1.353-0.616-1.353-1.378 0-0.759 0.606-1.378 1.353-1.378 0.744-0 1.35 0.619 1.35 1.378z" />
      <path d="M10.316 22.492c0 0.759-0.603 1.378-1.35 1.378s-1.35-0.619-1.35-1.378c0-0.763 0.603-1.378 1.35-1.378s1.35 0.616 1.35 1.378z" />
      <path d="M14.457 25.539c0 0.759-0.606 1.378-1.353 1.378s-1.353-0.619-1.353-1.378c0-0.763 0.606-1.378 1.353-1.378s1.353 0.616 1.353 1.378z" />
      <path d="M11.588 23.87c0 0.759-0.606 1.378-1.35 1.378-0.747 0-1.353-0.619-1.353-1.378 0-0.763 0.606-1.378 1.353-1.378 0.744 0 1.35 0.616 1.35 1.378z" />
      <path d="M12.563 24.592c0 0.522-0.412 0.947-0.928 0.947-0.512 0-0.928-0.425-0.928-0.947s0.416-0.947 0.928-0.947c0.516 0 0.928 0.425 0.928 0.947z" />
      <path d="M14.885 26.564c0 0.403-0.322 0.731-0.719 0.731s-0.722-0.328-0.722-0.731c0-0.406 0.325-0.734 0.722-0.734s0.719 0.328 0.719 0.734z" />
      <path d="M15.672 27.102c0 0.297-0.237 0.537-0.528 0.537-0.294 0-0.528-0.241-0.528-0.537s0.234-0.537 0.528-0.537c0.291-0 0.528 0.24 0.528 0.537z" />
      <path d="M4.816 18.564c0 0.759-0.606 1.378-1.353 1.378s-1.353-0.619-1.353-1.378 0.606-1.378 1.353-1.378 1.353 0.619 1.353 1.378z" />
      <path d="M7.335 6.836c0 0.763-0.603 1.378-1.35 1.378s-1.353-0.616-1.353-1.378c0-0.759 0.606-1.378 1.353-1.378s1.35 0.619 1.35 1.378z" />
      <path d="M15.516 9.077c0 0.409-0.325 0.737-0.725 0.737s-0.725-0.328-0.725-0.737 0.325-0.737 0.725-0.737 0.725 0.328 0.725 0.737z" />
      <path d="M15.757 10.133c0 0.244-0.194 0.444-0.434 0.444-0.244 0-0.437-0.2-0.437-0.444 0-0.247 0.194-0.447 0.437-0.447 0.241 0 0.434 0.2 0.434 0.447z" />
      <path d="M16.11 10.911c0 0.247-0.197 0.447-0.437 0.447s-0.437-0.2-0.437-0.447c0-0.244 0.197-0.444 0.437-0.444s0.437 0.2 0.437 0.444z" />
      <path d="M15.194 10.92c0 0.269 0.212 0.488 0.478 0.488s0.478-0.219 0.478-0.488-0.212-0.488-0.478-0.488-0.478 0.219-0.478 0.488z" />
      <path d="M15.107 27.93c0 0.319 0.253 0.578 0.566 0.578s0.566-0.259 0.566-0.578-0.253-0.575-0.566-0.575-0.566 0.256-0.566 0.575z" />
      <path d="M15.194 18.655c0 0.269 0.212 0.488 0.478 0.488s0.478-0.219 0.478-0.488-0.212-0.488-0.478-0.488-0.478 0.219-0.478 0.488z" />
    </svg>
  );
}

IconPulse.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
