// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconCommunity({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M30.4 14.571c0-3.137-3.363-5.681-7.51-5.681s-7.51 2.544-7.51 5.681c0 1.724 1.015 3.267 2.617 4.31-0.066 0.561-0.276 1.892-0.876 2.962 0.851-0.082 3.209-1.337 3.973-1.755 0.576 0.107 1.176 0.164 1.795 0.164 4.148 0 7.511-2.543 7.511-5.681z" />
    </svg>
  );
}

IconCommunity.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
