import styles from "./HoursList.module.css";
import React from "react";

export function HoursList({ value, startHour, onSelect }) {
  const hours = [];
  let hourIndex = 0;

  while (hourIndex < 12) {
    let hour = startHour + hourIndex;

    if (hour > 24) {
      hour = hour - 24;
    }

    hours.push(hour);
    hourIndex++;
  }

  return (
    <ol>
      {hours.map((h) => {
        const displayHour = h > 12 ? h - 12 : h;
        const displayLabel = h >= 12 && h < 24 ? "PM" : "AM";
        const isSelectedHour = value[0] === h;

        return (
          <li
            className={`${styles.row} ${isSelectedHour ? styles.selected : ""}`}
            key={h}
          >
            <a
              className={styles.rowHour}
              onClick={() => {
                onSelect([h, 0]);
              }}
            >
              <span className={styles.rowHourNumber}>{displayHour}</span>
              <span
                className={`${styles.rowHourLabel} ${
                  isSelectedHour && value[1] === 0 ? styles.selected : ""
                }`}
              >
                {displayLabel}
              </span>
            </a>

            <a
              className={`${styles.rowMinute} ${
                isSelectedHour && value[1] === 15 ? styles.selected : ""
              }`}
              onClick={() => {
                onSelect([h, 15]);
              }}
            >
              :<span>15</span>
            </a>

            <a
              className={`${styles.rowMinute} ${
                isSelectedHour && value[1] === 30 ? styles.selected : ""
              }`}
              onClick={() => {
                onSelect([h, 30]);
              }}
            >
              :<span>30</span>
            </a>

            <a
              className={`${styles.rowMinute} ${
                isSelectedHour && value[1] === 45 ? styles.selected : ""
              }`}
              onClick={() => {
                onSelect([h, 45]);
              }}
            >
              :<span>45</span>
            </a>
          </li>
        );
      })}
    </ol>
  );
}
