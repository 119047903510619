import styles from "./SectionedList.module.css";
import React from "react";
import { Item } from "./Item/Item";
import { Group } from "./Group/Group";

/**
 * A styled list with sections, items and many alignment features.
 * Based loosely on the iOS sectioned table view.
 */
export const SectionedList = (props) => {
  return <div className={styles.sectionedList}>{props.children}</div>;
};

SectionedList.Group = Group;
SectionedList.Item = Item;
