// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconPlay({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.077 2.596c7.452 0 13.514 6.062 13.514 13.514s-6.062 13.513-13.514 13.513-13.514-6.062-13.514-13.513c0-7.452 6.062-13.514 13.514-13.514zM16.077 1.71c-7.953 0-14.4 6.447-14.4 14.4s6.447 14.4 14.4 14.4c7.954 0 14.4-6.446 14.4-14.4s-6.446-14.4-14.4-14.4v0z" />
      <path d="M12.216 10.571l9.494 5.538-9.494 5.538z" />
    </svg>
  );
}

IconPlay.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
