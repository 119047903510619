// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconMajorFunction({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26.24 13.56l-6.178 1.714h0.062v14.534h-3.027v-13.715l-3.537 0.973c-0.062 0.015-0.124 0.031-0.17 0.031-0.293 0-0.541-0.185-0.633-0.479-0.093-0.34 0.108-0.71 0.448-0.803l9.082-2.518c-0.386-0.819-0.896-1.56-1.514-2.209-0.834-0.88-1.853-1.591-2.996-2.070-0.17 0.154-0.386 0.232-0.618 0.232-0.108 0-0.216-0.015-0.324-0.062l-1.915-0.726c-0.479-0.185-0.71-0.726-0.541-1.205 0.139-0.371 0.494-0.602 0.865-0.602 0.108 0 0.216 0.015 0.324 0.062l1.915 0.726c0.324 0.124 0.541 0.417 0.587 0.741 1.251 0.525 2.394 1.313 3.321 2.286 0.71 0.757 1.297 1.622 1.73 2.579l2.749-0.757c0.34-0.093 0.695 0.108 0.803 0.448 0.124 0.355-0.077 0.71-0.432 0.819z" />
      <path d="M11.814 4.725c0 1.45-1.176 2.626-2.626 2.626s-2.626-1.176-2.626-2.626c0-1.45 1.176-2.626 2.626-2.626s2.626 1.176 2.626 2.626z" />
      <path d="M18.765 12.633c-0.031 0.154-0.17 0.355-0.664 0.494-1.143 0.293-2.023-0.015-2.703-0.185-2.502-0.602-3.12-0.695-3.444-0.541-0.062 0.031-0.124 0.077-0.124 0.077-0.324 0.247-0.417 0.618-0.448 0.819-0.17 0.772-0.201 1.344-0.201 1.344-0.015 0.185-0.015 0.185-0.046 0.402-0.031 0.232-0.108 0.911-0.216 1.683-0.077 0.541-0.124 0.819-0.201 1.112-0.031 0.108-0.077 0.263-0.124 0.402-0.185 2.857-0.726 7.738-0.911 10.611 0 0.587-0.479 1.066-1.066 1.066h-0.71c-0.587 0-1.066-0.479-1.066-1.066-0.17-2.657-0.587-7.043-0.788-9.946-0.108-0.062-0.185-0.139-0.278-0.216-0.34-0.355-0.386-0.88-0.494-1.884-0.093-0.911 0.015-1.591 0.062-1.946 0.556-3.799 0-4.479 0.88-5.483 0.077-0.093 0.958-1.066 2.224-1.112 1.158-0.046 2.239 0.757 2.703 1.112 0.463 0.34 0.51 0.494 1.019 0.803 0.602 0.355 1.097 0.525 1.776 0.726 0.741 0.232 1.421 0.355 2.147 0.494 0.278 0.046 0.541 0.108 0.819 0.17 0.88 0.17 1.112 0.216 1.421 0.402 0.17 0.062 0.479 0.371 0.432 0.664z" />
    </svg>
  );
}

IconMajorFunction.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
