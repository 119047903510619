// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconBasic({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.363 16.723h-1.534v5.543c0.381 0.052 0.821 0.082 1.268 0.082 0.129 0 0.257-0.003 0.385-0.007l-0.018 0.001c1.985 0 3.779-0.731 3.779-2.842-0.008-1.973-1.743-2.776-3.88-2.776z" />
      <path d="M16.003 1c-0.001 0-0.002 0-0.003 0-8.284 0-15 6.716-15 15s6.716 15 15 15c8.284 0 15-6.716 15-15v0c0-8.283-6.714-14.999-14.997-15h-0zM20.87 23.084c-1.207 1.058-3.195 1.585-6.313 1.585-0.030 0-0.065 0-0.1 0-1.303 0-2.587-0.082-3.847-0.242l0.15 0.015v-16.648c1.316-0.241 2.831-0.378 4.379-0.378 0.055 0 0.11 0 0.165 0.001l-0.008-0c2.267 0 3.696 0.302 4.827 1.083 1.064 0.634 1.765 1.778 1.765 3.086 0 0.037-0.001 0.075-0.002 0.112l0-0.005c0 1.559-0.98 2.968-2.817 3.648v0.076c1.786 0.451 3.421 1.859 3.421 4.174 0 0.017 0 0.038 0 0.058 0 1.38-0.628 2.613-1.613 3.43l-0.007 0.006z" />
      <path d="M18.756 12.045c0-1.61-1.207-2.338-3.167-2.338-0.085-0.003-0.186-0.005-0.287-0.005-0.522 0-1.033 0.048-1.528 0.139l0.052-0.008v4.651h1.587c2.167 0 3.343-1.005 3.343-2.439z" />
    </svg>
  );
}

IconBasic.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
