// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconUSFlagWhite({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M27.942 7.95h-11.942v-3.176h9.019c0.138 0.11 0.272 0.222 0.405 0.338 0.086 0.075 0.173 0.152 0.259 0.229 0.17 0.155 0.336 0.314 0.499 0.477 0.051 0.051 0.101 0.102 0.15 0.154 0.096 0.096 0.187 0.195 0.278 0.294 0.034 0.035 0.066 0.072 0.098 0.107 0.061 0.067 0.12 0.134 0.179 0.203 0.062 0.072 0.123 0.144 0.184 0.218 0.062 0.072 0.122 0.146 0.179 0.219 0.035 0.045 0.072 0.090 0.107 0.134 0.037 0.046 0.074 0.094 0.109 0.142 0.054 0.070 0.109 0.142 0.162 0.216 0.107 0.146 0.211 0.294 0.314 0.445z" />
      <path d="M30.301 14.301h-14.301v-3.176h13.554c0.008 0.022 0.016 0.045 0.022 0.067 0.032 0.085 0.061 0.171 0.090 0.256 0.048 0.142 0.093 0.286 0.136 0.432 0.010 0.032 0.019 0.066 0.029 0.099 0.019 0.066 0.038 0.131 0.056 0.197 0.014 0.051 0.029 0.102 0.042 0.155 0.021 0.075 0.040 0.152 0.058 0.229 0.018 0.074 0.037 0.147 0.053 0.221 0.054 0.238 0.104 0.478 0.146 0.722 0.010 0.053 0.018 0.106 0.026 0.158 0.019 0.109 0.035 0.219 0.050 0.33 0.014 0.094 0.027 0.189 0.038 0.285 0.002 0.008 0.002 0.018 0.003 0.026z" />
      <path d="M30.326 17.475c-0.013 0.126-0.027 0.253-0.045 0.378-0.003 0.029-0.006 0.059-0.011 0.088-0.011 0.088-0.024 0.176-0.038 0.262-0.008 0.054-0.016 0.107-0.026 0.162-0.018 0.109-0.037 0.218-0.058 0.326-0.005 0.024-0.010 0.048-0.014 0.072-0.021 0.112-0.043 0.222-0.069 0.333-0.022 0.104-0.046 0.208-0.072 0.31l-0.038 0.154c-0.011 0.051-0.026 0.102-0.040 0.154-0.024 0.088-0.048 0.178-0.074 0.266-0.010 0.040-0.022 0.080-0.035 0.12-0.021 0.074-0.045 0.147-0.067 0.221-0.034 0.112-0.070 0.221-0.107 0.331h-27.264c-0.037-0.11-0.074-0.219-0.107-0.331-0.024-0.074-0.046-0.147-0.067-0.221-0.013-0.040-0.026-0.080-0.035-0.12-0.026-0.088-0.050-0.178-0.074-0.266-0.014-0.051-0.029-0.102-0.040-0.154l-0.038-0.154c-0.026-0.102-0.050-0.206-0.072-0.31-0.026-0.11-0.048-0.221-0.069-0.333-0.005-0.024-0.010-0.048-0.014-0.072-0.021-0.109-0.040-0.218-0.058-0.326-0.010-0.054-0.018-0.107-0.026-0.162-0.014-0.086-0.027-0.174-0.038-0.262-0.005-0.029-0.008-0.059-0.011-0.088-0.018-0.125-0.032-0.251-0.045-0.378h28.653z" />
      <path d="M28.090 23.826c-0.061 0.093-0.122 0.186-0.184 0.277-0.029 0.042-0.056 0.083-0.085 0.123-0.069 0.099-0.139 0.197-0.21 0.293-0.032 0.043-0.064 0.088-0.096 0.13-0.070 0.094-0.142 0.187-0.214 0.278-0.050 0.062-0.101 0.125-0.152 0.187-0.035 0.045-0.074 0.090-0.112 0.134-0.048 0.058-0.098 0.115-0.147 0.173-0.054 0.064-0.11 0.128-0.168 0.19-0.075 0.083-0.15 0.166-0.227 0.248s-0.154 0.162-0.232 0.242c-0.090 0.093-0.181 0.182-0.275 0.272-0.069 0.066-0.139 0.131-0.21 0.197-0.067 0.062-0.133 0.123-0.2 0.182-0.094 0.085-0.19 0.168-0.288 0.25h-18.579c-0.098-0.082-0.194-0.165-0.288-0.25-0.067-0.059-0.133-0.12-0.2-0.182-0.070-0.066-0.141-0.131-0.21-0.197-0.094-0.090-0.186-0.179-0.275-0.272-0.078-0.080-0.155-0.16-0.232-0.242s-0.152-0.165-0.227-0.248c-0.058-0.062-0.114-0.126-0.168-0.19-0.050-0.058-0.099-0.115-0.147-0.173-0.038-0.045-0.077-0.090-0.112-0.134-0.051-0.062-0.102-0.125-0.152-0.187-0.072-0.091-0.144-0.184-0.214-0.278-0.032-0.042-0.064-0.086-0.096-0.13-0.070-0.096-0.141-0.194-0.21-0.293-0.029-0.040-0.056-0.082-0.083-0.123-0.064-0.091-0.125-0.184-0.186-0.277h24.179z" />
    </svg>
  );
}

IconUSFlagWhite.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
