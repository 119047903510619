import styles from "./index.module.css";
import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "./NavLink";
import { FunctionComponent } from "react";

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * String to display as the nav button title.
       */
      title: PropTypes.string.isRequired,

      /**
       * Something to render as the nav button icon.
       */
      icon: PropTypes.node.isRequired,

      /**
       * Path to navigate to when the nav item is clicked.
       */
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
}

type DesktopNavProps = PropTypes.InferProps<typeof propTypes>;

/**
 * Navigation items as seen in the header in a desktop layout.
 */
export const DesktopNav: FunctionComponent<DesktopNavProps> = ({ items }) => {
  return (
    <nav className={styles.nav}>
      <ul>
        {items.map((item, i) => {
          return (
            <li key={i}>
              <NavLink {...item} />
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

DesktopNav.propTypes = propTypes;
