// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconBook({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.759 5.341v17.798c0.424 0 0.836 0.007 1.239 0.021 5.596 0.182 9.189 1.57 10.606 2.233 0.455 0.213 0.686 0.352 0.686 0.352s0-10.984 0-15.505c0-4.524-12.53-4.899-12.53-4.899zM3.496 6.109c4.568 0.246 11.057 1.508 11.057 4.131v14.319c-1.733-0.762-5.458-2.043-11.057-2.149v-16.3z" />
      <path d="M15.999 9.834c-0.262-2.063-2.554-3.56-6.823-4.461-3.16-0.665-6.264-0.765-6.395-0.768l-0.758-0.024v19.294h0.736c0.427 0 0.843 0.007 1.249 0.022 7.060 0.24 10.863 2.454 10.901 2.476l1.067 0.641 0.018 0.012 0.031 0.018v-16.805c0-0.138-0.009-0.272-0.025-0.406zM14.553 24.559c-1.733-0.762-5.458-2.043-11.057-2.149v-16.3c4.568 0.246 11.057 1.508 11.057 4.131v14.319z" />
      <path d="M16.711 10.24c0 4.521 0 15.505 0 15.505s0.238-0.143 0.708-0.362c1.433-0.668 5.020-2.042 10.591-2.223 0.399-0.013 0.809-0.021 1.23-0.021v-17.798c0 0-12.528 0.375-12.528 4.899zM28.504 22.409c-5.599 0.106-9.324 1.387-11.057 2.149v-14.319c0-2.623 6.489-3.885 11.057-4.131v16.3z" />
      <path d="M29.218 4.605c-0.129 0.003-3.234 0.103-6.394 0.768-4.269 0.9-6.563 2.398-6.825 4.461-0.016 0.134-0.025 0.268-0.025 0.406v15.61l0.001 1.165v0.022l0.018-0.010 0.031-0.019 1.065-0.633c0.038-0.024 3.846-2.239 10.91-2.477 0.403-0.015 0.817-0.022 1.24-0.022h0.737v-19.294l-0.759 0.024zM28.504 22.409c-5.599 0.106-9.324 1.387-11.057 2.149v-14.319c0-2.623 6.489-3.885 11.057-4.131v16.3z" />
      <path d="M28.938 26.048c0 0-9.85-0.883-11.749 1.37h-2.371c-1.899-2.253-11.749-1.37-11.749-1.37l1.109-2.542-0.457-0.878c0 0 8.442 0.354 10.568 2.484 0.618 0.613 1.119 0.774 1.712 0.736h0.005c0.594 0.038 1.094-0.124 1.712-0.736 2.125-2.13 10.567-2.484 10.567-2.484l-0.456 0.878 1.109 2.542z" />
    </svg>
  );
}

IconBook.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
