// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconLink({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 11 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.327 3.014c-0.932-0.935-2.221-1.514-3.646-1.514-2.843 0-5.148 2.305-5.148 5.148 0 0.003 0 0.005 0 0.008v3.016c0.003 2.055 1.204 3.829 2.943 4.659 0.031-1.039 0.031-2.094 0.031-3.146-0.003-0.063-0.005-0.136-0.005-0.209 0-0.119 0.005-0.237 0.014-0.354-0.154-0.291-0.244-0.652-0.244-1.033v-3.037c0.105-1.247 1.143-2.22 2.409-2.22s2.303 0.972 2.408 2.211l0.001 3.046c0 0.006 0 0.012 0 0.019 0 0.368-0.083 0.716-0.232 1.027 0.011 0.018 0.014 0.055 0.014 0.093 0 0.001 0 0.002 0 0.004v3.607c1.767-0.844 2.967-2.614 2.973-4.665v-3.017c-0.002-1.423-0.582-2.711-1.517-3.641z" />
      <path d="M6.646 9.75c0.255 0.238 0.414 0.576 0.414 0.952 0 0.009-0 0.018-0 0.028q0 4.962 0 9.928c0 0.209 0 0.417 0 0.627 0 0.001 0 0.001 0 0.002 0 0.752-0.61 1.361-1.361 1.361-0.265 0-0.512-0.076-0.721-0.206-0.398-0.23-0.665-0.66-0.665-1.152 0-0.017 0-0.034 0.001-0.051q-0-5.029-0-10.061c0-0.363-0.037-0.727 0.14-1.071 0.223-0.458 0.686-0.768 1.22-0.768 0.381 0 0.726 0.158 0.972 0.412z" />
      <path d="M2.048 28.986c0.932 0.937 2.223 1.517 3.649 1.517 2.843 0 5.148-2.305 5.148-5.148 0-0.004 0-0.007 0-0.011v-3.015c-0.003-2.055-1.204-3.828-2.943-4.659-0.031 1.039-0.031 2.094-0.031 3.146 0.003 0.063 0.005 0.136 0.005 0.209 0 0.119-0.005 0.237-0.014 0.354 0.153 0.29 0.242 0.65 0.243 1.030v3.037c-0.105 1.247-1.143 2.22-2.409 2.22s-2.304-0.972-2.408-2.211l-0.001-3.043c0-0.002 0-0.004 0-0.007 0-0.373 0.086-0.726 0.238-1.040-0.011-0.018-0.014-0.056-0.014-0.094 0-0.001 0-0.002 0-0.003v-3.606c-1.769 0.843-2.971 2.613-2.977 4.665v3.017c0.001 1.423 0.58 2.711 1.514 3.641z" />
    </svg>
  );
}

IconLink.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
