// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconTraining({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.653 16c0-1.847 1.497-3.347 3.344-3.347 1.849 0 3.346 1.5 3.346 3.347 0 1.85-1.497 3.348-3.346 3.348-1.847 0-3.344-1.499-3.344-3.348zM15.941 18.211c1.218 0 2.209-0.992 2.209-2.211s-0.991-2.208-2.209-2.208c-1.222 0-2.206 0.989-2.206 2.208s0.984 2.211 2.206 2.211z" />
      <path d="M15.878 1.6c8.143 0 14.521 6.437 14.521 14.4 0 8.634-7.050 14.4-14.403 14.4-8.603 0-14.397-7.047-14.397-14.4 0-7.963 6.469-14.4 14.278-14.4zM15.997 29.38c7.393 0 13.377-5.991 13.377-13.38 0-7.386-5.984-13.378-13.377-13.378-7.391 0-13.375 5.992-13.375 13.378 0 7.389 5.984 13.38 13.375 13.38z" />
      <path d="M15.997 3.733c-6.772 0-12.266 5.494-12.266 12.267 0 6.778 5.494 12.267 12.266 12.267 6.777 0 12.268-5.489 12.268-12.267 0-6.773-5.491-12.267-12.268-12.267zM11.15 25.291c-3.547-0.969-4.628-4.623-4.628-4.623l4.769-2.847c0.431 2.155 2.444 2.698 2.444 2.698l-2.584 4.772zM15.997 20.166c-2.297 0-4.159-1.867-4.159-4.166s1.863-4.164 4.159-4.164c2.303 0 4.165 1.866 4.165 4.164s-1.862 4.166-4.165 4.166zM20.703 13.777c-0.431-2.152-2.506-2.508-2.506-2.508l2.647-4.963c3.547 0.972 4.628 4.627 4.628 4.627l-4.769 2.844z" />
    </svg>
  );
}

IconTraining.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
