// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconPremier({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.612 9.808c-0.052-0.001-0.114-0.002-0.176-0.002-0.569 0-1.124 0.056-1.662 0.162l0.054-0.009v5.684c0.379 0.098 0.815 0.155 1.263 0.155 0.087 0 0.174-0.002 0.26-0.006l-0.012 0.001c2.313 0 3.721-1.156 3.721-3.116-0.003-1.862-1.31-2.867-3.449-2.867z" />
      <path d="M16.003 1c-0.001 0-0.002 0-0.003 0-8.284 0-15 6.716-15 15s6.716 15 15 15c8.284 0 15-6.716 15-15v0c0-8.283-6.714-14.999-14.997-15h-0zM20.693 16.368c-1.26 1.232-3.169 1.837-5.356 1.837-0.059 0.002-0.129 0.003-0.2 0.003-0.464 0-0.917-0.047-1.355-0.136l0.043 0.007v6.412h-3.066v-16.721c1.317-0.226 2.834-0.355 4.38-0.355 0.122 0 0.245 0.001 0.367 0.002l-0.018-0c2.267 0 3.897 0.479 4.978 1.383 1.020 0.883 1.662 2.179 1.662 3.626 0 0.043-0.001 0.086-0.002 0.129l0-0.006c0 1.61-0.504 2.917-1.433 3.822z" />
    </svg>
  );
}

IconPremier.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
