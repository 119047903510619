import { Close as Cross, IkonateContext } from "react-ikonate";
import React from "react";
import styles from "./index.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";
/**
 * A basic HTML checkbox with built in styles.
 */
export function Crossbox({ className, label, ...props }) {
  return (
    <div className={styles.row}>
      <div className={styles.container}>
        <input
          type="checkbox"
          checked={props.selected}
          className={classNames(styles.checkbox, className)}
          {...props}
        />
        <div className={styles.icon}>
          <IkonateContext.Provider value={{ color: "#91b6bb", size: 25 }}>
            <Cross />
          </IkonateContext.Provider>
        </div>
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  );
}
Crossbox.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};
