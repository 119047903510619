// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconContact({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26.589 1.6h-20.244c-0.777 0-1.411 0.636-1.411 1.411v1.035h0.683c0.172 0 0.312 0.141 0.312 0.312v0.103c0 0.172-0.141 0.312-0.312 0.312h-0.683v1.583h0.683c0.172 0 0.312 0.141 0.312 0.312v0.103c0 0.172-0.141 0.312-0.312 0.312h-0.683v1.581h0.683c0.172 0 0.312 0.141 0.312 0.312v0.103c0 0.172-0.141 0.312-0.312 0.312h-0.683v1.583h0.683c0.172 0 0.312 0.141 0.312 0.312v0.103c0 0.172-0.141 0.312-0.312 0.312h-0.683v1.583h0.683c0.172 0 0.312 0.141 0.312 0.312v0.103c0 0.172-0.141 0.312-0.312 0.312h-0.683v1.583h0.683c0.172 0 0.312 0.141 0.312 0.312v0.103c0 0.172-0.141 0.312-0.312 0.312h-0.683v1.583h0.683c0.172 0 0.312 0.141 0.312 0.312v0.103c0 0.172-0.141 0.312-0.312 0.312h-0.683v1.583h0.683c0.172 0 0.312 0.141 0.312 0.312v0.103c0 0.172-0.141 0.312-0.312 0.312h-0.683v1.583h0.683c0.172 0 0.312 0.141 0.312 0.312v0.103c0 0.172-0.141 0.312-0.312 0.312h-0.683v1.583h0.683c0.172 0 0.312 0.141 0.312 0.312v0.103c0 0.172-0.141 0.312-0.312 0.312h-0.683v1.583h0.683c0.172 0 0.312 0.141 0.312 0.312v0.103c0 0.172-0.141 0.312-0.312 0.312h-0.683v1.103c0 0.777 0.635 1.411 1.411 1.411h20.244c0.777 0 1.411-0.634 1.411-1.411v-25.976c-0-0.775-0.635-1.411-1.411-1.411zM22.134 20.256c-1.905 0.006-3.81 0.002-5.715 0.002-1.882 0-3.765 0.003-5.646-0.002-0.77-0.002-0.8-0.056-0.677-0.819 0.186-1.133 0.808-1.925 1.852-2.38 0.555-0.241 1.12-0.458 1.692-0.655 0.495-0.172 0.811-0.542 1.136-0.914 0.094-0.105 0.188-0.252 0.106-0.363-0.377-0.506-0.177-1.222-0.697-1.705-0.324-0.303-0.325-0.833-0.244-1.278 0.103-0.563 0.081-1.113 0.008-1.671-0.186-1.446 0.669-2.483 2.135-2.599 0.18-0.014 0.356-0.042 0.535-0.052 0.412-0.022 0.844-0.388 1.225-0.077 0.544 0.442 1.036 1 1.080 1.725 0.072 1.18 0.084 2.369 0.019 3.549-0.031 0.555-0.558 0.963-0.656 1.569-0.131 0.822 0.044 1.367 0.841 1.713 0.636 0.275 1.299 0.492 1.935 0.77 1.024 0.445 1.632 1.233 1.8 2.34 0.106 0.692-0.025 0.842-0.728 0.845z" />
      <path d="M4.934 4.046v0.728h-0.622c-0.172 0-0.312-0.141-0.312-0.312v-0.103c0-0.172 0.141-0.312 0.312-0.312h0.622z" />
      <path d="M4.934 6.357v0.728h-0.622c-0.172 0-0.312-0.141-0.312-0.312v-0.103c0-0.172 0.141-0.312 0.312-0.312h0.622z" />
      <path d="M4.934 8.667v0.728h-0.622c-0.172 0-0.312-0.141-0.312-0.312v-0.103c0-0.172 0.141-0.312 0.312-0.312h0.622z" />
      <path d="M4.934 10.978v0.728h-0.622c-0.172 0-0.312-0.141-0.312-0.312v-0.103c0-0.172 0.141-0.312 0.312-0.312h0.622z" />
      <path d="M4.934 13.289v0.728h-0.622c-0.172 0-0.312-0.141-0.312-0.312v-0.103c0-0.172 0.141-0.312 0.312-0.312h0.622z" />
      <path d="M4.934 15.601v0.728h-0.622c-0.172 0-0.312-0.141-0.312-0.312v-0.103c0-0.172 0.141-0.312 0.312-0.312h0.622z" />
      <path d="M4.934 17.912v0.728h-0.622c-0.172 0-0.312-0.141-0.312-0.312v-0.103c0-0.172 0.141-0.312 0.312-0.312h0.622z" />
      <path d="M4.934 20.223v0.728h-0.622c-0.172 0-0.312-0.141-0.312-0.312v-0.103c0-0.172 0.141-0.312 0.312-0.312h0.622z" />
      <path d="M4.934 22.535v0.728h-0.622c-0.172 0-0.312-0.141-0.312-0.312v-0.103c0-0.172 0.141-0.312 0.312-0.312h0.622z" />
      <path d="M4.934 24.846v0.728h-0.622c-0.172 0-0.312-0.141-0.312-0.312v-0.103c0-0.172 0.141-0.312 0.312-0.312h0.622z" />
      <path d="M4.934 27.157v0.728h-0.622c-0.172 0-0.312-0.141-0.312-0.312v-0.103c0-0.172 0.141-0.312 0.312-0.312h0.622z" />
    </svg>
  );
}

IconContact.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
