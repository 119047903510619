// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconDigitalInfoSystem({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M32.089 11.752l-6.354-7.062v3.979h-0.449c-0.543-0.049-1.175-0.077-1.813-0.077-2.356 0-4.624 0.379-6.745 1.081l0.152-0.043c-0.32 0.129-0.577 0.257-0.834 0.386l-0.772 0.386c-1.872 1.024-3.379 2.511-4.4 4.309l-0.029 0.055c-0.496 0.903-0.913 1.949-1.199 3.049l-0.021 0.096c0.805-0.659 1.709-1.256 2.672-1.756l0.087-0.041c1.678-0.866 3.636-1.459 5.709-1.663l0.067-0.005c0.194-0.043 0.417-0.067 0.645-0.067 0.045 0 0.089 0.001 0.134 0.003l-0.006-0h1.091c0.044-0 0.096-0.001 0.148-0.001 1.843 0 3.626 0.258 5.315 0.741l-0.136-0.033 0.321 0.064v3.658z" />
      <path d="M18.419 15.217c-2.138 0.189-4.099 0.785-5.86 1.71l0.085-0.041v0.192c0 1.382-1.121 2.503-2.503 2.503s-2.503-1.121-2.503-2.503v0c0.024-1.307 1.023-2.373 2.299-2.502l0.011-0.001c1.022-2.044 2.584-3.689 4.501-4.784l0.055-0.029c-1.243-0.765-2.749-1.218-4.361-1.218-0.001 0-0.002 0-0.004 0h0c-4.681 0.050-8.458 3.849-8.471 8.534v0.001c0 4.678 3.793 8.471 8.471 8.471s8.471-3.793 8.471-8.471v0c0-0.011 0-0.024 0-0.038 0-0.646-0.070-1.275-0.203-1.881l0.011 0.058zM6.803 15.281l-3.402-1.86c0.526-1.551 1.724-2.749 3.238-3.265l0.036-0.011 1.99 3.401c-0.899 0.196-1.605 0.86-1.857 1.719l-0.004 0.017zM13.609 23.56l-1.99-3.401c0.902-0.197 1.6-0.895 1.794-1.782l0.003-0.015 3.531 1.861c-0.546 1.575-1.764 2.793-3.302 3.326l-0.037 0.011z" />
      <path d="M20.023 15.088h-0.834c0.122 0.578 0.192 1.242 0.192 1.922v0c-0.014 5.078-4.134 9.188-9.214 9.188-5.089 0-9.214-4.125-9.214-9.214s4.125-9.214 9.214-9.214c1.904 0 3.672 0.577 5.141 1.566l-0.033-0.021c0.257-0.129 0.577-0.257 0.898-0.386-1.632-1.242-3.699-1.99-5.941-1.99-0.033 0-0.065 0-0.098 0l0.005-0c-0.010-0-0.021-0-0.032-0-5.571 0-10.089 4.507-10.108 10.073v0.002c-0 0.010-0 0.021-0 0.032 0 5.571 4.506 10.089 10.073 10.108h0.002c0.009 0 0.020 0 0.031 0 5.571 0 10.090-4.507 10.108-10.074v-0.002c0-0.016 0-0.036 0-0.055 0-0.685-0.070-1.354-0.203-2l0.011 0.064z" />
    </svg>
  );
}

IconDigitalInfoSystem.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
