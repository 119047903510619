// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconDownload({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 2.486c7.452 0 13.514 6.063 13.514 13.514s-6.062 13.513-13.514 13.513-13.514-6.062-13.514-13.513c0-7.452 6.062-13.514 13.514-13.514zM16 1.6c-7.953 0-14.4 6.446-14.4 14.4s6.447 14.4 14.4 14.4c7.954 0 14.4-6.446 14.4-14.4s-6.446-14.4-14.4-14.4v0z" />
      <path d="M21.33 17.029c-0.355 0-0.649-0.28-0.664-0.638-0.015-0.367 0.27-0.676 0.637-0.691 1.767-0.072 2.416-1.219 2.436-2.18 0.019-0.944-0.543-2.007-1.82-2.111l-0.468-0.038-0.229-0.857c-0.746-1.887-2.468-2.513-3.841-2.472-1.264 0.040-2.392 0.612-3.094 1.571l-0.288 0.393-0.803-0.274c-0.605-0.253-1.317-0.247-1.903 0.017-0.361 0.163-0.842 0.503-1.077 1.196l-0.107 0.316-0.318 0.103c-1.33 0.43-1.64 1.613-1.501 2.496 0.144 0.916 0.812 1.839 1.978 1.839 0.367 0 0.665 0.298 0.665 0.665s-0.298 0.665-0.665 0.665c-1.663 0-3.017-1.218-3.292-2.962-0.225-1.432 0.347-3.134 2.101-3.856 0.335-0.747 0.918-1.335 1.669-1.673 0.859-0.387 1.886-0.422 2.785-0.1 0.948-1.054 2.309-1.676 3.808-1.723 2.299-0.072 4.319 1.244 5.135 3.353l0.028 0.091c1.368 0.315 2.603 1.498 2.565 3.388-0.033 1.66-1.194 3.38-3.711 3.482-0.009 0-0.018 0.001-0.027 0.001z" />
      <path d="M15.868 25.564l-5.29-5.29c-0.26-0.26-0.26-0.68 0-0.94s0.68-0.259 0.94 0l4.35 4.35 4.236-4.236c0.26-0.259 0.68-0.259 0.94 0s0.26 0.68 0 0.94l-5.176 5.176z" />
      <path d="M15.868 25.289c-0.367 0-0.665-0.298-0.665-0.665v-12.311c0-0.367 0.298-0.665 0.665-0.665s0.665 0.298 0.665 0.665v12.311c0 0.367-0.297 0.665-0.665 0.665z" />
    </svg>
  );
}

IconDownload.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
