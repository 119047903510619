// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconMerchandisingEvent({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.037 7.714c0.005 0 0.011 0 0.018 0 1.689 0 3.058-1.369 3.058-3.058s-1.369-3.058-3.058-3.058c-1.689 0-3.058 1.369-3.058 3.058 0 0.001 0 0.001 0 0.002v-0c0.010 1.678 1.363 3.037 3.038 3.056l0.002 0z" />
      <path d="M22.797 19.218h1.158c0.799-0.003 1.445-0.651 1.445-1.45 0-0.043-0.002-0.085-0.006-0.127l0 0.005c-0.16-2.032-1.506-3.712-3.725-4.715v-0.16c0-0.001 0-0.002 0-0.003 0-0.797-0.644-1.444-1.439-1.45h-2.743v-0.466c0 0 0-0 0-0 0-1.029-0.831-1.864-1.859-1.87h-1.738l-1.854 2.366-1.856-2.366h-1.736c-1.029 0.006-1.86 0.841-1.861 1.87v6.907c0.001 1.029 0.832 1.864 1.86 1.87h0.001v8.909c-0 0.004-0 0.008-0 0.012 0 1.025 0.831 1.856 1.856 1.856 0.789 0 1.463-0.492 1.732-1.187l0.004-0.013c0.273 0.7 0.941 1.188 1.723 1.192h6.46c0.796-0.005 1.44-0.652 1.44-1.45 0-0.001 0-0.001 0-0.002v0-0.13c2.363-1.075 3.744-2.93 3.744-5.107 0.013-1.803-1.005-3.416-2.606-4.494zM20.226 28.949h-4.277v-1.12c-1.878-0.614-3.571-1.882-3.733-3.84h4.341c0.16 0.563 0.786 0.986 1.531 0.986 0.862 0 1.562-0.566 1.562-1.261s-0.699-1.259-1.562-1.259c-3.248 0-5.885-1.981-5.885-4.414 0-2.040 1.717-3.448 3.746-4.112v-1.16h4.277v1.16c1.883 0.614 3.57 1.877 3.73 3.84h-4.341c-0.16-0.565-0.782-0.987-1.526-0.987-0.867 0-1.57 0.568-1.57 1.259s0.702 1.256 1.57 1.256c3.245 0 5.883 1.984 5.883 4.418 0 2.045-1.72 3.45-3.746 4.114z" />
    </svg>
  );
}

IconMerchandisingEvent.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
