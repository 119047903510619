// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconFavoriteOn({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.969 2.489c7.452 0 13.514 6.062 13.514 13.514s-6.062 13.514-13.514 13.514-13.514-6.062-13.514-13.514 6.062-13.514 13.514-13.514zM15.969 1.603c-7.954 0-14.4 6.447-14.4 14.4s6.446 14.4 14.4 14.4c7.954 0 14.4-6.447 14.4-14.4s-6.446-14.4-14.4-14.4v0z" />
      <path d="M26.842 12.845h-7.962l-2.898-7.255-2.918 7.255h-7.968l6.18 5.224-2.026 7.46 6.732-4.158 6.712 4.158-2.032-7.46 6.18-5.224zM18.579 16.99h-5.22v-1.423h5.22v1.423z" />
    </svg>
  );
}

IconFavoriteOn.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
