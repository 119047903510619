// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconInfoSystem({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26.455 7.999h-4.5v-2.5c-0.001-0.828-0.672-1.499-1.499-1.5h-9.002c-0.828 0.001-1.499 0.672-1.5 1.499v2.5h-4.5c-1.93 0.008-3.492 1.57-3.5 3.499v13.001c0.009 1.93 1.571 3.491 3.499 3.5h21.002c1.93-0.008 3.492-1.57 3.5-3.499v-13.001c-0.007-1.93-1.569-3.492-3.498-3.5zM19.954 7.999h-8v-2h8v2z" />
    </svg>
  );
}

IconInfoSystem.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
