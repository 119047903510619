// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconDreamNight({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.181 10.36c-0.676 0.013-1.324 0.11-1.941 0.28l0.055-0.013v10.608c0.582 0.146 1.256 0.239 1.947 0.257l0.013 0c0.037 0.001 0.081 0.002 0.125 0.002 1.349 0 2.565-0.574 3.415-1.491l0.003-0.003q1.403-1.506 1.403-4.074t-1.403-4.075q-1.408-1.49-3.616-1.491z" />
      <path d="M16 1.6c-7.953 0-14.4 6.447-14.4 14.4s6.447 14.4 14.4 14.4c7.953 0 14.4-6.447 14.4-14.4v0c0-7.953-6.447-14.4-14.4-14.4v0zM26.824 10.002q-0.8 0-1.12 0.48-0.202 0.294-0.202 1.344v10.514h-0.334l-8.224-10.046v1.266c0.226 0.68 0.357 1.463 0.357 2.277s-0.131 1.597-0.372 2.329l0.015-0.053v1.862c0 0.766 0.082 1.245 0.248 1.44q0.35 0.386 1.077 0.386h0.442v0.341h-4.342v-0.341h0.432c0.534 0 0.91-0.16 1.131-0.48 0.134-0.195 0.203-0.64 0.203-1.342v-0.298c-0.139 0.191-0.275 0.358-0.42 0.517l0.004-0.005q-1.776 1.95-5.408 1.95h-5.576v-0.341h0.47q0.79 0 1.12-0.506 0.202-0.302 0.202-1.362v-8.066c0-0.778-0.085-1.266-0.258-1.462-0.238-0.269-0.594-0.405-1.066-0.405h-0.469v-0.339h5.078q2.797 0 4.254 0.64c0.847 0.378 1.546 0.957 2.057 1.679l0.010 0.015v-0.698c-0.249-0.31-0.519-0.584-0.815-0.829l-0.009-0.008c-0.237-0.16-0.51-0.29-0.803-0.377l-0.021-0.005c-0.211-0.054-0.453-0.085-0.703-0.085-0.037 0-0.074 0.001-0.11 0.002l0.005-0v-0.334h3.384l7.627 9.355v-7.2q0-1.15-0.258-1.44-0.341-0.387-1.077-0.387h-0.432v-0.328h4.342v0.339z" />
    </svg>
  );
}

IconDreamNight.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
