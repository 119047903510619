// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconEagleTracker({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M28.416 14.677c-0.436 0.331-1.067 0.842-1.864 1.142 1.548-1.203 3.412-3.563 2.886-5.351-2.555 3.968-7.11 7.095-12.101 7.576v0c-0.421-0.225-0.887-0.346-1.338-0.346-0.466 0-0.917 0.12-1.338 0.346-4.991-0.481-9.545-3.608-12.101-7.576-0.526 1.774 1.338 4.149 2.886 5.351-0.812-0.301-1.428-0.797-1.864-1.142 0.586 1.684 1.759 2.51 2.751 2.826-0.992-0.301-1.954-0.706-2.871-1.203 0.391 1.819 3.157 3.006 5.096 3.322-0.887 0.075-1.639-0.015-2.195-0.105 1.323 1.188 2.751 1.353 3.728 1.142-0.511 0.165-1.037 0.286-1.578 0.361 0.842 0.691 2.36 0.331 3.217-0.15-0.316 0.316-0.631 0.511-0.887 0.646 0.962 0.105 1.578-0.271 1.849-0.646 0.21 0.135 0.496 0.256 0.827 0.361-0.887 1.007-2.029 1.864-3.307 2.495 1.233 0.271 2.856-0.722 3.668-1.533-0.195 0.421-0.541 0.752-0.767 0.977 1.157-0.316 1.714-0.932 1.924-1.443-0.195 0.511-0.466 1.022-0.812 1.488 0.752-0.12 1.353-0.706 1.744-1.368 0.391 0.661 0.977 1.233 1.744 1.368-0.331-0.466-0.601-0.977-0.812-1.488 0.21 0.511 0.782 1.127 1.924 1.443-0.225-0.225-0.571-0.556-0.767-0.977 0.812 0.812 2.435 1.789 3.668 1.533-1.293-0.646-2.45-1.518-3.337-2.525 0.301-0.090 0.601-0.195 0.872-0.331 0.256 0.376 0.872 0.752 1.834 0.646-0.241-0.135-0.571-0.331-0.887-0.646 0.857 0.481 2.375 0.827 3.217 0.15-0.541-0.075-1.067-0.195-1.578-0.361 0.992 0.225 2.405 0.060 3.728-1.142-0.541 0.090-1.293 0.18-2.195 0.105 1.939-0.316 4.705-1.503 5.096-3.322-0.902 0.496-1.879 0.902-2.871 1.203 1.052-0.316 2.225-1.157 2.811-2.826zM15.955 17.834c0.391 0.015 0.797 0.105 1.188 0.256l-0.466 1.548-0.436 0.361 0.15-0.496 0.195-0.165v-0.15l-0.271 0.241-0.015 0.030-0.346 1.112-0.346-1.112-0.015-0.030-0.271-0.241v0.165l0.195 0.15 0.165 0.526-0.015-0.030-0.436-0.361-0.451-1.548c0.376-0.18 0.782-0.256 1.172-0.256z" />
      <path d="M16 8.243l0.887 2.646h2.901l-2.345 1.789 0.887 2.751-2.33-1.699-2.345 1.699 0.902-2.751-2.345-1.639h2.901z" />
    </svg>
  );
}

IconEagleTracker.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
