// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconMore({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 2.393c7.515 0 13.607 6.092 13.607 13.607s-6.092 13.607-13.607 13.607c-7.515 0-13.607-6.092-13.607-13.607 0.009-7.511 6.096-13.598 13.607-13.607zM16 1.5c-8.008 0-14.5 6.492-14.5 14.5s6.492 14.5 14.5 14.5c8.008 0 14.5-6.492 14.5-14.5s-6.492-14.5-14.5-14.5z" />
      <path d="M12.101 16c0 1.068-0.866 1.933-1.933 1.933s-1.933-0.866-1.933-1.933c0-1.068 0.866-1.933 1.933-1.933s1.933 0.866 1.933 1.933z" />
      <path d="M17.933 16c0 1.068-0.866 1.933-1.933 1.933s-1.933-0.866-1.933-1.933c0-1.068 0.866-1.933 1.933-1.933s1.933 0.866 1.933 1.933z" />
      <path d="M23.766 16c0 1.068-0.866 1.933-1.933 1.933s-1.933-0.866-1.933-1.933c0-1.068 0.866-1.933 1.933-1.933s1.933 0.866 1.933 1.933z" />
    </svg>
  );
}

IconMore.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
