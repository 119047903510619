// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconVideo({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 51 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M48.658 2.458v27.083c-0.002 0.507-0.413 0.917-0.92 0.917-0.246 0-0.47-0.097-0.635-0.254l-12.633-12.18v-4.048l12.633-12.183c0.165-0.157 0.389-0.254 0.635-0.254 0.508 0 0.92 0.412 0.92 0.92z" />
      <path d="M25.587 1.5c0.073-0.004 0.158-0.006 0.243-0.006 1.52 0 2.877 0.7 3.764 1.796 1.101 1.005 1.786 2.434 1.791 4.023v17.389c-0.167 3.133-2.665 5.632-5.783 5.798l-17.411 0.001c-3.133-0.167-5.632-2.665-5.798-5.783l-0.001-17.414c0.164-3.135 2.664-5.637 5.783-5.803z" />
    </svg>
  );
}

IconVideo.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
