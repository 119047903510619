// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconFeedback({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.174 16.355l-3.462-2.818h-2.76v0.57h1.52c0.419 0 0.763 0.342 0.763 0.763 0 0.424-0.344 0.763-0.763 0.763h-1.52v0.741h4.229c0.421 0 0.765 0.342 0.765 0.763 0 0.038-0.005 0.077-0.008 0.11l1.237-0.893zM30.4 10.312v11.376h-17.448v-3.787h4.080l-3.698 2.669v0.469l6.146-4.432 1.549 1.261c0.253 0.213 0.424 0.32 0.595 0.32 0.173 0 0.341-0.107 0.592-0.317l1.576-1.264 6.226 4.435v-0.469l-5.915-4.214 5.915-4.736v-0.52l-8.066 6.454c-0.328 0.283-0.328 0.283-0.664 0l-1.469-1.194-0.149-0.126-0.157-0.126-3.157-2.573h1.538c0.421 0 0.765-0.344 0.765-0.763 0-0.424-0.344-0.763-0.765-0.763h-3.411l-1.149-0.936v0.528l0.499 0.408h-0.882v-1.699h17.448z" />
      <path d="M18.277 12.774c0 0.21-0.171 0.382-0.382 0.382h-9.744c-0.317 0-0.573-0.069-0.573-0.382 0-0.317 0.256-0.382 0.573-0.382h9.744c0.211 0 0.382 0.17 0.382 0.382z" />
      <path d="M14.853 14.87c0 0.213-0.171 0.381-0.381 0.381h-9.746c-0.21 0-0.382-0.168-0.382-0.381 0-0.21 0.173-0.382 0.382-0.382h9.746c0.21 0 0.381 0.173 0.381 0.382z" />
      <path d="M17.563 17.138c0 0.21-0.171 0.381-0.382 0.381h-9.744c-0.211 0-0.382-0.171-0.382-0.381s0.171-0.382 0.382-0.382h9.744c0.211 0 0.382 0.173 0.382 0.382z" />
      <path d="M12.109 19.306c0 0.21-0.173 0.382-0.382 0.382h-9.744c-0.211 0-0.382-0.173-0.382-0.382 0-0.214 0.171-0.382 0.382-0.382h9.744c0.21 0 0.382 0.168 0.382 0.382z" />
    </svg>
  );
}

IconFeedback.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
