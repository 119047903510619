// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconDirectFulfillment({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 12.048c-0 0-0.001 0-0.002 0-2.182 0-3.95 1.769-3.95 3.95s1.769 3.95 3.95 3.95c2.181 0 3.949-1.768 3.95-3.949 0-0.001 0-0.001 0-0.002 0-2.181-1.768-3.95-3.949-3.95zM15.917 18.592h-0.019c-1.433-0.005-2.592-1.168-2.592-2.602 0-1.437 1.165-2.602 2.602-2.602s2.602 1.165 2.602 2.602c0 1.433-1.159 2.596-2.591 2.602z" />
      <path d="M15.877 1.6c-7.895 0.049-14.277 6.461-14.277 14.363 0 0.013 0 0.026 0 0.039 0 7.358 5.8 14.398 14.4 14.398 7.36 0 14.4-5.76 14.4-14.4v-0.194c-0.089-7.865-6.486-14.207-14.364-14.207-0.056 0-0.112 0-0.168 0.001zM16 29.12c-7.246 0-13.12-5.874-13.12-13.12s5.874-13.12 13.12-13.12c7.246 0 13.12 5.874 13.12 13.12-0.009 7.242-5.878 13.111-13.119 13.12zM21.104 5.877l-2.802 5.182c1.326 0.282 2.354 1.309 2.631 2.613l5.025-2.936s-1.158-3.83-4.854-4.859zM11.062 17.934l-5.019 3.003s1.157 3.826 4.853 4.854l2.72-5.021c-1.294-0.424-2.266-1.49-2.549-2.812z" />
      <path d="M21.104 5.877l-2.802 5.182c1.326 0.282 2.354 1.309 2.631 2.613l5.025-2.936s-1.158-3.83-4.854-4.859zM11.062 17.934l-5.019 3.003s1.157 3.826 4.853 4.854l2.72-5.021c-1.294-0.424-2.266-1.49-2.549-2.812z" />
      <path d="M25.957 10.734l-5.019 2.96c-0.282-1.326-1.309-2.354-2.613-2.631l2.779-5.186c3.696 1.029 4.853 4.858 4.853 4.858z" />
      <path d="M13.614 20.771l-2.72 5.021c-3.694-1.029-4.851-4.854-4.851-4.854l5.021-3.003c0.287 1.346 1.258 2.412 2.524 2.829z" />
    </svg>
  );
}

IconDirectFulfillment.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
