// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconLocalFunction({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.567 16.185l14.688-6.44-6.548 14.688-0.788-7.352z" />
      <path d="M16 29.9c-3.707 0-7.197-1.452-9.823-4.077s-4.077-6.116-4.077-9.823 1.452-7.197 4.077-9.823 6.116-4.077 9.823-4.077 7.197 1.452 9.823 4.077 4.077 6.116 4.077 9.823-1.452 7.197-4.077 9.823-6.116 4.077-9.823 4.077zM16 4.061c-3.197 0-6.193 1.236-8.448 3.49s-3.49 5.251-3.49 8.448 1.236 6.193 3.49 8.448c2.255 2.255 5.251 3.49 8.448 3.49s6.193-1.236 8.448-3.49c2.255-2.255 3.49-5.251 3.49-8.448s-1.236-6.193-3.49-8.448c-2.255-2.255-5.251-3.49-8.448-3.49z" />
    </svg>
  );
}

IconLocalFunction.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
