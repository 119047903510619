// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconCanadaFlagWhite({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.013 1.6c-3.979 0-7.579 1.613-10.186 4.221v20.358c2.606 2.608 6.206 4.221 10.186 4.221 3.978 0 7.578-1.613 10.184-4.219v-20.362c-2.606-2.606-6.206-4.219-10.184-4.219zM20.246 20.467l0.357 1.176c-1.406-0.162-2.667-0.307-4.074-0.47-0.125-0.002-0.208 0.069-0.206 0.213l0.251 4.349-0.562 0.003-0.562-0.003 0.25-4.349c0.002-0.144-0.080-0.214-0.205-0.213-1.406 0.163-2.669 0.309-4.075 0.47l0.358-1.176c0.058-0.205-0.026-0.432-0.187-0.557l-4.085-3.33 0.845-0.398c0.373-0.133 0.269-0.342 0.136-0.848l-0.624-2.274 2.373 0.501c0.232 0.035 0.387-0.112 0.422-0.253l0.304-1.070 1.877 2.107c0.267 0.338 0.808 0.338 0.64-0.443l-0.802-4.267 1.075 0.558c0.302 0.168 0.605 0.197 0.773-0.106l1.486-2.778 1.485 2.778c0.17 0.302 0.472 0.274 0.773 0.106l1.077-0.558-0.802 4.267c-0.17 0.781 0.371 0.781 0.638 0.443l1.877-2.107 0.306 1.070c0.035 0.141 0.189 0.288 0.421 0.253l2.373-0.501-0.622 2.274c-0.134 0.506-0.238 0.715 0.134 0.848l0.846 0.398-4.086 3.33c-0.162 0.125-0.243 0.352-0.186 0.557z" />
    </svg>
  );
}

IconCanadaFlagWhite.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
