// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconShareOne({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.014 15.372c-1.836 0.157-3.522 0.665-5.035 1.457l0.073-0.035c0.007 0.053 0.011 0.114 0.011 0.176 0 0.002 0 0.003 0 0.005v-0c0 0.002 0 0.004 0 0.007 0 1.176-0.953 2.129-2.129 2.129s-2.129-0.953-2.129-2.129c0-1.122 0.868-2.042 1.97-2.123l0.007-0c0.883-1.737 2.213-3.137 3.84-4.081l0.047-0.025c-1.064-0.647-2.351-1.031-3.728-1.031-4.007 0-7.256 3.249-7.256 7.256s3.249 7.256 7.256 7.256c4.007 0 7.255-3.248 7.256-7.255v-0c0-0.004 0-0.010 0-0.015 0-0.564-0.067-1.113-0.192-1.639l0.010 0.048zM8.078 15.425l-2.89-1.568c0.586-2.146 2.801-2.804 2.801-2.804l1.725 2.89c-0.779 0.168-1.394 0.733-1.632 1.467l-0.004 0.015zM13.886 22.54l-1.723-2.89c1.306-0.262 1.521-1.521 1.521-1.521l3.008 1.603c-0.589 2.149-2.806 2.807-2.806 2.807z" />
      <path d="M19.402 15.311c-0.237 0-0.479 0-0.719 0.018 0.11 0.492 0.173 1.057 0.173 1.637 0 0.003 0 0.006 0 0.009v-0c0 0.002 0 0.005 0 0.007 0 4.377-3.548 7.925-7.925 7.925s-7.925-3.548-7.925-7.925c0-4.377 3.548-7.925 7.925-7.925 1.643 0 3.17 0.5 4.435 1.356l-0.028-0.018c0.24-0.117 0.491-0.231 0.753-0.338-1.42-1.067-3.212-1.709-5.154-1.709-4.765 0-8.628 3.863-8.628 8.628s3.863 8.628 8.628 8.628c4.765 0 8.628-3.863 8.628-8.628 0-0.001 0-0.002 0-0.002v0c-0.001-0.589-0.060-1.164-0.172-1.719l0.009 0.056z" />
      <path d="M24.282 18.49v-3.25l-0.269-0.067c-1.372-0.361-2.949-0.572-4.574-0.581l-0.005-0c-0.068 0-0.138 0-0.205 0-0.245 0-0.49 0.009-0.73 0.024s-0.456 0.027-0.675 0.056c-1.833 0.171-3.513 0.676-5.029 1.455l0.077-0.036c-0.894 0.455-1.664 0.96-2.371 1.539l0.023-0.018c0.244-1.014 0.594-1.906 1.049-2.733l-0.028 0.056c0.887-1.597 2.185-2.871 3.752-3.706l0.050-0.024c0.208-0.114 0.427-0.219 0.654-0.319s0.482-0.208 0.741-0.304c1.825-0.665 4.172-1.014 7.168-0.833l0.376 0.024v-3.376l5.404 6.045z" />
    </svg>
  );
}

IconShareOne.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
