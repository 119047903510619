// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconGoals({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.398 29.79c-0.124 0-0.262 0-0.386 0-7.335-0.216-13.126-6.347-12.91-13.682 0.216-7.35 6.347-13.141 13.682-12.925 1.93 0.062 3.768 0.51 5.482 1.359 0.262 0.139 0.386 0.463 0.247 0.726s-0.463 0.386-0.726 0.247c-1.575-0.772-3.274-1.189-5.034-1.251-6.733-0.201-12.369 5.127-12.57 11.86s5.127 12.369 11.86 12.57c6.717 0.216 12.369-5.127 12.57-11.86 0.062-1.899-0.324-3.737-1.112-5.466-0.124-0.278 0-0.602 0.262-0.726 0.278-0.124 0.602 0 0.726 0.262 0.865 1.869 1.266 3.876 1.204 5.945-0.216 7.227-6.131 12.94-13.296 12.94z" />
      <path d="M29.852 5.036c-0.077-0.201-0.278-0.324-0.479-0.324l-2.1-0.093-0.015-1.992c0-0.216-0.124-0.401-0.324-0.479s-0.417-0.031-0.571 0.108l-3.413 3.397c-0.108 0.108-0.154 0.247-0.154 0.386l-0.031 2.224-0.015-0.015-1.096 1.096c-2.162-1.946-5.219-2.903-8.478-2.224-3.644 0.772-6.532 3.644-7.304 7.289-1.313 6.208 3.413 11.69 9.389 11.69 5.297 0 9.605-4.308 9.605-9.605 0-2.455-0.927-4.694-2.44-6.393l1.066-1.066 2.424 0.108c0.015 0 0.015 0 0.031 0 0.139 0 0.278-0.062 0.371-0.154l3.428-3.397c0.154-0.139 0.201-0.355 0.108-0.556zM23.829 16.479c0 4.648-3.783 8.447-8.431 8.447s-8.447-3.783-8.447-8.447c0.015-4.648 3.799-8.431 8.447-8.431 2.131 0 4.077 0.788 5.559 2.1l-2.1 2.1c-0.942-0.772-2.162-1.235-3.474-1.235-3.027 0-5.482 2.455-5.482 5.482s2.455 5.482 5.482 5.482c3.027 0 5.482-2.455 5.482-5.482 0-1.313-0.463-2.533-1.235-3.474l2.1-2.1c1.313 1.482 2.1 3.428 2.1 5.559zM15.398 17.606c0.618 0 1.112-0.494 1.112-1.112 0-0.108-0.015-0.201-0.046-0.309l2.394-2.394c0.587 0.741 0.927 1.683 0.927 2.702 0 2.424-1.977 4.401-4.401 4.401s-4.401-1.977-4.401-4.401c0-2.424 1.977-4.401 4.401-4.401 1.019 0 1.961 0.34 2.702 0.927l-2.378 2.394c-0.093-0.031-0.201-0.046-0.309-0.046-0.618 0-1.112 0.494-1.112 1.112s0.494 1.127 1.112 1.127zM28.446 6.009l-2.471 2.455c-0.077 0.062-0.17 0.108-0.262 0.108 0 0-0.015 0-0.015 0l-1.884-0.077c-0.216-0.015-0.386-0.17-0.386-0.386l0.031-1.776c0-0.108 0.046-0.201 0.108-0.278l2.455-2.424c0.108-0.108 0.262-0.139 0.417-0.077 0.139 0.062 0.232 0.185 0.232 0.34l0.015 1.436 1.513 0.077c0.154 0 0.293 0.093 0.355 0.232 0.031 0.108 0 0.263-0.108 0.371z" />
    </svg>
  );
}

IconGoals.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
