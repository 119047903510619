// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconHouseMeeting({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M25.979 29.538h-7.178c-0 0-0.001 0-0.002 0-0.33 0-0.597-0.267-0.597-0.597 0-0.001 0-0.001 0-0.002v0-7.776h-4.786v7.776c0 0.331-0.268 0.598-0.598 0.598v0h-7.179c0 0 0 0 0 0-0.33 0-0.597-0.267-0.597-0.597 0-0.001 0-0.001 0-0.002v0-11.963c-0-0.009-0.001-0.020-0.001-0.031 0-0.331 0.268-0.598 0.598-0.598s0.598 0.268 0.598 0.598c0 0.011-0 0.022-0.001 0.032l0-0.002v11.36h5.982v-7.776c0-0.331 0.268-0.598 0.598-0.598h5.982c0.33 0.001 0.597 0.268 0.597 0.598 0 0 0 0 0 0v0 7.776h5.982v-11.36c0-0.331 0.268-0.598 0.598-0.598s0.598 0.268 0.598 0.598v0 11.963c0 0 0 0.001 0 0.002 0 0.33-0.267 0.597-0.597 0.597-0.001 0-0.001 0-0.002 0h0z" />
      <path d="M2.198 17.531c-0.33-0.001-0.597-0.268-0.597-0.598 0-0.165 0.067-0.314 0.175-0.422l13.874-13.874c0.108-0.108 0.257-0.175 0.422-0.175s0.314 0.067 0.422 0.175v0l13.73 13.73c0.099 0.106 0.159 0.249 0.159 0.406 0 0.331-0.268 0.598-0.598 0.598-0.157 0-0.299-0.060-0.406-0.159l0 0-13.307-13.307-13.451 13.451c-0.107 0.108-0.256 0.174-0.42 0.174-0.001 0-0.002 0-0.002 0h0z" />
      <path d="M24.782 9.2c-0.33-0.001-0.598-0.268-0.598-0.598v-3.589h-4.184c-0.331 0-0.598-0.268-0.598-0.598s0.268-0.598 0.598-0.598v0h4.786c0 0 0.001 0 0.002 0 0.33 0 0.597 0.267 0.597 0.597 0 0.001 0 0.001 0 0.002v-0 4.187c0 0 0 0 0 0 0 0.331-0.268 0.598-0.598 0.598-0.001 0-0.002 0-0.003 0h0z" />
    </svg>
  );
}

IconHouseMeeting.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
