// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconAudio({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 40 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.624 4.186c0.383 0.383 0.763 0.763 0.763 1.144v20.975c0 0.383-0.383 0.763-0.763 1.144h-0.383c-0.383 0-0.763 0-0.763-0.383l-6.48-6.482h-4.958c-0.383 0-0.763 0-0.763-0.383-0.383-0.389-0.383-0.389-0.383-0.763v-7.245c0-0.383 0-0.763 0.383-0.763 0.383-0.383 0.383-0.383 0.763-0.383h4.958l6.482-6.482c0-0.378 0.761-0.378 1.144-0.378zM23.632 24.781c0.763 0 1.144-0.383 1.907-0.763 2.122-2.138 3.433-5.082 3.433-8.333 0-0.020-0-0.041-0-0.061-0.009-2.544-0.798-4.904-2.141-6.854-0.38-0.547-0.814-1.062-1.291-1.533-0.503-0.461-1.17-0.749-1.905-0.764-0.766-0-1.147 0.382-1.91 0.763-0.389 0.407-0.662 0.928-0.761 1.509-0.002 0.78 0.38 1.161 0.761 1.924 1.183 1.307 1.907 3.048 1.907 4.959s-0.724 3.652-1.913 4.965c-0.455 0.496-0.742 1.163-0.757 1.897 0.012 0.622 0.306 1.171 0.759 1.526 0.392 0.48 0.979 0.782 1.637 0.782 0.097 0 0.192-0.007 0.285-0.019zM31.641 30.502c0.737-0.016 1.405-0.303 1.909-0.765 3.082-3.631 4.957-8.374 4.957-13.555 0-0.061-0-0.123-0.001-0.184 0.007-0.189 0.011-0.423 0.011-0.657 0-5.024-1.884-9.607-4.985-13.082-0.39-0.37-0.911-0.642-1.492-0.741-0.108-0.011-0.214-0.016-0.322-0.016-0.586 0-1.138 0.147-1.621 0.405-0.368 0.398-0.637 0.919-0.734 1.498-0.011 0.108-0.016 0.214-0.016 0.321 0 0.586 0.147 1.138 0.405 1.621 2.363 2.754 3.807 6.382 3.807 10.347 0 0.11-0.001 0.219-0.003 0.329 0.007 0.148 0.010 0.34 0.010 0.534 0 3.89-1.449 7.442-3.837 10.144-0.312 0.29-0.514 0.724-0.514 1.206 0 0.248 0.054 0.483 0.15 0.695 0.008 0.609 0.302 1.157 0.754 1.512 0.377 0.388 0.759 0.388 1.522 0.388z" />
    </svg>
  );
}

IconAudio.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
