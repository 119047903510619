// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconAlert({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.963 26.394c-1.016 0-1.751-0.803-1.751-1.853 0-1.081 0.735-1.844 1.799-1.844s1.776 0.757 1.776 1.844c0 1.043-0.71 1.853-1.799 1.853zM14.882 21.189l-0.426-11.007h3.077l-0.426 11.007z" />
      <path d="M25.9 29.9h-19.801c-1.598 0-2.843-0.601-3.507-1.699-0.72-1.181-0.646-2.811 0.219-4.479l9.903-19.108c0.837-1.622 2.006-2.514 3.287-2.514v0c1.28 0 2.449 0.893 3.288 2.516l9.901 19.106c0.86 1.662 0.939 3.291 0.219 4.479-0.664 1.098-1.91 1.699-3.509 1.699zM16 28.527h9.898c1.104 0 1.937-0.368 2.343-1.038 0.463-0.772 0.371-1.914-0.267-3.138l-9.9-19.109c-0.585-1.134-1.341-1.778-2.074-1.776s-1.49 0.642-2.074 1.776l-9.901 19.117c-0.638 1.226-0.734 2.369-0.267 3.138 0.406 0.67 1.236 1.038 2.341 1.038z" />
    </svg>
  );
}

IconAlert.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
