import styles from "./index.module.css";
import React, { FunctionComponent, useContext } from "react";
import classNames from "classnames";
import { ChevronLeft } from "react-ikonate";
import { AppHeaderContext } from "../../contexts/AppHeaderContext";

/**
 * Navigation bar seen along the top of the screen in any app.
 * Houses app title and back button, navigation items on desktop and custom controls.
 */
export const AppHeaderView: FunctionComponent = ({ children }) => {
  const { currentState } = useContext(AppHeaderContext);
  const { isVisible, title, icon, controls, onBack } = currentState;

  if (!isVisible) {
    return null;
  }

  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        <div
          className={classNames(styles.titleGroup, {
            [styles.showBack]: onBack != null,
          })}
        >
          <button
            className={styles.backButton}
            disabled={onBack == null}
            onClick={onBack}
          >
            <ChevronLeft className={styles.backIcon} />
          </button>

          <div className={styles.title}>
            <div className={styles.appIcon}>{icon}</div>
            <h1>{title}</h1>
          </div>
        </div>

        <div className={styles.content}>
          <div
            className={classNames(
              styles.controls,
              controls && styles.withControls
            )}
          >
            {controls}
          </div>

          {children}
        </div>
      </div>
    </header>
  );
}
