import styles from "./Toggle.module.css";
import React, { useState } from "react";
import PropTypes from "prop-types";

export function Toggle(props) {
  const { label, name, onChange, isOn } = props;
  const [id] = useState("toggle_" + Math.round(Math.random() * 99999999999));

  return (
    <label className={styles.container}>
      {label && <span className={styles.label}>{label}</span>}
      <div className={`${styles.toggleContainer} ${isOn ? styles.isOn : ""}`}>
        <input
          id={id}
          type="checkbox"
          name={name}
          className={styles.checkbox}
          checked={isOn}
          onChange={(e) => {
            const isToggled = e.target.value.toLowerCase() === "on";
            onChange({
              isOn: isToggled,
              event: e,
              target: e.target,
            });
          }}
        />
        <div className={styles.toggle} />
      </div>
    </label>
  );
}

Toggle.propTypes = {
  label: PropTypes.string,
  /**
   * HTML name property for use in forms.
   */
  name: PropTypes.string,
  isOn: PropTypes.bool,
  onChange: PropTypes.func,
};
