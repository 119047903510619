// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconAttitudeMeeting({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 30.4c-7.953 0-14.4-6.447-14.4-14.4s6.447-14.4 14.4-14.4c7.953 0 14.4 6.447 14.4 14.4v0c-0.009 7.949-6.451 14.391-14.399 14.4h-0.001zM16 2.539c-7.434 0-13.461 6.027-13.461 13.461s6.027 13.461 13.461 13.461c7.434 0 13.461-6.027 13.461-13.461v0c-0.008-7.431-6.030-13.453-13.46-13.461h-0.001z" />
      <path d="M10.774 11.715c0-1.643 0.747-2.976 1.669-2.976s1.667 1.341 1.667 2.976-0.746 2.973-1.667 2.973-1.669-1.331-1.669-2.973z" />
      <path d="M17.080 11.715c0-1.643 0.747-2.976 1.669-2.976s1.669 1.333 1.669 2.976-0.747 2.973-1.669 2.973-1.669-1.331-1.669-2.973z" />
      <path d="M15.914 26.066c-5.546 0-9.742-8.442-9.92-8.8-0.035-0.072-0.055-0.157-0.055-0.247 0-0.145 0.054-0.278 0.142-0.38l-0.001 0.001c0.108-0.126 0.267-0.205 0.445-0.205 0.058 0 0.114 0.008 0.167 0.024l-0.004-0.001c2.592 0.766 3.669 1.043 5.398 1.44 3.274 0.934 7.258 0.011 7.296 0 2.055-0.445 3.807-0.969 5.501-1.609l-0.265 0.088c0.062-0.025 0.134-0.039 0.209-0.039 0.321 0 0.581 0.26 0.581 0.581 0 0.083-0.017 0.162-0.049 0.233l0.001-0.004c-0.165 0.36-3.909 8.918-9.448 8.918zM7.702 17.962c1.338 2.269 4.539 6.942 8.211 6.942 3.627 0 6.546-4.675 7.762-6.974-1.058 0.371-2.414 0.748-3.8 1.050l-0.25 0.046c-0.16 0.038-4.302 1.002-7.84-0.010-1.334-0.306-2.331-0.552-4.083-1.054z" />
    </svg>
  );
}

IconAttitudeMeeting.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
