// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconAdd({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 2.909c7.219 0 13.091 5.873 13.091 13.091s-5.872 13.091-13.091 13.091c-7.219 0-13.091-5.872-13.091-13.091s5.872-13.091 13.091-13.091zM16 1.6c-7.953 0-14.4 6.448-14.4 14.4s6.447 14.4 14.4 14.4 14.4-6.447 14.4-14.4c0-7.952-6.447-14.4-14.4-14.4z" />
      <path d="M23.2 15.345h-6.545v-6.545h-1.309v6.545h-6.545v1.309h6.545v6.545h1.309v-6.545h6.545z" />
    </svg>
  );
}

IconAdd.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
