import styles from "./index.module.css";
import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import { MobileBridge } from "@wwg/mobile-bridge";
import { AppFooterView } from "./components/AppFooterView";
import { AppHeaderView } from "./components/AppHeaderView/index";
import { DesktopNav } from "./components/DesktopNav/index";
import { MobileNav } from "./components/MobileNav/index";
import {
  AppHeaderContext,
  useAppHeaderState,
} from "./contexts/AppHeaderContext";
import {
  AppFooterContext,
  useAppFooterState,
} from "./contexts/AppFooterContext";
import { MobileBridgeContext } from "./contexts/MobileBridgeContext";

export { AppHeader } from "./controls/AppHeader";
export { AppFooter } from "./controls/AppFooter";
export { useMobileBridge } from "./hooks/useMobileBridge";
export { MobileBridgeContext } from "./contexts/MobileBridgeContext";

const propTypes = {
  /**
   * The app's instance of MobileBridge from `@wwg/mobile-bridge`.
   */
  mobileBridge: PropTypes.instanceOf(MobileBridge).isRequired,

  /**
   * Configuration for the app's header.
   */
  header: PropTypes.shape({
    /**
     * String to display as the app's title.
     */
    title: PropTypes.string.isRequired,

    /**
     * Something to render as the app's header icon.
     */
    icon: PropTypes.node.isRequired,
  }).isRequired,

  /**
   * Configuration for the app's top-level navigation items.
   */
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * String to display as the nav button title.
       */
      title: PropTypes.string.isRequired,

      /**
       * Something to render as the nav button icon.
       */
      icon: PropTypes.node.isRequired,

      /**
       * Path to navigate to when the nav item is clicked.
       */
      path: PropTypes.string.isRequired,
    })
  ),

  /**
   * Limit the maximum header width to a number of pixels. Defaults to full width.
   */
  maxHeaderWidth: PropTypes.number,

  /**
   * Limit the maximum content width to a number of pixels. Defaults to full width.
   */
  maxContentWidth: PropTypes.number,

  /**
   * Limit the maximum footer width to a number of pixels. Defaults to full width.
   */
  maxFooterWidth: PropTypes.number,
};

type AppFrameProps = PropTypes.InferProps<typeof propTypes>;

/**
 * Top level frame for app navigation. Most apps should go inside one of these.
 * Provides a standard set of navigation controls that you can control in your app
 * through hooks.
 */
export const AppFrame: FunctionComponent<AppFrameProps> = ({
  mobileBridge,
  header,
  navigation,
  maxHeaderWidth,
  maxContentWidth,
  maxFooterWidth,
  children,
}) => {
  const headerState = useAppHeaderState({
    isVisible: true,
    title: header.title,
    icon: header.icon,
  });

  const footerState = useAppFooterState({
    isVisible: true,
  });

  const showNav =
    navigation && (mobileBridge.isApp() && !mobileBridge.isMocked()) === false;

  // TODO: Add method of listening for tab changes from mobile app
  //       instead of reloading the app for each tab.

  return (
    <MobileBridgeContext.Provider value={mobileBridge}>
      <AppHeaderContext.Provider value={headerState}>
        <AppFooterContext.Provider value={footerState}>
          <div
            className={styles.frame}
            style={
              {
                "--frameMaxHeaderWidth": maxHeaderWidth
                  ? maxHeaderWidth + "px"
                  : "100%",
                "--frameMaxContentWidth": maxContentWidth
                  ? maxContentWidth + "px"
                  : "100%",
                "--frameMaxFooterWidth": maxFooterWidth
                  ? maxFooterWidth + "px"
                  : "100%",
                "--frameFooterOffset": showNav ? 60 + "px" : 0,
              } as unknown
            }
          >
            <AppHeaderView>
              {showNav && (
                <div className={styles.desktopNav}>
                  {/* DesktopNav is visible only in the desktop layout */}
                  <DesktopNav items={navigation} />
                </div>
              )}
            </AppHeaderView>

            <div className={styles.appContainer}>
              <div className={styles.appContents}>{children}</div>
            </div>

            <AppFooterView />

            {showNav && (
              <div className={styles.mobileNav}>
                {/* MobileNav is visible only in the mobile web layout */}
                <MobileNav items={navigation} />
              </div>
            )}
          </div>
        </AppFooterContext.Provider>
      </AppHeaderContext.Provider>
    </MobileBridgeContext.Provider>
  );
};

AppFrame.propTypes = propTypes;
