// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconIdea({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.594 28.096h-20.994c0.070-0.13 0.034-0.337 0.056-0.594 0.194-4.765 3.734-3.851 6.118-5.838l0.446-0.291c0.472 0 0.725-1.296 0.894-1.602-0.306-0.451-0.803-0.904-1.266-1.442l-0.043-0.299c-0.056-0.31-0.29-0.557-0.352-0.846l-0.178-0.818c-0.154-0.723 0.043-1.806 0.109-2.566 0.104-1.192 1.605-3.738 2.923-4.019 4.114-0.874 4.155 1.538 5.546 3.181 0.309 0.366 0.213 0.939 0.229 1.422 0.003 0.109-0.274 0.661-0.294 0.771-0.13 0.702-0.082 1.49-0.227 2.197-0.018 0.088-0.040 0.182-0.066 0.28 0.016 0.017 0.030 0.035 0.045 0.053-0.024 0.315-0.152 0.571-0.325 0.803-0.254 0.658-0.619 1.376-1.074 1.981l0.234 0.547c0.4 0.317 0.883 0.586 1.36 0.757 0.051 0.024 0.101 0.048 0.15 0.072 1.28 0.635 2.042 0.96 3.357 1.537 2.079 0.925 3.351 2.459 3.351 4.714z" />
      <path d="M16.896 16.961l0.185-0.33c0.539-0.961 0.752-2.16 0.827-2.738-1.64-1.105-2.577-2.689-2.577-4.364 0-3.211 3.424-5.823 7.633-5.823s7.633 2.612 7.633 5.823c0 3.21-3.424 5.822-7.633 5.822-0.588 0-1.175-0.051-1.744-0.152-0.954 0.518-3.11 1.644-3.948 1.726l-0.377 0.036zM22.965 4.102c-3.991 0-7.237 2.435-7.237 5.427 0 1.573 0.911 3.068 2.501 4.1l0.103 0.067-0.014 0.122c-0.056 0.478-0.23 1.612-0.697 2.627 1.003-0.303 2.863-1.295 3.473-1.628l0.062-0.033 0.069 0.013c0.568 0.105 1.154 0.158 1.742 0.158 3.991 0 7.237-2.434 7.237-5.426s-3.247-5.427-7.237-5.427z" />
      <path d="M22.954 6.676c1.657 0 3.314-0 4.972 0 0.055 0 0.113-0.002 0.165 0.013 0.11 0.032 0.187 0.159 0.175 0.271-0.014 0.123-0.112 0.22-0.237 0.231-0.048 0.004-0.095 0.003-0.144 0.003-3.282 0-6.565 0-9.847 0-0.036 0-0.072 0-0.108-0.001-0.174-0.006-0.273-0.104-0.27-0.267 0.003-0.153 0.107-0.251 0.272-0.252 0.675-0.001 1.35 0 2.025 0 0.998 0 1.996 0 2.995 0z" />
      <path d="M22.954 8.211c1.657 0 3.314-0 4.972 0 0.055 0 0.113-0.002 0.165 0.013 0.11 0.032 0.187 0.159 0.175 0.271-0.014 0.123-0.112 0.221-0.237 0.231-0.048 0.004-0.095 0.003-0.144 0.003-3.282 0-5.854 0-9.137 0-0.036 0-0.071 0-0.107-0.001-0.174-0.006-0.273-0.104-0.27-0.267 0.003-0.152 0.108-0.251 0.273-0.251 0.675-0.001 1.35-0 2.025-0 0.998 0 1.285 0 2.284 0z" />
      <path d="M22.954 9.673c1.657 0 3.314 0 4.972 0 0.055 0 0.113-0.001 0.165 0.014 0.11 0.032 0.187 0.158 0.175 0.271-0.014 0.122-0.112 0.22-0.237 0.23-0.048 0.004-0.095 0.003-0.144 0.003-3.282 0-3.72 0-7.003 0-0.036 0-0.072 0.001-0.108-0.001-0.174-0.006-0.273-0.104-0.27-0.267 0.004-0.153 0.108-0.251 0.272-0.252 0.675-0-0.072 0 0.603 0 0.998 0 0.574 0 1.573 0z" />
      <path d="M22.954 11.136c1.657 0 3.314-0 4.972 0 0.055 0 0.113-0.002 0.165 0.014 0.11 0.032 0.187 0.158 0.175 0.272-0.014 0.122-0.112 0.22-0.237 0.23-0.048 0.004-0.095 0.003-0.144 0.003-3.282 0-5.143 0-8.426 0-0.035 0-0.071 0-0.108-0.001-0.174-0.006-0.272-0.104-0.27-0.267 0.004-0.152 0.108-0.251 0.273-0.251 0.675-0.001-0.072-0 0.603-0 0.998-0.001 1.996-0 2.995-0z" />
    </svg>
  );
}

IconIdea.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
