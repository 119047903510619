import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import connector from "../../utils/native-connector";
import { useMobileBridge } from "components/AppFrame/hooks/useMobileBridge";
import { getFileType, FILE_TYPE } from "./getFileType";
import { FEATURES } from "@wwg/mobile-bridge";

const propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
};

type AutoLinkProps = PropTypes.InferType<typeof propTypes>;

/**
 * A smart <a> tag that tries to open its href in an app-friendly way when in the mobile app.
 * Supports PDFs and standard links. Takes all the same properties as an <a> tag. Acts the same as a normal <a>
 * tag when not running in the mobile app.
 *
 * Must be inside of a MobileBridgeContext.Provider. This is already true if your app is inside an AppFrame component.
 */
export const AutoLink: FunctionComponent<AutoLinkProps> = ({
  href,
  children,
  ...props
}) => {
  const mobile = useMobileBridge();

  return (
    <a
      {...props}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }

        // calling e.preventDefault() in a custom onClick handler will cancel the default behavior.
        if (!e.defaultPrevented) {
          if (mobile.isApp()) {
            let url = href;

            // Make absolute URL.
            if (url[0] === "/") {
              url = window.location.protocol + "//" + window.location.hostname + href;
            }

            const fileType = getFileType(href);

            if (fileType === FILE_TYPE.PDF && mobile.supports(FEATURES.viewPDF)) {
              mobile.viewPDF(url);
              e.preventDefault();
              e.stopPropagation();
              return;
            }

            if (mobile.supports(FEATURES.openLink)) {
              mobile.openLink(url);
              e.preventDefault();
              e.stopPropagation();
              return;
            }

            // ...otherwise link is handled normally.
          }
        }
      }}
    >
      {children}
    </a>
  );
};

AutoLink.propTypes = propTypes;
