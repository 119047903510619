// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconReceipts({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.896 9.050h6.95v-0.772h-6.95v0.772zM25.576 2.1v27.8l-1.915-2.348-1.915 2.348-1.915-2.348-1.915 2.348-1.915-2.332-1.915 2.332-1.915-2.348-1.915 2.348-1.915-2.348-1.915 2.348v-27.8h19.151zM22.641 21.869v-0.772h-3.089v0.772h3.089zM22.641 20.016v-0.772h-3.089v0.772h3.089zM22.641 18.008v-0.309h-13.746v0.309h13.746zM22.641 10.903v-0.772h-3.089v0.772h3.089zM22.641 9.050v-0.772h-3.089v0.772h3.089zM22.641 7.197v-0.772h-3.089v0.772h3.089zM15.846 7.197v-0.772h-6.95v0.772h6.95zM13.529 10.903v-0.772h-4.633v0.772h4.633z" />
    </svg>
  );
}

IconReceipts.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
