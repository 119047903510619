import log from "loglevel";
import styles from "./DatePicker.module.css";
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { getDaysInMonth } from "date-fns";
import { Calendar } from "react-ikonate";
import classNames from "classnames";

import { useClickAway } from "react-use";

import { TextInput } from "../../TextInput/TextInput";
import { CalendarPicker } from "./CalendarPicker/CalendarPicker";

export function DatePicker({ value, onChange, isRequired, useNativeOnMobile }) {
  const [rawValue, setRawValue] = useState(value || dateToValue(new Date()));
  const [editValue, setEditValue] = useState(valueToLabel(rawValue));
  const [isOpen, setIsOpen] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const touchRef = useRef(null);
  const groupRef = useRef(null);
  const containerRef = useRef(null);

  // Mobile devices fire both a touchstart and mousedown for compatibility.
  // Here we're tracking whether mousedown has been activated to decide if we need to proceed
  // with the mousedown logic.
  const [clickPrevented, setClickPrevented] = useState(false);

  useClickAway(containerRef, () => {
    setIsOpen(false);
  });

  // // React when the value prop is updated.
  // useEffect(() => {
  //   if (label !== editValue) {
  //     setEditValue(label);
  //     if (groupRef.current) {
  //       groupRef.current.classList.remove(styles.updated);
  //       setTimeout(() => {
  //         if (groupRef.current) {
  //           groupRef.current.classList.add(styles.updated);
  //         }
  //       });
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [label, groupRef]);

  return (
    <div className={styles.container} ref={containerRef}>
      <input
        className={styles.touchInput}
        type="date"
        ref={touchRef}
        required={isRequired}
        value={valueToInputString(rawValue)}
        onInput={(e) => {
          e.preventDefault();

          const value = e.target.value.split("-").map(Number);

          onChange(value);
          setRawValue(value);
          setEditValue(valueToLabel(value));
          setIsValid(true);
        }}
        onChange={(e) => {
          e.preventDefault();
        }}
      />

      <div
        className={classNames(styles.inputGroup, {
          [styles.invalid]: !isValid,
        })}
        ref={groupRef}
      >
        <TextInput
          value={editValue}
          onInput={(e) => {
            setEditValue(e.target.value);

            const value = parseInputDate(e.target.value);

            setIsValid(value != null);

            if (value) {
              setRawValue(value);
              onChange(value);
            }
          }}
          onChange={(e) => {}}
        />
        <button
          className={styles.toggleButton}
          onTouchStart={(e) => {
            if (useNativeOnMobile) {
              log.info("onTouchStart ", touchRef.current);
              setClickPrevented(true);

              if (touchRef.current) {
                touchRef.current.focus();
                touchRef.current.click();
              }
            }
          }}
          onMouseDown={(e) => {
            log.info("onMouseDown ", e);
            e.preventDefault();
          }}
          onClick={(e) => {
            log.info("onClick ", e);
            e.preventDefault();

            if (!clickPrevented) {
              setIsOpen(!isOpen);
            }

            setClickPrevented(false);
          }}
        >
          <Calendar fontSize={18} strokeWidth={1.5} />
        </button>
      </div>

      <div className={`${styles.popOverMenu} ${isOpen ? styles.open : ""}`}>
        <CalendarPicker
          value={rawValue}
          onChange={(value) => {
            setIsOpen(false);
            setEditValue(valueToLabel(value));
            setRawValue(value);
            onChange(value);
          }}
        />
      </div>
    </div>
  );
}

DatePicker.propTypes = {
  /**
   * An array of two numbers representing year, month and date. Month runs from 1-12.
   */
  value: PropTypes.arrayOf(PropTypes.number).isRequired,

  /**
   * A callback to fire when the user changes the value. Returns the newly selected time in the same [y, m, d] format.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Set required=true on the HTML input if true. Helpful for use in forms.
   */
  isRequired: PropTypes.bool,

  /**
   * Use native time picker instead of web-based one on touch devices.
   */
  useNativeOnMobile: PropTypes.bool,
};

function parseInputDate(str) {
  const matches = str.trim().match(/^(\d+)[-./](\d+)[-./](\d+)$/i);

  if (matches) {
    const parts = matches.slice(1, 4);

    const month = parseInt(parts[0]);
    const date = parseInt(parts[1]);
    const year = parseInt(parts[2]);

    const isValid = isDateValid(year, month, date);

    if (isValid) {
      return [year, month, date];
    }
  }

  return null;
}

function isDateValid(year, month, date) {
  if (year < 1900) {
    return false;
  }

  if (month < 1 || month > 12) {
    return false;
  }

  const daysInMonth = getDaysInMonth(new Date(year, month - 1));

  if (date < 1 || date > daysInMonth) {
    return false;
  }

  return true;
}

function dateToValue(date) {
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();

  return [y, m, d];
}

function valueToLabel(value) {
  const y = value[0].toString();
  const m = value[1].toString();
  const d = value[2].toString();

  return `${m}.${d}.${y}`;
}

/**
 * Formats value as a string for an HTML date input.
 *
 * @param {[number, number, number]} value
 */
function valueToInputString(value) {
  const y = value[0].toString().padStart(4, "0");
  const m = value[1].toString().padStart(2, "0");
  const d = value[2].toString().padStart(2, "0");

  return `${y}-${m}-${d}`;
}
