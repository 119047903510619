import styles from "./CircleLoader.module.css";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * Animated circular element loader. Place inside of a component
 * and it will fill the full space and center itself inside.
 *
 * Parent element must be `position:absolute` or `position:relative`
 * to contain the loader.
 *
 * The example below shows the general usage:
 *
 * @example
 * function ExampleComponent() {
 *   // Using useAuthedRequest as an example.
 *   const { data, loading, error } = useAuthedRequest("/my/api/route");
 *
 *   if (error) {
 *     // Actually handle this in real life.
 *     return "ERROR";
 *   }
 *
 *   // CircleLoader stays in the tree. Set isLoading=true to fade in
 *   // and isLoading=false to fade out. Loader should appear over top
 *   // of the component's content.
 *   return (
 *     <div style="position:relative">
 *       <ActualContent data={data} />
 *       <CircleLoader isLoading={loading} />
 *     </div>
 *   );
 * }
 */
export function CircleLoader({ isLoading = true }) {
  return (
    <div
      className={classNames(styles.container, {
        [styles.loading]: isLoading,
      })}
    >
      <div className={styles.spinner}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

CircleLoader.propTypes = {
  /**
   * Set to `true` to show the loader and `false` to hide.
   */
  isLoading: PropTypes.bool,
};
