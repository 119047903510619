// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconRegionalRally({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.466 22.547l-0.275 0.891c-0.028 0.093-0.044 0.201-0.044 0.312 0 0.511 0.342 0.942 0.809 1.076l0.008 0.002c0.095 0.030 0.205 0.048 0.318 0.051l0.002 0c0.508-0.003 0.937-0.334 1.087-0.792l0.002-0.008 0.602-1.981zM9.565 13.79l-0.202 0.653c0.137 0.011 0.263 0.033 0.384 0.066l-0.014-0.003 0.085-0.664c-0.066-0.018-0.152-0.036-0.239-0.049l-0.014-0.002z" />
      <path d="M30.4 6.859l-17.12-5.259-2.4 7.822-9.28 1.65 2.379 13.507 8.059-1.43 1.11 6.317c0.1 0.535 0.563 0.934 1.119 0.934 0 0 0.001 0 0.001 0h-0c0.009 0 0.019 0 0.030 0 0.060 0 0.12-0.004 0.178-0.012l-0.007 0.001c0.54-0.087 0.947-0.549 0.947-1.107 0-0.067-0.006-0.132-0.017-0.196l0.001 0.007-1.12-6.339 7.32-1.293-0.55-3.123 5.349 1.635zM18.354 20.896l-13.443 2.374-1.989-11.285 15.379-2.738 0.138 0.81v0.022l0.19 1.016 0.982 5.613 0.222 1.256 0.454 2.594zM16.491 8.437c0.149-0.27 0.286-0.509 0.413-0.768l0.021 0.011c-0.042 0.227-0.074 0.456-0.106 0.694zM20.85 17.090l-0.562-3.17 1.227-4.016-0.75-0.227-0.765 2.526-0.454-2.531 1.163-1.806-0.762-0.227-0.587 0.944-0.117-0.64-1.723 0.301 0.083-1.328-0.782-0.238-1.174 1.906-1.376 0.25 0.19-2.88-0.782-0.238-0.158 3.246-1.36 0.245 1.878-6.194 14.944 4.586-3.342 10.963z" />
      <path d="M21.632 8.146c-0.044-0.015-0.095-0.024-0.149-0.024-0.222 0-0.408 0.15-0.463 0.355l-0.001 0.003c-0.015 0.044-0.024 0.095-0.024 0.149 0 0.215 0.144 0.396 0.341 0.452l0.003 0.001h0.011c0.043 0.013 0.092 0.021 0.142 0.021 0.27 0 0.488-0.219 0.488-0.488 0-0.219-0.144-0.404-0.343-0.466l-0.003-0.001z" />
      <path d="M24.848 10.822c-0.154-0.055-0.331-0.087-0.517-0.087-0.424 0-0.809 0.168-1.092 0.441l0-0-0.011-0.011 0.16-0.694-0.669-0.197c-0.101 0.463-0.207 0.842-0.331 1.213l0.024-0.081-0.92 3.029 0.747 0.227 0.766-2.501c0.041-0.13 0.095-0.244 0.163-0.348l-0.003 0.005c0.206-0.304 0.55-0.5 0.94-0.5 0.11 0 0.216 0.016 0.316 0.045l-0.008-0.002c0.698 0.218 0.782 0.861 0.571 1.525l-0.72 2.4 0.75 0.235 0.752-2.478c0.434-1.43-0.349-2.042-0.92-2.219z" />
      <path d="M10.453 16.426l-1.957 0.342 0.117 0.622 1.195-0.218 0.306 1.784c-0.285 0.171-0.622 0.289-0.982 0.33l-0.011 0.001c-1.386 0.25-2.443-0.48-2.707-1.992s0.528-2.56 1.966-2.821c0.176-0.038 0.378-0.060 0.585-0.060 0.141 0 0.279 0.010 0.415 0.029l-0.015-0.002c0.137 0.011 0.263 0.033 0.384 0.066l-0.014-0.003 0.085-0.664c-0.066-0.018-0.152-0.036-0.239-0.049l-0.014-0.002c-0.151-0.019-0.326-0.030-0.504-0.030-0.274 0-0.543 0.026-0.803 0.076l0.027-0.004c-1.989 0.352-2.982 1.867-2.677 3.642 0.17 0.923 0.624 1.67 1.218 2.080 0.686 0.445 1.459 0.549 2.358 0.382 0.668-0.119 1.264-0.342 1.802-0.654l-0.026 0.014z" />
      <path d="M15.68 16.674l-0.034-0.194c-0.069-0.432-0.239-0.815-0.485-1.137l0.005 0.006c-0.363-0.453-0.916-0.74-1.536-0.74-0.149 0-0.295 0.017-0.434 0.048l0.013-0.002c-0.067 0.008-0.127 0.023-0.185 0.043l0.006-0.002c-0.495 0.116-0.909 0.407-1.181 0.803l-0.004 0.007c-0.338 0.456-0.48 1.069-0.349 1.794 0.243 1.35 1.291 2.045 2.434 1.837h0.010c0.966-0.17 1.901-0.998 1.741-2.462zM13.858 18.542c-0.75 0.134-1.406-0.48-1.565-1.411-0.030-0.126-0.047-0.27-0.047-0.418 0-0.372 0.108-0.72 0.295-1.012l-0.005 0.008c0.177-0.247 0.446-0.419 0.755-0.465l0.006-0.001c0.826-0.146 1.322 0.518 1.502 1.171 0.029 0.066 0.051 0.144 0.062 0.224l0.001 0.005c0.178 0.976-0.264 1.774-1.005 1.899z" />
      <path d="M17.39 18.522h-0.010c-0.028 0.006-0.060 0.009-0.092 0.009-0.23 0-0.422-0.156-0.478-0.368l-0.001-0.003v-0.011c-0.019-0.022-0.031-0.051-0.032-0.083v-0c-0.008-0.034-0.012-0.073-0.012-0.114 0-0.184 0.092-0.347 0.233-0.445l0.002-0.001c0.056-0.042 0.124-0.072 0.198-0.083l0.002-0c0.017-0.002 0.036-0.003 0.056-0.003 0.309 0 0.56 0.251 0.56 0.56 0 0.262-0.179 0.481-0.422 0.543l-0.004 0.001z" />
      <path d="M17.381 16.664l-0.603 0.104-0.202-0.986-0.666-3.237 0.656-0.115 0.189-0.030 0.085 0.602 0.445 2.998 0.096 0.664z" />
    </svg>
  );
}

IconRegionalRally.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
