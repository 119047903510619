import { useMemo } from "react";

/**
 * Parses a size value passed into the component into a `flex` CSS value.
 */
export function useParsedSize(size: number | string | null) {
  return useMemo(() => {
    if (size == null) {
      return null;
    }

    if (typeof size === "number") {
      return `0 0 ${size}px`; //
    }

    if (typeof size === "string") {
      if (size === "auto") {
        return "1 1 100%";
      }

      return `0 0 ${size}`; // pass along (should be a CSS value)
    }

    throw new Error(`Size was "${size}" but must be a string or number.`);
  }, [size]);
}
