// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconIdeaCenterAdmin({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.88 26.236h4.34c0.462 0 0.836 0.374 0.836 0.836v0.034c0 0.462-0.374 0.836-0.836 0.836h-4.34c-0.462 0-0.836-0.374-0.836-0.836v-0.034c0-0.462 0.374-0.836 0.836-0.836z" />
      <path d="M13.88 28.275h4.34c0.462 0 0.836 0.374 0.836 0.836v0.034c0 0.462-0.374 0.836-0.836 0.836h-4.34c-0.462 0-0.836-0.374-0.836-0.836v-0.034c0-0.462 0.374-0.836 0.836-0.836z" />
      <path d="M14.802 30.315h2.496c0.462 0 0.836 0.374 0.836 0.836v0.034c0 0.462-0.374 0.836-0.836 0.836h-2.495c-0.462 0-0.836-0.374-0.836-0.836v-0.034c0-0.462 0.374-0.836 0.836-0.836z" />
      <path d="M16.050 7.449c3.324 0.048 6.001 2.754 6.001 6.085 0 0.024-0 0.048-0 0.072l0-0.004c0 0.001 0 0.003 0 0.005 0 1.511-0.536 2.896-1.429 3.976l0.008-0.011-0.031 0.037-0.028 0.039c-1.237 1.826-2.141 3.983-2.571 6.307l-0.016 0.104c-0.038 0.275-0.271 0.484-0.553 0.487h-2.722c-0.3-0.002-0.548-0.219-0.599-0.504l-0.001-0.004c-0.499-2.463-1.429-4.645-2.716-6.568l0.041 0.066-0.024-0.034-0.026-0.032c-0.834-1.044-1.338-2.382-1.338-3.839 0-0.010 0-0.021 0-0.031v0.002c-0-0.021-0-0.045-0-0.069 0-3.331 2.676-6.037 5.996-6.085l0.005-0zM16.050 6.218c-4.003 0.044-7.231 3.299-7.231 7.309 0 0.027 0 0.053 0 0.080l-0-0.004c0 0.005-0 0.010-0 0.016 0 1.75 0.604 3.359 1.614 4.63l-0.012-0.015c1.309 1.954 2.23 4.278 2.595 6.783l0.011 0.090c0.046 0.38 0.366 0.671 0.755 0.671 0.001 0 0.002 0 0.002 0h4.483c0.001 0 0.003 0 0.004 0 0.455 0 0.827-0.356 0.854-0.804l0-0.002c0.111-2.109 1.342-5.031 2.454-6.615 1.059-1.277 1.701-2.933 1.701-4.738 0-0.006 0-0.011-0-0.017v0.001c0-0.023 0-0.049 0-0.076 0-4.009-3.228-7.265-7.227-7.309l-0.004-0z" />
      <path d="M7.013 13.727c-0-0.255-0.205-0.462-0.459-0.466h-0l-3.664-0.207c-0.372 0-0.674 0.302-0.674 0.674s0.302 0.674 0.674 0.674v0h0.009l3.655-0.207c0.255-0.004 0.459-0.211 0.459-0.467 0-0.001 0-0.001 0-0.002v0z" />
      <path d="M9.644 7.532c0.083-0.084 0.134-0.2 0.134-0.327s-0.051-0.243-0.134-0.327l0 0-2.467-2.715c-0.123-0.125-0.293-0.203-0.482-0.203-0.372 0-0.674 0.302-0.674 0.674 0 0.193 0.081 0.367 0.211 0.49l0 0 0.007 0.006 2.753 2.412c0.083 0.079 0.196 0.128 0.321 0.128 0.13 0 0.247-0.053 0.332-0.139l0-0z" />
      <path d="M16.131 4.797c0.253-0.005 0.457-0.212 0.457-0.466 0-0.001 0-0.002 0-0.003v0l0.134-3.659c0-0.003 0-0.006 0-0.009-0.014-0.361-0.311-0.649-0.674-0.649-0.373 0-0.674 0.302-0.674 0.674 0 0 0 0.001 0 0.001v-0 0.009l0.28 3.65c0.009 0.251 0.214 0.451 0.466 0.451 0.004 0 0.008-0 0.012-0h-0.001z" />
      <path d="M22.507 7.362c0.083 0.078 0.195 0.125 0.318 0.125 0.132 0 0.251-0.055 0.336-0.143l0-0 2.655-2.534c0.121-0.122 0.196-0.29 0.196-0.476 0-0.373-0.302-0.674-0.674-0.674-0.201 0-0.382 0.088-0.506 0.228l-0.001 0.001-0.005 0.007-2.346 2.811c-0.075 0.083-0.121 0.193-0.121 0.314 0 0.134 0.057 0.255 0.147 0.34l0 0z" />
      <path d="M25.001 13.812c0.006 0.253 0.213 0.456 0.467 0.456 0.001 0 0.001 0 0.002 0h-0l3.668 0.131c0.361-0.014 0.649-0.31 0.649-0.674 0-0.373-0.302-0.674-0.674-0.674-0.001 0-0.001 0-0.002 0h-0.009l-3.65 0.282c-0.251 0.009-0.45 0.215-0.45 0.467 0 0.004 0 0.008 0 0.012v-0.001z" />
    </svg>
  );
}

IconIdeaCenterAdmin.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
