// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconScanner({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.304 23.349h-0.658v-14.698h0.658zM7.962 23.339h-0.334v-14.688h0.334zM8.944 23.339h-0.32v-14.688h0.32zM10.582 23.339h-0.32v-14.688h0.32zM12.221 23.339h-0.64v-14.688h0.64zM13.536 23.339h-0.32v-14.688h0.32zM14.194 23.339h-0.32v-14.688h0.32zM14.851 23.339h-0.32v-14.688h0.32zM16.491 23.339h-0.651v-14.688h0.658zM18.13 23.339h-0.658v-14.688h0.658zM19.445 23.339h-0.658v-14.688h0.658zM20.76 23.339h-0.658v-14.688h0.658zM21.741 23.339h-0.658v-14.688h0.658zM23.715 23.339h-0.982v-14.688h0.982zM24.373 23.339h-0.334v-14.688h0.334zM25.354 23.349h-0.658v-14.698h0.658z" />
      <path d="M4.517 7.907h-1.104v-1.69c0.001-1.268 1.028-2.296 2.296-2.298h1.783v1.104h-1.782c-0.659 0.001-1.192 0.535-1.192 1.194v0z" />
      <path d="M28.587 7.954h-1.104v-1.782c0 0 0 0 0 0 0-0.659-0.533-1.193-1.192-1.194h-1.691v-1.104h1.691c1.268 0.002 2.295 1.030 2.296 2.297v0z" />
      <path d="M26.291 28.080h-1.782v-1.104h1.782c0.659-0.001 1.192-0.535 1.192-1.194v0-1.69h1.104v1.69c-0.001 1.268-1.028 2.296-2.296 2.298h-0z" />
      <path d="M7.4 28.126h-1.691c-1.268-0.002-2.295-1.030-2.296-2.297v-1.783h1.104v1.782c0 0 0 0 0 0 0 0.659 0.533 1.193 1.192 1.194h1.691z" />
      <path d="M1.6 15.448h28.8v1.104h-28.8v-1.104z" />
    </svg>
  );
}

IconScanner.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
