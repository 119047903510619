// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconEdit({ className, size, color }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 32 32" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      style={{ color }}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M29.844 12.102l-1.054 1.173c0.188 0.88 0.301 1.788 0.301 2.724 0 7.219-5.872 13.091-13.091 13.091s-13.091-5.872-13.091-13.091c0-7.218 5.872-13.091 13.091-13.091 2.985 0 5.74 1.004 7.945 2.693 0.044 0.034 0.083 0.075 0.127 0.109l0.872-0.996c-2.457-1.95-5.564-3.115-8.944-3.115-7.953 0-14.4 6.448-14.4 14.4s6.447 14.4 14.4 14.4 14.4-6.447 14.4-14.4c0-1.354-0.207-2.655-0.556-3.898z" />
      <path d="M11.756 26.103c0.025-0.087 0.040-0.146 0.059-0.203 0.636-1.9 1.271-3.8 1.91-5.699 0.029-0.087 0.080-0.173 0.141-0.241 3.732-4.154 7.467-8.305 11.2-12.458 0.305-0.34 0.692-0.432 1.104-0.308 0.271 0.081 0.541 0.225 0.76 0.403 0.471 0.383 0.912 0.804 1.359 1.215 0.324 0.298 0.526 0.662 0.54 1.112 0.009 0.287-0.098 0.527-0.289 0.74-1.322 1.476-2.637 2.959-3.963 4.431-2.402 2.668-4.809 5.331-7.217 7.993-0.082 0.091-0.187 0.17-0.296 0.228-1.704 0.901-3.411 1.798-5.117 2.695-0.053 0.028-0.109 0.052-0.192 0.092zM26.504 11.774c0.046-0.061 0.081-0.113 0.121-0.16 0.408-0.483 0.812-0.97 1.228-1.446 0.146-0.167 0.181-0.25 0.042-0.509-0.050-0.092-0.124-0.176-0.203-0.247-0.439-0.4-0.88-0.799-1.328-1.19-0.097-0.085-0.22-0.147-0.341-0.197-0.147-0.061-0.27-0.026-0.385 0.105-0.429 0.49-0.87 0.969-1.306 1.453-0.033 0.036-0.060 0.078-0.094 0.12 0.753 0.688 1.498 1.37 2.265 2.071zM16.274 23.111c-0.746-0.682-1.48-1.353-2.232-2.041-0.29 0.86-0.569 1.684-0.842 2.511-0.013 0.039 0.012 0.111 0.044 0.142 0.196 0.188 0.397 0.372 0.604 0.549 0.035 0.030 0.119 0.042 0.159 0.022 0.752-0.387 1.501-0.781 2.268-1.182z" />
    </svg>
  );
}

IconEdit.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
