import styles from "./TextInput.module.css";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export function TextInput(props) {
  const { value, onChange, className } = props;
  const [editValue, setEditValue] = useState(value || "");

  useEffect(() => {
    if (editValue !== value) {
      setEditValue(value);
    }
  }, [value]);

  return (
    <input
      {...props}
      type="text"
      className={classNames(styles.input, className)}
      value={editValue}
      onChange={(e) => {
        setEditValue(e.target.value);

        if (typeof onChange === "function") {
          onChange(e);
        }
      }}
    />
  );
}

TextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
