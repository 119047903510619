import styles from "./FlowLayout.module.css";
import React, { useMemo, createContext, FunctionComponent } from "react";
import PropTypes from "prop-types";
import { useMeasure } from "react-use";

type FlowLayoutState = {
  debug: boolean;
  size: {
    width: number;
    height: number;
  };
};

export const FlowLayoutContext = createContext<FlowLayoutState>(null);

const propTypes = {
  /**
   * If true, render all child containers with bright colored borders for layout debugging.
   */
  debug: PropTypes.bool,

  children: PropTypes.node,
};

type FlowLayoutProps = PropTypes.InferType<typeof propTypes>;

export const FlowLayout: FunctionComponent<FlowLayoutProps> = function FlowLayout({
  debug,
  children,
}) {
  const [ref, size] = useMeasure();
  const ctx = useMemo<FlowLayoutState>(
    () => ({
      // debug should never be true once the app is deployed even if it's left on.
      debug: (process.env.NODE_ENV !== "production" && debug) || false,
      size: {
        width: size.width,
        height: size.height,
      },
    }),
    [debug, size]
  );

  return (
    <FlowLayoutContext.Provider value={ctx}>
      <div ref={ref} className={styles.flowLayout}>
        {children}
      </div>
    </FlowLayoutContext.Provider>
  );
};

FlowLayout.propTypes = propTypes;
