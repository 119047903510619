import { ReactNodeLike } from "prop-types";
import { createContext, MouseEventHandler } from "react";
import { useMergedState } from "../hooks/useMergedState";

/**
 * Shared context to hold one instance of useAppHeaderState.
 */
export const AppHeaderContext = createContext<
  ReturnType<typeof useAppHeaderState>
>(null);

type AppHeaderState = {
  isVisible?: boolean;
  title?: string;
  icon?: ReactNodeLike;
  controls?: ReactNodeLike[];
  onBack?: MouseEventHandler<HTMLButtonElement>;
};

/**
 * Header state management hook. One instance of this is shared across all consumers of AppHeaderContext.
 */
export function useAppHeaderState(initialValues: AppHeaderState) {
  return useMergedState(initialValues);
}
