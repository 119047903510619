import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { FlowContainer } from "../FlowContainer";

type FlowUpProps = PropTypes.InferProps<typeof FlowContainer.propTypes>;

/**
 * Layout container that flows children from bottom to top.
 */
export const FlowUp = forwardRef(function FlowUp(props: FlowUpProps, ref) {
  return <FlowContainer ref={ref} {...props} direction="column-reverse" />;
});

FlowUp.propTypes = FlowContainer.propTypes;
